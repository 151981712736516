// import React, { useEffect, useState } from "react";
// import Button from "./Button";
// import RangeSlider from "./RangeSlider";
// // import { useLocation } from "react-router-dom";

// const FilterOptions = ({
//   btnColor,
//   data,
//   setResponse,
//   filterResponse,
//   filterData,
//   setFilterData,
//   // handleOnSelect,
// }) => {
//   const {
//     brands,
//     types,
//     models,
//     colors,
//     ranges,
//     maxPrice,
//     minPrice,
//     autoPilotList,
//     seats,
//   } = data?.data?.carFilters || {};
//   const currentUserDetails = JSON.parse(
//     sessionStorage.getItem("CurrentUserDetails")
//   );
//   // const location = useLocation();
//   // const pathLength = location?.pathname?.split("/")?.length - 1;
//   // const pathLength1 = location?.pathname?.split("/")?.length - 2;
//   // const pathNameFromSiteMap = location.pathname.split("/")[pathLength];
//   // const pathNameFromSiteMap1 = location.pathname.split("/")[pathLength1];

//   // useEffect(() => {
//   //   if (pathNameFromSiteMap1 === "make") {
//   //     //decodeURIComponent it is used to remove %20 from URl and add space
//   //     handleOnSelect(decodeURIComponent(pathNameFromSiteMap));
//   //   }
//   //   // eslint-disable-next-line
//   // }, [pathNameFromSiteMap1]);

//   const [subFilterData, setSubFilterData] = useState({
//     autoPilotIds: [],
//     brand: [],
//     vehicleModel: [],
//     vehicleType: [],
//     seats: [],
//     colors: [],
//     ranges: [],
//     minPrice: "",
//     maxPrice: "",
//   })

//   useEffect(() => {
//     if (filterData?.ageGroup) {
//       setFilterData((prev) => ({
//         ...prev,
//         ageGroup: filterData?.ageGroup,
//       }));
//     }
//     // eslint-disable-next-line
//   }, [filterData?.ageGroup]);

//   const handleFilterChange = (filterType, value)=> {
// console.log(filterType, value);

//     setSubFilterData((prev) => {
//       const currentSelection = prev[filterType] || [];
//       const newSelection = currentSelection.includes(value)
//         ? currentSelection.filter((item) => item !== value)
//         : [...currentSelection, value];

//         //const filterCars = currentSelection?.includes(value) ? filterResponse?.data?.cars :  filterResponse?.data?.cars?.filter((car) => car[subType] === subValue)
        
//         const filterCars =
//         Object.values({ ...prev, [filterType]: newSelection }).every((values) => values.length === 0)
//           ? filterResponse?.data?.cars
//           : filterResponse?.data?.cars?.filter((car) =>
//               Object.entries({ ...prev, [filterType]: newSelection }).every(
//                 ([key, values]) => values.length === 0 || values.includes(car[key])
//               )
//             );
  
//         setResponse((prev) => ({
//           ...prev,
//           data:{
//             ...prev?.data,
//             cars: filterCars
//           }
//         }))

//     return{
//       ...prev,
//       [filterType]:newSelection
//     }
//     })
    
//     setFilterData((prev) => {
//       // Ensure prev[filterType] is an array
//       const currentSelection = prev[filterType] || [];
//       const newSelection = currentSelection.includes(value)
//         ? currentSelection.filter((item) => item !== value)
//         : [...currentSelection, value];

//       return {
//         ...prev,
//         carFilters: data?.data?.carFilters,
//         [filterType]: newSelection,
//       };
//     });
//   };

//   console.log(filterResponse);
  

//   return (
//     <div>
//       <div className="p-3 fontAntarctica width100">
//         <p className="fontSize14 mb-1 px-2">Vehicle Type</p>
//         {types?.map((type, index) => (
//           <Button
//             key={index}
//             btnClassName={`filterButtons text-white ${type?.available ? "" : "disabled-class"} ${
//               filterData?.vehicleType?.includes(type?.name)
//                 ? "bgCE1"
//                 : "bg3B3936"
//             }`}
//             onClick={() => handleFilterChange("vehicleType", type?.name)}
//           >
//             {type?.name}
//           </Button>
//         ))}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Brand</p>
//         {brands?.map((brand, index) => (
//           <Button
//             key={index}
//             btnClassName={`filterButtons text-white ${brand?.available ? "" : "disabled-class"} ${
//               filterData?.brand?.includes(brand?.name) ? "bgCE1" : "bg3B3936"
//             }`}
//             onClick={() => handleFilterChange("brand", brand?.name)}
//           >
//             {brand?.name}
//           </Button>
//         ))}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Model</p>
//         {models?.map((model, index) => (
//           <Button
//             key={index}
//             btnClassName={`filterButtons text-white ${model?.available ? "" : "disabled-class"} ${
//               filterData?.vehicleModel?.includes(model?.name)
//                 ? "bgCE1"
//                 : "bg3B3936"
//             }`}
//             onClick={() => handleFilterChange("vehicleModel", model?.name)}
//           >
//             {model?.name}
//           </Button>
//         ))}
//         {/* <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Color</p>
//         <div>
//         {colors?.map((color, index) =>
//           color?.available === false ? (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white disbaleFilterButton cursorDefault`}
//             >
//               {color?.color}
//             </Button>
//           ) : (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white  ${
//                 filterData?.colors?.includes(color?.color)  ? "bgCE1" : "bg3B3936"
//               }`}
//               onClick={() => handleFilterChange("colors", color?.color)}
//             >
//               {color?.color}
//             </Button>
//           )
//         )}
//         </div> */}
//         <hr className="bg3B3936" />
//         <div>
//           <RangeSlider
//             data={data}
//             filterData={filterData}
//             setFilterData={setFilterData}
//             maxPrice={maxPrice}
//             minPrice={minPrice}
//           />
//         </div>
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Age Group</p>
//         <select
//           name="ageGroup"
//           value={filterData?.ageGroup}
//           onChange={(e) => {
//             setFilterData((prev) => ({
//               ...prev,
//               ageGroup: e.target.value,
//             }));
//           }}
//           className="w-100 age-group-select  outlineNone border-0 p-2 fontSize13 cursorPointer borderRadius8 text-white bg3B3936"
//         >
//           <option disabled value="Select Age">
//             Select Age
//           </option>
//           <option value="1">18-20</option>
//           <option disabled={currentUserDetails?.ageGroup === 1} value="2">
//             21-24
//           </option>
//           <option
//             disabled={
//               currentUserDetails?.ageGroup === 1 ||
//               currentUserDetails?.ageGroup === 2
//             }
//             value="3"
//           >
//             25+
//           </option>
//         </select>
//         <hr className="bg3B3936" />
//         <p className="fontSize14 mb-1 px-2">Range</p>
//         {ranges?.map((range, index) => (
//           <Button
//             key={index}
//             btnClassName={`filterButtons text-white ${range?.available ? "" : "disabled-class"} ${
//               filterData?.ranges?.includes(range?.id) ? "bgCE1" : "bg3B3936"
//             }`}
//             onClick={() => handleFilterChange("ranges", range?.id)}
//           >
//             {range?.label}
//           </Button>
//         ))}
//         <hr className="bg3B3936" />
//         <p className="fontSize14 filterButtons">Autopilot</p>
//         {autoPilotList?.map((list, index) => (
//           <Button
//             key={index}
//             btnClassName={`filterButtons text-white ${list?.available ? "" : "disabled-class"} ${
//               filterData?.autoPilotList?.includes(list?.id)
//                 ? "bgCE1"
//                 : "bg3B3936"
//             }`}
//             onClick={() => handleFilterChange("autoPilotList", list?.id)}
//           >
//             {list?.type}
//           </Button>
//         ))}
//         <hr className="bg3B3936" />
//         <div className="mobilePadding">
//           <p className="fontSize14 mb-1 px-2">Seats</p>
//           {seats?.map((seat, index) => (
//             <Button
//               key={index}
//               btnClassName={`filterButtons text-white ${seat?.available ? "" : "disabled-class"} ${
//                 filterData?.seats?.includes(seat?.name) ? "bgCE1" : "bg3B3936"
//               }`}
//               onClick={() => handleFilterChange("seats", seat?.name)}
//             >
//               {seat?.name}
//             </Button>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FilterOptions;


import React, { useEffect, useState } from "react";
import Button from "./Button";
import RangeSlider from "./RangeSlider";
import { useLocation, useNavigate } from "react-router-dom";

const FilterOptions = ({
  btnColor,
  data,
  setResponse,
  filterResponse,
  filterData,
  setFilterData,
  subFilterData,
  setSubFilterData,
  carDataAvailable,
  setCarDataAvailable
}) => {
  const {
    brands,
    types,
    models,
    colors,
    ranges,
    maxPrice,
    minPrice,
    autoPilotList,
    seats,
  } = data?.data?.carFilters || {};
  const currentUserDetails = JSON.parse(
    sessionStorage.getItem("CurrentUserDetails")
  );

  const location = useLocation()

  useEffect(() => {
    if (filterData?.ageGroup) {
      setFilterData((prev) => ({
        ...prev,
        ageGroup: filterData?.ageGroup,
      }));
    }
    // eslint-disable-next-line
  }, [filterData?.ageGroup]);

  const handleFilterChange = (filterType, value)=> {
    setFilterData((prev) => {

       // For minPrice and maxPrice, update the value directly (no array manipulation needed)
    if (filterType === "minPrice") {
      return {
        ...prev,
        carFilters: data?.data?.carFilters,
        [filterType]: value[0], // Directly set the value for minPrice or maxPrice
      };
    }

    if(filterType === "maxPrice"){
      return {
        ...prev,
        carFilters: data?.data?.carFilters,
        [filterType]: value[1], // Directly set the value for minPrice or maxPrice
      };
    }

      // Ensure prev[filterType] is an array
      const currentSelection = prev[filterType] || [];
      const newSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value)
        : [...currentSelection, value];

      return {
        ...prev,
        carFilters: data?.data?.carFilters,
        [filterType]: newSelection,
      };
    });
    applyFilters(filterResponse?.data?.cars,  subFilterData, filterType, value)
  };
  
const applyFilters = (carList, filters, filterType, value) => {
  const relatedCars = filterResponse?.data?.cars?.filter((car) => filterType === "minPrice" || filterType === "maxPrice" ?  car?.baseRatePerDay >= value[0] &&  car?.baseRatePerDay <= value[1]  : car[filterType] === value) || [];
  
  //console.log(filterType, value[0], value[1]);
  //console.log(relatedCars);
  
  
  // Extract unique values for each category
  const relatedModels = [...new Set(relatedCars.map((car) => car.vehicleModel))];
  const relatedTypes = [...new Set(relatedCars.map((car) => car.vehicleType))];
  const relatedBrands = [...new Set(relatedCars.map((car) => car.brand))];
  const relatedSeats = [...new Set(relatedCars.map((car) => car.seats))];
  const relatedAutopilot = [...new Set(relatedCars.map((car) => car.autoPilotIds))];
  const relatedRanges = [...new Set(relatedCars.map((car) => car.ranges))];

  if(filterType === "minPrice" || filterType === "maxPrice"){
    if(relatedCars?.length === 0){
      setCarDataAvailable(true)
    }else{
      setCarDataAvailable(false)
    }
    setSubFilterData((prev) => ({
      ...prev,
      autoPilotIds: [...relatedAutopilot],
      brand: [...relatedBrands],
      vehicleModel: [...relatedModels],
      vehicleType: [...relatedTypes],
      seats: [...relatedSeats],
      ranges: [...relatedRanges],
    }))  
  } else {
    setCarDataAvailable(false)

    setFilterData((prev) => ({
      ...prev,
      minPrice: minPrice,
      maxPrice: maxPrice,
    }));

  setSubFilterData((prev) => {
    const currentSelection = prev[filterType] || [];
    const isSelected = currentSelection.includes(value);

    let updatedFilters = { ...prev };

    if (!isSelected) {
      // Add selected value & its related filters
      updatedFilters[filterType] = [...currentSelection, value];
      updatedFilters.vehicleModel = [...new Set([...(prev?.vehicleModel || []), ...relatedModels])];
      updatedFilters.vehicleType = [...new Set([...(prev?.vehicleType || []), ...relatedTypes])];
      updatedFilters.brand = [...new Set([...(prev?.brand || []), ...relatedBrands])];
      updatedFilters.seats = [...new Set([...(prev?.seats || []), ...relatedSeats])];
      updatedFilters.autoPilotIds = [...new Set([...(prev?.autoPilotIds || []), ...relatedAutopilot])];
      updatedFilters.ranges = [...new Set([...(prev?.ranges || []), ...relatedRanges])];
    } else {
      // Remove selected value
      updatedFilters[filterType] = currentSelection.filter((item) => item !== value);

      // If the filterType is 'brand or vehicleModel or vehicleModel' and it's the last selected brand or vehicleModel or vehicleModel , remove all related filters
      if ((filterType === 'vehicleModel' || filterType === 'brand' || filterType === 'vehicleType' || filterType === 'autoPilotIds' || filterType === 'seats' || filterType === 'ranges') && (updatedFilters.vehicleModel.length === 0 || updatedFilters.brand.length === 0 || updatedFilters.vehicleType.length === 0 || updatedFilters.autoPilotIds.length === 0 || updatedFilters.seats.length === 0 || updatedFilters.ranges.length === 0)) {
        updatedFilters.vehicleModel = [];
        updatedFilters.vehicleType = [];
        updatedFilters.seats = [];
        updatedFilters.autoPilotIds = [];
        updatedFilters.ranges = [];
        updatedFilters.brand = []
      } else {
        // Find remaining valid cars after removal
        const validCars = carList.filter((car) =>
          Object.keys(updatedFilters).every(
            (key) =>
              updatedFilters[key].length === 0 || updatedFilters[key].includes(car[key])
          )
        );

        // Remove related filters **only if no valid cars still have them**
        const remainingValues = {
          vehicleModel: new Set(validCars.map((car) => car.vehicleModel)),
          vehicleType: new Set(validCars.map((car) => car.vehicleType)),
          brand: new Set(validCars.map((car) => car.brand)),
          seats: new Set(validCars.map((car) => car.seats)),
          autoPilotIds: new Set(validCars.map((car) => car.autoPilotIds)),
          ranges: new Set(validCars.map((car) => car.ranges)),
        };

        updatedFilters.vehicleModel = prev.vehicleModel.filter((model) => remainingValues.vehicleModel.has(model));
        updatedFilters.vehicleType = prev.vehicleType.filter((type) => remainingValues.vehicleType.has(type));
        updatedFilters.brand = prev.brand.filter((b) => remainingValues.brand.has(b));
        updatedFilters.seats = prev.seats.filter((seat) => remainingValues.seats.has(seat));
        updatedFilters.autoPilotIds = prev.autoPilotIds.filter((autoPilot) => remainingValues.autoPilotIds.has(autoPilot));
        updatedFilters.ranges = prev.ranges.filter((range) => remainingValues.ranges.has(range));
      }
    }

    return updatedFilters;
  });
 }
};

//console.log(subFilterData);


  const carList = filterResponse?.data?.cars

  useEffect(() => {
    const { vehicleModel, brand } = subFilterData || {};
  
    const filteredCars = carList?.filter((car) => {

      const modelMatches =
        vehicleModel?.length === 0 || vehicleModel?.some((model) =>
          car.vehicleModel.toLowerCase().includes(model.toLowerCase())
        );
  
      const brandMatches =
        brand?.length === 0 || brand?.includes(car.brand);
  
      if (vehicleModel?.length > 0 && brand?.length > 0) {
        // If both model and brand filters are provided:
        return brandMatches && modelMatches;
      } else if (vehicleModel?.length > 0 ) {
        // If only models are provided, include cars from all brands that match the model
        return modelMatches;
      } else if (brand?.length > 0) {
        // If only brands are provided, include all cars from the selected brands (regardless of model)
        return brandMatches;
      } 
      // Default case: return cars that match the brand or model
      return modelMatches || brandMatches ;
    });
  
  
    if(vehicleModel?.length === 0 && brand?.length === 0){
      if(siteMapPathname?.includes("make") || carDataAvailable){
        setResponse((prev) => ({
          ...prev,
          data:{
            ...prev?.data,
            cars:""
          },
          message:"No cars available. Please try a different Pickup and Drop-off Location or dates for vehicle availability."
        }))
        setSiteMapPathname("")
      } else {
         setResponse((prev) => ({
        ...prev,
        data:{
          ...prev?.data,
          cars:carList
        }
      }))
      }
    
    } else {
      setResponse((prev) => ({
        ...prev,
        data:{
          ...prev?.data,
          cars:filteredCars
        }
      }))
    }
    
  }, [subFilterData, carList]);  // Include subFilterData and carList in the dependency array

  const navigate = useNavigate()
  const pathLength = location?.pathname?.split("/")?.length - 1;
  const [siteMapPathname, setSiteMapPathname] = useState("")
  
  useEffect(() => {
    if (location.pathname.split("/")?.includes("make") && filterResponse?.data?.cars?.length > 0) {
      setSiteMapPathname(location.pathname?.split("/"))
      const brand = decodeURIComponent(location.pathname.split("/")[pathLength]); // Decode brand name
      const newPath = location.pathname.replace(/\/make\/[^/]+/, ""); // Remove /make/{brand}
  
      navigate(newPath, { replace: true });
  
      // Run handleFilterChange AFTER navigation to avoid using stale location
      setTimeout(() => {
        handleFilterChange("brand", brand);
      }, 0);
    }
  }, [location.pathname, filterResponse?.data?.cars]);
  

  const handleGetMinAndMaxPrice = (newPriceData, clickedThumb) => {
      // Update based on which thumb was clicked
      if (clickedThumb === "minPrice") {
        handleFilterChange("minPrice", newPriceData); 
      } else if (clickedThumb === "maxPrice") {
        handleFilterChange("maxPrice", newPriceData); 
      }
  }

  return (
    <div>
      <div className="p-3 fontAntarctica width100">
        <p className="fontSize14 mb-1 px-2">Vehicle Type</p>
        {types?.map((type, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${type?.available ? "" : "disabled-class"} ${
              subFilterData?.vehicleType?.includes(type?.name)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("vehicleType", type?.name)}
          >
            {type?.name}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Brand</p>
        {brands?.map((brand, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${brand?.available ? "" : "disabled-class"} ${
              subFilterData?.brand?.includes(brand?.name) ? "bgCE1" : "bg3B3936"
            }`}
            onClick={() =>{
               handleFilterChange("brand", brand?.name)
              }}
          >
            {brand?.name}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Model</p>
        {models?.map((model, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${model?.available ? "" : "disabled-class"} ${
              subFilterData?.vehicleModel?.includes(model?.name)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("vehicleModel", model?.name)}
          >
            {model?.name}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <div>
          <RangeSlider
            data={data}
            filterData={filterData}
            setFilterData={setFilterData}
            maxPrice={maxPrice}
            minPrice={minPrice}
            handleGetMinAndMaxPrice={handleGetMinAndMaxPrice}
          />
        </div>
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Age Group</p>
        <select
          name="ageGroup"
          value={filterData?.ageGroup}
          onChange={(e) => {
            setFilterData((prev) => ({
              ...prev,
              ageGroup: e.target.value,
            }));
            setSubFilterData({
                 autoPilotIds: [],
                brand: [],
                vehicleModel: [],
                vehicleType: [],
                seats: [],
                colors: [],
                ranges: [],
                minPrice: "",
                maxPrice: "",
            })
          }}
          className="w-100 age-group-select  outlineNone border-0 p-2 fontSize13 cursorPointer borderRadius8 text-white bg3B3936"
        >
          <option disabled value="Select Age">
            Select Age
          </option>
          <option value="1">18-20</option>
          <option disabled={currentUserDetails?.ageGroup === 1} value="2">
            21-24
          </option>
          <option
            disabled={
              currentUserDetails?.ageGroup === 1 ||
              currentUserDetails?.ageGroup === 2
            }
            value="3"
          >
            25+
          </option>
        </select>
        <hr className="bg3B3936" />
        <p className="fontSize14 mb-1 px-2">Range</p>
        {ranges?.map((range, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${range?.available ? "" : "disabled-class"} ${
              subFilterData?.ranges?.includes(range?.id) ? "bgCE1" : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("ranges", range?.id)}
          >
            {range?.label}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <p className="fontSize14 filterButtons">Autopilot</p>
        {autoPilotList?.map((list, index) => (
          <Button
            key={index}
            btnClassName={`filterButtons text-white ${list?.available ? "" : "disabled-class"} ${
              subFilterData?.autoPilotIds?.includes(list?.id)
                ? "bgCE1"
                : "bg3B3936"
            }`}
            onClick={() => handleFilterChange("autoPilotIds", list?.id)}
          >
            {list?.type}
          </Button>
        ))}
        <hr className="bg3B3936" />
        <div className="mobilePadding">
          <p className="fontSize14 mb-1 px-2">Seats</p>
          {seats?.map((seat, index) => (
            <Button
              key={index}
              btnClassName={`filterButtons text-white ${seat?.available ? "" : "disabled-class"} ${
                subFilterData?.seats?.includes(seat?.name) ? "bgCE1" : "bg3B3936"
              }`}
              onClick={() => handleFilterChange("seats", seat?.name)}
            >
              {seat?.name}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterOptions;
