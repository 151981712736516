import { createSlice } from "@reduxjs/toolkit";

const modalStatusSlice = createSlice({
  name: "modalStatus",
  initialState: {
    showModalCalendar: false,
    showModalPickupLocation: false,
    showModalDropLocation: false,
    showModalFilterOptions: false,
    showModalMap: false,
    showModalBookingDetails: false,
    showModalDriverLicenseDetails: false,
    showModalPickupDropDown:false
  },
  reducers: {
    toggleShowModalCalendar: (state, action) => {
      state.showModalCalendar = action.payload;
    },
    toggleShowModalPickupLocation: (state, action) => {
      state.showModalPickupLocation = action.payload;
    },
    toggleShowModalDropLocation: (state, action) => {
      state.showModalDropLocation = action.payload;
    },
    toggleShowModalFilterOptions: (state, action) => {
      state.showModalFilterOptions = action.payload;
    },
    toggleShowModalMap: (state, action) => {
      state.showModalMap = action.payload;
    },
    toggleShowModalBookingDetails: (state, action) => {
      state.showModalBookingDetails = action.payload;
    },
    toggleShowModalDriverLicenseDetails: (state, action) => {
      state.showModalDriverLicenseDetails = action.payload
    },
    toggleShowPickupDropdown: (state, action) => {
      state.showModalPickupDropDown = action.payload
    }
  },
});

export const {
  toggleShowModalCalendar,
  toggleShowModalPickupLocation,
  toggleShowModalDropLocation,
  toggleShowModalFilterOptions,
  toggleShowModalMap,
  toggleShowModalBookingDetails,
  toggleShowModalDriverLicenseDetails,
  toggleShowPickupDropdown
} = modalStatusSlice.actions;

export default modalStatusSlice.reducer;
