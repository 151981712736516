import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import ModalContainer from "../ModalContainer";
import LocationsList from "./LocationsList";
import { useDispatch, useSelector } from "react-redux";
import {
  addDropLocation,
  addPickupAndDropId,
  addPickupLocation,
} from "../../Store/locationSlice";
import {
  toggleShowModalCalendar,
  toggleShowModalDropLocation,
  toggleShowModalPickupLocation,
} from "../../Store/modalStatusSlice";
import DropOffLocation from "./DropOffLocation";
import { BASE_URL } from "../../api/index";
import useKeyBoradNavigation from "../../Hooks/useKeyboardNavigation";
import closeIcon from "../../assets/svg/closeIcon.svg";
import whiteCloseIcon from "../../assets/svg/whiteCloseIcon.svg";
import { setDisableSearchButton } from "../../Store/buttonSlice";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { json } from "react-router-dom";
import useScrollY from "../../Hooks/useScrollY";

const PickupLocation = ({
  toggle = () => {},
  show,
  onSelect = () => {},
  dropToggle = () => {},
  dropShow,
  dropOnSelect,
  locationOptions,
  filterOptions,
  setFilterOptions = () => {},
  loading,
  error,
  handleGetServiceCount = () => {},
  siteMapLocationID,
  locationSitMapName
}) => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [activeInput, setActiveInput] = useState(null);
  const dispatch = useDispatch();
  const [garageServiceCount, setGarageServiceCount] = useState("");
  const [restrictDropOffLocations, setRestrictDropOffLocations] =
    useState(false);
  const scrollY  = useScrollY()

  const pickAndDropLocation = useSelector((store) => store?.currentLocations);
  const modalLocationStatus = useSelector((store) => store.modalStatus);
  const currentButtonStatus = useSelector((store) => store?.currentButton);
  const [optionValue, setOptionValue] = useState(pickAndDropLocation?.pickup);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState([]); // State for autocomplete suggestions
  const [suggestionLoader, setSuggestionLoader] = useState(false);
  const firstInputRef = useRef(null);
  const autocompleteRef = useRef(null); // Create a ref for Autocomplete component
  const currentPage = useSelector((state) => state?.currentPage?.page);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992 && show) {
        // If screen is smaller than 1000px and option container is shown, switch to modal
        dispatch(toggleShowModalPickupLocation(true));
        toggle(); // Hide the option container
      } else if (
        window.innerWidth >= 992 &&
        modalLocationStatus?.showModalPickupLocation
      ) {
        // If screen is resized back to 1000px or more, close the modal
        dispatch(toggleShowModalPickupLocation(false));
        toggle();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [show, modalLocationStatus?.showModalPickupLocation, toggle]);

  useEffect(() => {
    if (siteMapLocationID && locationSitMapName) {
      handleChangeLocation(
        locationSitMapName,
        siteMapLocationID,
        null, // location.latitude
        null, // location.longitude
        {fromSiteMap: true}
      );
    }
    //eslint-disable-next-line
  }, [siteMapLocationID, locationSitMapName]);

  useEffect(() => {
    // Only attempt to focus if the modal has just been made visible
    if (
      modalLocationStatus?.showModalPickupLocation &&
      !modalLocationStatus?.showModalDropLocation &&
      firstInputRef?.current
    ) {
      firstInputRef?.current?.focus();
      setActiveInput("pickup");
    }
  }, [modalLocationStatus]);

  useEffect(() => {
    // Only attempt to focus if the modal has just been made visible
    if (modalLocationStatus?.showModalPickupLocation) {
      setActiveInput("pickup");
    }
  }, [modalLocationStatus, setActiveInput]);

  const toggleLocationDisplay = () => {
    if (window?.innerWidth < 992) {
      dispatch(toggleShowModalPickupLocation(true));
    } else {
      toggle();
    }
  };

  useEffect(() => {
    if(modalLocationStatus?.showModalPickupDropDown){
    toggleLocationDisplay()
    firstInputRef?.current?.focus();
  }
     //eslint-disable-next-line
  }, [modalLocationStatus?.showModalPickupDropDown, dispatch])

  //bigscreen handleChange
  const handleChange = (e) => {
    if (e.target.value === "") {
      localStorage.removeItem("newFilteredPickupGoogleLocations");
      localStorage.removeItem("serviceCount");
    }
    dispatch(setDisableSearchButton(true));
    setOptionValue(e.target.value);
    dispatch(addPickupLocation(e.target.value));
    localStorage.setItem("localCurrentPickupLocation", JSON.stringify(e.target.value))
    const newValue = e.target.value.replace(/[\s,-]+/g, "").toLowerCase(); // Remove spaces and commas from input

    const res = locationOptions?.filter((opt) => {
      // Remove spaces and commas from each option and compare
      const cleanedOption = opt.displayAddress
        .replace(/[\s,-]+/g, "")
        .toLowerCase();
      return cleanedOption.includes(newValue);
    });
    // setFilterOptions(res);
  };

  useEffect(() => {
    if (optionValue?.length === 0) {
      setGarageServiceCount("");
      setAutocompleteSuggestions([]);
    }
  }, [optionValue]);

  useEffect(() => {
    if (optionValue === "") {
      localStorage.removeItem("newFilteredPickupGoogleLocations");
      localStorage.removeItem("serviceCount");
      localStorage.removeItem("disableDropLocation");
    }
  }, [optionValue]);

  // Handles the place changed event from Google Places Autocomplete
  const handlePlaceChanged = () => {
    const place = autocompleteRef.current?.getPlace(); // Correctly access the place
    const currentLatitude = place?.geometry?.location?.lat();
    const currentLongitude = place?.geometry?.location?.lng();

    if (place && place.formatted_address) {
      handleChangeLocation(
        place.formatted_address,
        place.place_id,
        currentLatitude,
        currentLongitude
      );
    }
  };

  //new code location api
  const fetchPredictions = useCallback(
    (input = pickAndDropLocation?.pickup) => {
      if (!window.google || !window.google.maps) return;

      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        { input, componentRestrictions: { country: "us" } },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            // Only return descriptions and place_id for predictions
            const results = predictions.map((prediction) => ({
              description: prediction?.description,
              placeId: prediction?.place_id,
            }));
            setAutocompleteSuggestions(results);
          } else {
            setAutocompleteSuggestions([]);
          }
        }
      );
    },
    [pickAndDropLocation?.pickup]
  );

  // Handle user selection of a prediction (when they click on a suggestion)
  const handlePlaceSelect = (placeId) => {
    if (!window.google || !window.google.maps) return;

    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    // Fetch the detailed info (lat, lng, placeId) when the user selects a place
    placesService.getDetails({ placeId }, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place
      ) {
        const selectedPlace = {
          description: place.name, // or you can use another property
          placeId: place.place_id,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        handleChangeLocation(
          place.formatted_address,
          place.place_id,
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        // Do something with the selected place details (e.g., store it in state)
        // setSelectedPlace(selectedPlace);
      }
    });
  };

  // useEffect(() => {
  //   fetchPredictions(); //this is to render the location list of initial input value
  // }, [fetchPredictions]);

  useEffect(() => {
    if (pickAndDropLocation?.pickup === "") {
      dispatch(setDisableSearchButton(true)); //this isto disable the search buttom if there is no pickup value
    }
  }, [dispatch, pickAndDropLocation]);

  const handleInputChange = (e) => {
    handleChange(e);
    fetchPredictions(e.target.value);
    if (!show && window.innerWidth > 992) {
      toggle(); //it opens the locations dropdown when the user changes the input value in desktop screen
    }
  };

  //Location change functionality
  const handleChangeLocation = (
    location,
    pickupId,
    currentLatitude,
    currentLongitude,
    garageLocationOptionSelected,
    { fromSiteMap = false } = {}
  ) => {
    
    if (currentLatitude !== null || currentLongitude !== null) {
      getNearByGarages(
        currentLatitude,
        currentLongitude,
        garageLocationOptionSelected ? pickupId : ""
      );
      // setRestrictDropOffLocations(false)
    } else {
      setGarageServiceCount(1); //here we have given count as 1 to disbale the drop off field
      if(window?.innerWidth > 992){
        onSelect();
      }
      // setRestrictDropOffLocations(true)
      dispatch(toggleShowModalPickupLocation(false));
      handleGetServiceCount(1, true);
      localStorage.removeItem("newFilteredDropGoogleLocations");
    }

    const dropId = pickupId; // sending the dropId or locationData of same pickupId to display on the inputs
    const currentDropoffLatitude = currentLatitude; //  sending the droplatitude and droplongitude to store
    const currentDropoffLongitude = currentLongitude;
    setOptionValue(location);
    dispatch(addPickupLocation(location));
    localStorage.setItem("localCurrentPickupLocation", JSON.stringify(location))
    dispatch(addDropLocation(location));
    localStorage.setItem("localCurrentDropLocation", JSON.stringify(location))
    dispatch(
      addPickupAndDropId({
        pickupId,
        dropId,
        currentLatitude,
        currentLongitude,
        currentDropoffLatitude,
        currentDropoffLongitude,
      })
    );
    dispatch(setDisableSearchButton(false));
    // toggleLocationDisplay();
    if (window.innerWidth < 992) {
      setActiveInput("drop");
    } else {
      // onSelect();
    }

    setFilterOptions(locationOptions);
  };

  const getNearByGarages = async (
    currentLatitude,
    currentLongitude,
    pickupId = ""
  ) => {
    setSuggestionLoader(true);
    const payload = {
      city: "",
      country: "",
      latitude: currentLatitude,
      longitude: currentLongitude,
      pickupIds: [pickupId],
      state: "",
    };
    try {
      const result = await axios.post(`${BASE_URL}location/open`, payload);
      setFilterOptions(result?.data?.data?.locations);
      setGarageServiceCount(result?.data?.data?.serviceableGaragesCount);
      localStorage.setItem(
        "newFilteredPickupGoogleLocations",
        JSON.stringify(result?.data?.data?.locations)
      );
      localStorage.removeItem("newFilteredDropGoogleLocations");
      if (result?.data?.data?.serviceableGaragesCount > 0) {
        handleGetServiceCount(
          result?.data?.data?.serviceableGaragesCount,
          false
        );
        // setGarageServiceCount(result?.data?.data?.serviceableGaragesCount)
        dispatch(toggleShowModalPickupLocation(false));
        if(window?.innerWidth > 992){
          onSelect();
        }
        dispatch(setDisableSearchButton(false));
        dispatch(
          addPickupAndDropId({
            pickupId: result?.data?.data?.locations[0]?.id,
            dropId: result?.data?.data?.locations[0]?.id,
          })
        );
        localStorage.setItem(
          "allLocationsTimeZones",
          JSON.stringify(result?.data?.data?.locations[0])
        );
        setSuggestionLoader(false);
      } else {
        dispatch(setDisableSearchButton(true));
        // dispatch(addPickupLocation(""));
        // dispatch(addDropLocation(""));
        setSuggestionLoader(false);
        firstInputRef?.current?.focus();
        setAutocompleteSuggestions([]);
        setFilterOptions(locationOptions);
      }
    } catch (error) {
      setSuggestionLoader(false);
    }
  };

  const newFilteredPickupGoogleLocations = JSON.parse(
    localStorage.getItem("newFilteredPickupGoogleLocations")
  );
  // console.log(newFilteredPickupGoogleLocations);

  const { handleKeyDown, highlightedIndex } = useKeyBoradNavigation(
    filterOptions,
    handleChangeLocation
  ); //used for navigate the options through up and down arrows

  if (loading)
    return (
      <div className="mb-0 p-2 col-lg-3 text-center">
        <Spinner />
      </div>
    );
  if (error) return <div>Error loading locations!</div>;

  // if string length is above 25 && ....
  function truncateString(str, num) {
    if (str.length > num) {
      return str.substring(0, num) + "...";
    }
    return str;
  }

  const handleClear = () => {
    dispatch(addPickupLocation(""));
    localStorage.removeItem("localCurrentPickupLocation")
    dispatch(addPickupAndDropId({ pickupId: "" }));
    firstInputRef?.current?.focus();
    dispatch(setDisableSearchButton(true));
    setFilterOptions(locationOptions);
    setGarageServiceCount("");
    setAutocompleteSuggestions([]);
    // setRestrictDropOffLocations(false)
    handleGetServiceCount(1, true);
    localStorage.removeItem("newFilteredPickupGoogleLocations");
    localStorage.removeItem("disableDropLocation");
  };

  return (
    <div className={`position-relative ${scrollY > 500 ? "col-lg-2" : "col-lg-3"} col-sm-12 `}>
      <div className="d-flex justify-content-between align-items-center">
      <div
        className={`fontAntarctica fontSize13 commonBgInput borderRadius8 p-2 mb-2 mb-lg-0 ${currentPage === "car-list" ? "" :"w-100"}`}
        // onClick={toggleLocationDisplay}
      >
        <p className={`${isDarkMode ? "fontColor212529 fontWeight700" : "fontcolor936"} textAlignLeft mb-1`}>
          Pick Up
        </p>
        <div className={`${currentPage === "car-list" ? "d-flex justify-content-between" : ""}`}>
          <Autocomplete
            onPlaceChanged={handlePlaceChanged}
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} // Assign autocomplete instance to ref
          >
            <div className="d-flex justify-content-between align-items-center border-bottom-grey">
              <input
                type="text"
                // value={
                //   window.innerWidth < 992
                //     ? pickAndDropLocation?.pickup === pickAndDropLocation?.drop
                //       ? `${truncateString(pickAndDropLocation.pickup, 45)}`
                //       : `${truncateString(
                //           pickAndDropLocation.pickup,
                //           25
                //         )} - ${truncateString(pickAndDropLocation.drop, 45)}`
                //     : pickAndDropLocation?.pickup
                // }
                readOnly={window.innerWidth < 992}
                value={
                  window.innerWidth < 992
                    ? pickAndDropLocation?.pickup === pickAndDropLocation?.drop
                      ? `${truncateString(pickAndDropLocation.pickup, 45)}`
                      : `${truncateString(pickAndDropLocation.pickup, 25)}`
                    : pickAndDropLocation?.pickup
                }
                placeholder="Location"
                className={`${
                  isDarkMode ? "fontColor212529" : "fontColor916"
                } inputOptionBox w-100`}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onClick={toggleLocationDisplay}
                ref={firstInputRef}
                maxLength={150}
              />
              {show ? (
                <div className="d-none d-lg-flex px-2">
                  <img onClick={handleClear} src={closeIcon} alt="closeIcon" />
                </div>
              ) : (
                ""
              )}
            </div>
          </Autocomplete>
        </div>
      </div>
      {currentPage === "car-list" && (
            <div
            className={`${
              isDarkMode ? "fontColor212529" : "fontcolor936"
            }  fontAntarctica fontSize13 w-50`}
              onClick={() => {
                dispatch(toggleShowModalPickupLocation(false));
                dispatch(toggleShowModalDropLocation(true));
              }}
            >
             <div className="mb-1 fontWeight700">Drop Off</div>
              <div className={`${
                  isDarkMode ? "fontColor212529 " : "fontColor916"
                } inputOptionBox border-bottom-grey mb-2 text-truncate`}>
                  {pickAndDropLocation?.drop}
                </div>
            </div>
          )}
          </div>
      {window.innerWidth < 992 ||
      modalLocationStatus?.showModalPickupLocation ? (
        <ModalContainer
          isVisible={modalLocationStatus?.showModalPickupLocation}
          onClose={() => {
            dispatch(toggleShowModalPickupLocation(false));
            dispatch(toggleShowModalDropLocation(false));
          }}
          modalTopHeading="Where"
        >
          <div className="px-2 py-1">
            <div className="w-100 fontAntarctica fontSize13">
              {/* <p className="mb-1 fontColorC7 fontSize14">Where</p> */}
              {/* <hr /> */}
              <div
                className={`inputModalOptionBox ${
                  activeInput === "pickup" ? "borderTrue" : ""
                }`}
              >
                <p className="my-1 fontAntarctica fontSize9">Pickup</p>
                <Autocomplete
                  onLoad={(autocomplete) =>
                    (autocompleteRef.current = autocomplete)
                  } // Assign autocomplete instance to ref
                  onPlaceChanged={handlePlaceChanged}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <input
                      type="text"
                      value={pickAndDropLocation?.pickup}
                      className={`${
                        isDarkMode ? "fontcolorFFF" : "fontColor916"
                      } inputOptionBox  text-truncate`}
                      onChange={handleInputChange}
                      onFocus={() => setActiveInput("pickup")}
                      ref={firstInputRef}
                      onKeyDown={handleKeyDown}
                      onClick={() => {
                        //here we are sending this to focus on the pickup input field in small screens
                        dispatch(toggleShowModalPickupLocation(true));
                        dispatch(toggleShowModalDropLocation(false));
                      }}
                      maxLength={150}
                    />
                    {activeInput === "pickup" ? (
                      <div className="px-2">
                        <img
                          onClick={handleClear}
                          src={whiteCloseIcon}
                          alt="whiteCloseIcon"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Autocomplete>
                <div></div>
              </div>
              {/* {garageServiceCount > 0 || autocompleteSuggestions?.length> 0 ? "" : <div className="px-2 fontColorFE9 opacity-75">Please search for your address or you can select a garage from the list below</div>} */}
              {activeInput === "pickup" ? (
                <div className="main-option-container w-100">
                  <LocationsList
                    show={show}
                    filterOptions={
                      newFilteredPickupGoogleLocations
                        ? newFilteredPickupGoogleLocations
                        : filterOptions
                    }
                    handleChangeLocation={handleChangeLocation}
                    optionValue={optionValue}
                    highlightedIndex={highlightedIndex}
                    autocompleteSuggestions={autocompleteSuggestions}
                    handlePlaceSelect={handlePlaceSelect}
                    suggestionLoader={suggestionLoader}
                    mainTitle="Garage Pickup"
                    garageServiceCount={garageServiceCount}
                    restrictDropOffLocations={restrictDropOffLocations}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <DropOffLocation
              toggle={dropToggle}
              show={false} //we are sendong false to not show in mobile screen
              // onSelect={dropOnSelect}
              showInputContainer={true}
              activeInput={activeInput}
              setActiveInput={setActiveInput}
              locationOptions={locationOptions}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              loading={loading}
              error={error}
            /> */}
          </div>
          {/* <div className="px-2">
            <div className="modal-footer d-flex justify-content-end align-items-end w-100">
              <button
                onClick={() => {
                  if (!currentButtonStatus?.disableSearchButton) {
                    dispatch(toggleShowModalCalendar(true));
                    dispatch(toggleShowModalPickupLocation(false));
                    setActiveInput(null);
                  }
                }}
                className={`w-100 searchButton ${
                  currentButtonStatus?.disableSearchButton ? "bgBA3" : "bgCE1"
                } text-white`}
              >
                Proceed
              </button>
            </div>
          </div> */}
        </ModalContainer>
      ) : (
        show && (
          <div className="main-option-container">
            <LocationsList
              show={show}
              filterOptions={
                newFilteredPickupGoogleLocations
                  ? newFilteredPickupGoogleLocations
                  : filterOptions
              }
              handleChangeLocation={handleChangeLocation}
              optionValue={optionValue}
              highlightedIndex={highlightedIndex}
              autocompleteSuggestions={autocompleteSuggestions}
              handlePlaceSelect={handlePlaceSelect}
              suggestionLoader={suggestionLoader}
              mainTitle="Garage Pickup"
              garageServiceCount={garageServiceCount}
              restrictDropOffLocations={restrictDropOffLocations}
            />
          </div>
        )
      )}
    </div>
  );
};

export default PickupLocation;
