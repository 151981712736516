import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import joulezIcon from "../assets/svg/joulezIcon.svg";
import joulezLightIcon from "../assets/svg/JoulezLightIcon.svg";
import navBarToggleIcon from "../assets/svg/navbarToggleIcon.svg";
import lightModeIcon from "../assets/svg/lightMode.svg";
import darkModeIcon from "../assets/svg/darkMode.svg";
import { NAVBAR_LIST } from "../assets/Constants";
import { useDispatch, useSelector } from "react-redux";
import { toggleMode } from "../Store/lightAndDarkModeSlice";
import { useEffect, useRef, useState } from "react";
import searchIcon from "../assets/svg/searchIcon.svg";
import filterIcon from "../assets/svg/filterIcon.svg";
import hidePasswordIcon from "../assets/svg/hidePasswordIcon.svg";
import showPasswordIcon from "../assets/svg/showPasswordIcon.svg";
import profilePersonLogo from "../assets/svg/profilePersonLogo.svg";
import MyTripLogo from "../assets/svg/MyTripMobile.svg";
import signoutLogo from "../assets/svg/Signout.svg";
import PickupLocation from "./SearchComponent/PickupLocation";
import TimeSelector from "./SearchComponent/TimeSelector";
import {
  toggleShowModalBookingDetails,
  toggleShowModalFilterOptions,
  toggleShowModalMap,
} from "../Store/modalStatusSlice";
import DesktopCustomModal from "./DesktopCustomModal";
import googleIcon from "../assets/svg/google_Icon.svg";
import amazonIcon from "../assets/svg/amazon_icon.svg";
import appleIcon from "../assets/svg/apple_icon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { BASE_URL } from "../api/index";
import { useGoogleLogin } from "@react-oauth/google";
import { setCurrentUserDetails } from "../Store/userDetailsSlice";
import {
  setOpenLoginDesktopModal,
  setOpenSignUpDesktopModal,
} from "../Store/buttonSlice";
import CommonCalendar from "./CommonCalendar";
import { endOfYear, format, setYear } from "date-fns";
import { useFetchLocations } from "../Hooks/useFetchLocations";
import SpinnerLoader from "./SpinnerLoader";
// import AmazonLogin from "./AmazonLogin";
import SocialLogins from "../Components/SocialLogins";
import userProfileLogo from "../assets/svg/user-profile.svg";
import DateOfBirthPicker from "./DateOfBirthPicker";
import useDateCount from "../Hooks/useDateCount";
import Recaptcha from "./Recaptcha";

const Navbar = ({ onLogin }) => {
  const dateNewCount = useDateCount()//we will pass the date count to the navbar in mobile screen
  const { locationOptions, filterOptions, setFilterOptions } =
    useFetchLocations(`${BASE_URL}location/open`);
  const currentPage = useSelector((state) => state?.currentPage?.page);
  const currentBookingScreenLoginAndSignUp = useSelector(
    (state) => state?.currentButton
  );
  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);
  const pickAndDropLocation = useSelector((store) => store?.currentLocations);
  const currentDatesAndTimings = useSelector(
    (store) => store?.currentDatesAndTimings
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navbarRef = useRef(null);
  const [collapseDropDown, setCollapseDropDown] = useState(true);
  const [signIn, setSignIn] = useState(true);
  const [resetEmailAddress, setResetEmailAddress] = useState("");

  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
    errors: "",
  });
  const [socialUser, setSocialUser] = useState({
    provider: "",
    id: "",
    email: "",
    name: "",
    photoUrl: "",
    firstName: "",
    lastName: "",
    authToken: "",
    idToken: "",
    authorizationCode: "",
    response: null,
  });
  const [signUp, setSignUp] = useState({
    email: "",
    password: "",
    firstName: "",
    middleName: "",
    lastName: "",
    countryCode: "",
    phoneNumber: "",
    phoneNumberCountryCode: "",
    dob: "",
    loginType: "CUSTOM",
    id: "",
    selectOptIn: false,
  });
  const [signUpLoader, setsignUpLoader] = useState(false);
  const [SignupErrorsFromBackend, setSignupErrorsFromBackend] = useState("");
  const [signupErrors, setSignupErrors] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    dob: "",
  });

  const [signupCheck, setSignUpcheck] = useState(false);
  const [showCalendarForSignUp, setShowCalendarForSignUp] = useState(false);

  const [response, setResponse] = useState(null); //this state is used to save the response after authenticating
  const [thankyouNavigateUserDetails, setThankyouNavigateUserDetails] =
    useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const [showPassword, setShowPassword] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const openModal = (modalId) => setActiveModal(modalId);
  const closeModal = () => {
    const navbarCollapse = document.getElementById("navbarNav");
    setActiveModal(null);
    dispatch(setOpenLoginDesktopModal(false));
    dispatch(setOpenSignUpDesktopModal(false));
    navbarCollapse.classList.remove("show");
  };

  const profileDetails = JSON.parse(
    sessionStorage?.getItem("CurrentUserDetails")
  );

  const [isProfileLoggedIn, setIsProfileLoggedIn] = useState(false);

  useEffect(() => {
    // Simulate async behavior by waiting for sessionStorage data
    const timer = setTimeout(() => {
      const profileDetails = JSON.parse(sessionStorage?.getItem("CurrentUserDetails"));
      if(profileDetails){
        setIsProfileLoggedIn(true)
      } else {
        setIsProfileLoggedIn(false)
      }
    }, 2000); // Simulate delay (1 second)

    return () => clearTimeout(timer); // Clean up timeout on component unmount
  }, []); // Run once on component mount

  // console.log(profileDetails)
  const handleChangeMode = () => {
    dispatch(toggleMode());
  };

  const handleDateSelect = (selectedDate) => {
    setSignUp({ ...signUp, dob: selectedDate }); // Update DOB with the selected date
    setSignupErrors({ ...signupErrors, dob: "" });
    setShowCalendarForSignUp(false);
  };

  
  const urlParams = new URLSearchParams(window.location.search);
  const hashcode = urlParams.get('hashCode');

  // Decode the hashcode from Base64
  const decodedHashcode = atob(hashcode);
  let splitDecodedHashcode = decodedHashcode?.split("--")
  //console.log(splitDecodedHashcode);  

  useEffect(() => {
      if((splitDecodedHashcode?.includes("CUSTOM") || splitDecodedHashcode?.includes("GUEST_USER")) && splitDecodedHashcode[1] === "null"){
        openModal("SignInAndSignUpModal")
        setSignIn(false)
        setSignInData((prev) => ({
          ...prev,
          email:splitDecodedHashcode[0]
        }))
      }

      if((splitDecodedHashcode?.includes("CUSTOM") || splitDecodedHashcode?.includes("GUEST_USER")) && splitDecodedHashcode[1] !== "null"){
        setSignInData((prev) => ({
          ...prev,
          email:splitDecodedHashcode[0]
        }))
      }
      
      if(splitDecodedHashcode?.includes("GOOGLE")){
        openModal("SignInAndSignUpModal")
        setSignIn(false)
         setTimeout(() => {
          handleGoogleLogin()
         }, 1000)
      }

      if(splitDecodedHashcode?.includes("APPLE")){
        sessionStorage.setItem("userJDDetails", JSON.stringify(splitDecodedHashcode));
        openModal("SignInAndSignUpModal")
        setSignIn(false)
         setTimeout(() => {
          handleAppleSignIn()
         }, 2000)
      }

      if(splitDecodedHashcode?.includes("AMAZON")){
        sessionStorage.setItem("userJDDetails", JSON.stringify(splitDecodedHashcode));
        openModal("SignInAndSignUpModal")
        setSignIn(false)
        //  setTimeout(() => {
        //   handleCloseAmazonSigninModal()
        //  }, 1000)
      }

    }, [])

  useEffect(() => {
    if (currentBookingScreenLoginAndSignUp?.bookingScreenLoginButton) {
      setTimeout(() => {
        openModal("SignInAndSignUpModal");
      }, 200);
      setSignIn(false);
    }

    if (currentBookingScreenLoginAndSignUp?.bookingScreenSignUpButton) {
      setTimeout(() => {
        openModal("SignInAndSignUpModal");
      }, 200);
      setSignIn(true);
    }
  }, [currentBookingScreenLoginAndSignUp]);

  useEffect(() => {
    const handleThemeChange = (e) => {
      const prefersDarkMode = e.matches;
      if (prefersDarkMode) {
        if (!isDarkMode) {
          dispatch(toggleMode());
        }
      } else {
        if (isDarkMode) {
          dispatch(toggleMode());
        }
      }
    };

    const darkModeListener = window.matchMedia("(prefers-color-scheme: dark)");
    darkModeListener.addEventListener("change", handleThemeChange);

    return () => {
      darkModeListener.removeEventListener("change", handleThemeChange);
    };
  }, [dispatch, isDarkMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarCollapse = document.getElementById("navbarNav");
        //Here the show is a bootstrap className we used this to close the navbar if user click outside
        if (navbarCollapse?.classList?.contains("show")) {
          navbarCollapse?.classList?.remove("show");
        }
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  //After clicking the the nav name the navbar should close
  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById("navbarNav");
    if (navbarCollapse.classList.contains("show")) {
      navbarCollapse.classList.remove("show");
    }
  };

  useEffect(() => {
    //it will close the nave bar menu when you change the page
    handleNavLinkClick()
  }, [currentPage])

  const handleChangeForSignIn = (e) => {
    setError("")
    const { id, value } = e.target;
    validateLoginField(id, value)
    setSignInData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const validateEmail = (email) => {
    const pattern = /^[^ @]+@[^ @]+\.[^ @]+$/;
    return pattern.test(email);
  };

  const validatePassword = (password) => {
    const pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/;
    return pattern.test(password);
  };

  //it is used to get the current user details
  const getUserDetails = async () => {
    const token = sessionStorage.getItem("AccessToken");
    try {
      const userResponse = await axios.get(`${BASE_URL}user/currentUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setResponse(userResponse?.data?.data);
      setThankyouNavigateUserDetails(userResponse?.data?.data);

      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(userResponse?.data?.data)
      );
      // dispatch(setCurrentUserDetails({profileDetails:response?.data?.data}));
    } catch (error) {
      setError(error);
    }
  };

  // Validation functions for signup
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    phoneNumber: false,
    dob: false,
  });

  const validateLoginField = (name, value) => {
    const newErrors = { email: "", password: "" };

    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/;
  
      switch (name) {
        case "email":
          if ((value && !emailRegex.test(value) )|| !value) {
            newErrors.email = "Please enter a valid email address.";
          }
          break;
        case "password":
          if (value?.length === 0) {
            newErrors.password =
              "Password is required";
          }
          break;
        default:
          break;
      }

      if (newErrors.email || newErrors.password) {
        setSignInData((prevState) => ({
          ...prevState,
          errors: newErrors,
        }));
      } else {
        setSignInData((prevState) => ({
          ...prevState,
          errors: "",
        }));
      }
  }

  // Validation functions
  const validateField = (name, value) => {
    let error = "";

    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    //const phoneRegex = /^[0-9]{1}[0-9]{9}$/;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/;
    const nameRegex = /^[a-zA-Z]+$/;

    switch (name) {
      case "firstName":
        if (!value || value.length < 3) {
          error = "First name must be at least 3 characters.";
        }
        break;
      case "lastName":
        if (!value || value.length < 3) {
          error = "Last name must be at least 3 characters.";
        }
        break;
      case "email":
        if ((value && !emailRegex.test(value) )|| !value) {
          error = "Please enter a valid email address.";
        }
        break;
      // case "phoneNumber":
      //   if (!value || !phoneRegex.test(value)) {
      //     error = "Please enter valid Phone Number.";
      //   }
      //   break;
      case "password":
        if (!passwordRegex.test(value)) {
          error =
            "Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number and a special character (such as !@#$%^&*_=+-)";
        }
        break;
      case "dob":
        if (!value) {
          error = "Date of birth is required.";
        }
        break;
      case "phoneNumber":
        if (!value) {
          error = "Phone Number is required.";
        }
          break;
      default:
        break;
    }

    return error;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched((prevState) => ({ ...prevState, [name]: true }));
    const error = validateField(name, value);
    setSignupErrors((prevState) => ({ ...prevState, [name]: error }));
  };

  const handleSignUp = (e) => {
    const { name, type, value, checked } = e.target;

    setSignUp((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value, // For checkbox, use 'checked', else use 'value'
    }));

    if (showCalendarForSignUp) {
      setShowCalendarForSignUp(false);
    }

    // Optionally validate in real-time on change as well
    // if (touched[name]) {
      const error = validateField(name, value);
      setSignupErrors((prevState) => ({ ...prevState, [name]: error }));
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = {};
    let isValid = true;

    // Validate all fields before submitting
    Object.keys(signUp).forEach((key) => {
      const error = validateField(key, signUp[key]);
      if (error) {
        formErrors[key] = error;
        isValid = false;
      }
    });

    setSignupErrors(formErrors);
    if (isValid) {
      try {
        setsignUpLoader(true);
        const formattedDob = signUp.dob
          ? format(new Date(signUp.dob), "yyyy-MM-dd")
          : "";

        // Prepare the payload with formatted dob
        const payload = {
          ...signUp,
          dob: formattedDob, // Make sure dob is in yyyy-MM-dd format
          phoneNumber: signUp?.phoneNumber?.slice(
            signUp?.countryCode?.length - 1
          ),
          selectOptIn: signupCheck,
        };
        const response = await axios.post(
          `${BASE_URL}user/customUserSignUp/open`,
          payload
        );
        sessionStorage.setItem("AccessToken", response?.data?.data?.jwt);
        dispatch(setCurrentUserDetails(response?.data?.data?.jwt));
        setSignupErrorsFromBackend("");
        getUserDetails();
        toast.success(response?.data?.data?.message);
        closeModal("SignInAndSignUpModal");
        setsignUpLoader(false);
        callAnalytics();
        const selectedCar = localStorage.getItem("selectedCar");
        const bookingInputs = localStorage.getItem("bookingInputs");
        if (selectedCar && bookingInputs) {
          handlePostLoginLogic(response?.data?.data?.jwt);
        } else {
          navigate("/thankyou");
        }
        setSignUp({
          email: "",
          password: "",
          firstName: "",
          middleName: "",
          lastName: "",
          countryCode: "",
          phoneNumber: "",
          phoneNumberCountryCode: "",
          dob: "",
          loginType: "CUSTOM",
        });
      } catch (error) {
        // toast.error(error?.response?.data?.message);
        setSignupErrorsFromBackend(error?.response?.data?.message);
        setsignUpLoader(false);
      }
    }
  };

  const handlePhoneChange = (value, country) => {
    validateField("phoneNumber", value)
    const numericValue = value.replace(/\D/g, "");
    if (numericValue?.length < 5) {
      setSignupErrors({
        ...signupErrors,
        phoneNumber: "Please enter valid number",
      });
    } else {
      setSignupErrors({ ...signupErrors, phoneNumber: "" });
    }

    if (numericValue.length !== 0) {
      setSignUp((prev) => ({
        ...prev,
        countryCode: `+${country?.dialCode}`,
        phoneNumberCountryCode: country?.countryCode,
        phoneNumber: numericValue,
      }));
    }
  };

  const callAnalytics = () => {
    window.gtag("event", "conversion", {
      send_to: "AW-10903830625/q9QFCImYubYYEOGArc8o",
    });
  };
  //it is used to validate the email and password and then sign in to app
  const handleSubmitSignInData = async () => {
    // setLoading(true);
    const newErrors = { email: "", password: "" };

    // Check if email is valid
    if (
      signInData.email !== "joulez_admin" &&
      !validateEmail(signInData.email)
    ) {
      newErrors.email = "Email address is required.";
    }

    // Check if password is valid
    if (
      signInData.password !== "987654" &&
      signInData.password?.length === 0
    ) {
      setError("")
      newErrors.password =
        "Password is required."
      }

    // If there are errors, update the state with the errors
    if (newErrors.email || newErrors.password) {
      setSignInData((prevState) => ({
        ...prevState,
        errors: newErrors,
      }));
    } else {
      // Handle successful submission
      setSignInData((prevState) => ({
        ...prevState,
        errors: "",
      }));
      try {
        setLoading(true);
        const res = await axios.post(
          `${BASE_URL}authenticate`,
          {
            username: signInData?.email,
            password: signInData?.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setResponse(res?.data?.data);
        setLoading(false); // Set loading to false after data is fetched
        setError("");
        closeModal();
        if (res?.status === 200) {
          toast.success("Signed in successfully");
          onLogin(true); //this is a function in appRouter
          sessionStorage.setItem("AccessToken", res?.data?.data?.jwt);
          dispatch(setCurrentUserDetails(res?.data?.data?.jwt));
          getUserDetails(); // to get the user details
          if(hashcode !== null){
            navigate("/booking-history", { state: { JDBookingId: splitDecodedHashcode[3] } });
          }
          if(splitDecodedHashcode?.length === 2){
            navigate("/")
          }
        }
      } catch (error) {
        setResponse("");
        setError(error?.response?.data?.message); // Set error message
        setLoading(false); // Set loading to false in case of an error
      }
    }
  };
  const profileRef = useRef();

  const handleProfileClickOutside = (event) => {
    // Check if the click is outside the profileRef element
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      if (toggleProfile) {
        setToggleProfile(false); // Close the profile
      }
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    document.addEventListener("mousedown", handleProfileClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleProfileClickOutside);
    };
    //eslint-disable-next-line
  }, [toggleProfile]); // Add toggleProfile as a dependency

  const handleCustomLogout = () => {
    toast.success("Signed out successfully");
    sessionStorage.removeItem("CurrentUserDetails");
    setResponse("");
    sessionStorage.removeItem("AccessToken");
    onLogin(false);
    dispatch(setCurrentUserDetails({ userDetails: "" }));
    navigate("/");
    localStorage.removeItem("selectedCar");
    localStorage.removeItem("bookingInputs");
    localStorage.removeItem("disableDropLocation");
    localStorage.removeItem("serviceCount");
    localStorage.removeItem("newFilteredPickupGoogleLocations");
    localStorage.removeItem("newFilteredDropGoogleLocations");
    //localStorage.removeItem("estimatedPriceDetails");
    localStorage.removeItem("localIsId1Disabled");
    localStorage.removeItem("localCurrentPickupLocation");
    localStorage.removeItem("localCurrentDropLocation");
    localStorage.removeItem("bookingInputs");
    localStorage?.removeItem("PaymentCard");
    localStorage.removeItem("insuranceDoc")
    localStorage.removeItem("daysCount");
    localStorage.removeItem("isRentalCoverageModal")
    sessionStorage.removeItem("userJDDetails")
    // Refresh the page after logout
    window.location.reload();
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      // Example API call with the token
      const decodedUser = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      setSocialUser(decodedUser);
      // Populate signupUser details
      const updatedSignupUser = {
        ...signUp,
        id: decodedUser?.data?.sub,
        firstName: decodedUser?.data?.given_name,
        lastName: decodedUser?.data?.family_name,
        email: decodedUser?.data?.email,
        loginType: "google",
      };
      setSignUp(updatedSignupUser);
      try {
        const response = await axios.post(
          `${BASE_URL}user/socialLogin/open`,
          updatedSignupUser
        ); // Replace with your API
        const { jwt } = response.data.data;
        // Store JWT in Local storage
        sessionStorage.setItem("AccessToken", jwt);
        // Decode JWT to get user details
        const userDTO = jwtDecode(jwt);
        userDTO.profileImage = decodedUser.picture;
        // Fetch current user and update application state
        const userDataResponse = await axios.get(
          `${BASE_URL}user/currentUser`,
          {
            headers: { Authorization: `Bearer ${jwt}` },
          }
        );
        onLogin(true);
        const userData = userDataResponse.data;
        sessionStorage.setItem(
          "CurrentUserDetails",
          JSON.stringify(userData?.data)
        );
        dispatch(setCurrentUserDetails(jwt));
        toast.success(response.data.data.message);
        // Handle booking or other post-login logic
        if (response.data.data.message === "Successfully signed up") {
          this.callAnalytics();
        }
        handlePostLoginLogic(jwt, userData);
        //  <SpinnerLoader />
        setLoading(false);
        closeModal("SignInAndSignUpModal"); // Close modal on successful login

        if(hashcode !== null){
          navigate("/booking-history", { state: { JDBookingId: splitDecodedHashcode[3] } });
        }

      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setLoading(false);
    },
  });

  const handlePostLoginLogic = async (jwt, userData) => {
    const selectedCar = localStorage.getItem("selectedCar");
    const bookingInputs = localStorage.getItem("bookingInputs");
    if (selectedCar && bookingInputs) {
      try {
        const parsedBookingInputs = JSON.parse(bookingInputs);
        const estimationResponse = await axios.post(
          `${BASE_URL}booking/estimatedPrice`,
          parsedBookingInputs,
          { headers: { Authorization: `Bearer ${jwt}` } }
        );
        localStorage.setItem(
          "bookingInputs",
          JSON.stringify(estimationResponse?.data?.data)
        );
      } catch (error) {
        toast.error(
          error.response?.data?.message || "Error fetching estimation price."
        );
      }
    }
  };

  useEffect(() => {
    //Aplle Sign IN
    window?.AppleID?.auth?.init({
      clientId: "your.services.id",
      scope: "email name",
      redirectURI: "https://yourapp.com/auth/callback",
      usePopup: true, // Use a popup for authentication
    });
  }, []);

  //Apple sign in function
  // const baseUrl = "https://dev.drivejoulez.com:8443/joulez-service";
  const REDIRECT_API_URL = BASE_URL + "user/applelogin/authorize/open";
  const CLIENT_ID = "com.drivejoulez.joulezService";
  const handleAppleSignIn = () => {
    try {
    setLoading(true);
    const appleLoginUrl = `https://appleid.apple.com/auth/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
      REDIRECT_API_URL
    )}&response_type=code id_token&scope=name email&response_mode=form_post`;
    window.open(appleLoginUrl, "_self");
    setLoading(false);
    const messageListener = (event) => {
      setLoading(false)
      // if (event.origin !== "https://your-backend.com") {
      //   console.error("Invalid origin:", event.origin);
      //   return;
      // }

        if (event.data && event.data.id_token) {
          // Handle the received data (similar to Angular example)
          const decodedToken = jwtDecode(event.data.id_token);
          const requestData = {
            email: decodedToken.email,
            socialId: decodedToken.sub,
            ...(event.data.user && {
              name: `${event.data.user.name.firstName} ${event.data.user.name.lastName}`,
            }),
          };

        // Optionally send to backend
        axios
          .post(REDIRECT_API_URL, requestData)
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.error("API Error:", error);
          });
      }
    };
    window.addEventListener("message", messageListener);

    if(hashcode !== null){
      setTimeout(() =>{
        navigate("/booking-history", { state: { JDBookingId: splitDecodedHashcode[3] } });
      }, 2000)
    }

  } catch {
    setLoading(false);
  }
  };

  // Function to encode email to Base64
  const encodeEmail = (email) => {
    // Base64 encode the email
    const encodedEmail = btoa(email); // btoa() function encodes a string to base64
    return encodedEmail;
  };

  const token = sessionStorage.getItem("AccessToken");
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  // Function to call the API with the encoded email
  const handleResetPassword = async (email) => {
    // console.log(email);

    try {
      const encodedEmail = encodeEmail(email);
      const url = `${BASE_URL}user/forgetPassword/${encodedEmail}/open`;
      const response = await axios.post(url);

      // console.log("Response from API:", response.data);
      setResetPasswordResponse(response?.data?.data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const handleCloseAmazonSigninModal = () => {
    closeModal("SignInAndSignUpModal");
  };

  const [captchaVerified, setCaptchaVerified] = useState(false);
  // Handle captcha response
  const handleCaptchaChange = (value) => {

    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };


  return (
    <>
      <div className="fixed-top zindexNavbar">
        <nav
          ref={navbarRef}
          className={`${isDarkMode ? "darkMode" : "lightMode border234D"} ${
            currentPage === "home" || currentPage === "showSearchContainer"
              ? ""
              : "searchBorder733"
          } navbar navbar-expand-lg p-0 navbarHeight64px`}
        >
          <div className="container-fluid p-2">
            <div
              className="col-3 px-3 cursorPointer"
              onClick={() => {
                navigate("/");
                //the entire page will reload
                window.location.reload();
                localStorage.removeItem("bookingInputs");
                localStorage.removeItem("daysCount");
                localStorage?.removeItem("PaymentCard");
                localStorage.removeItem("insuranceDoc")
                localStorage?.removeItem("selectedCar")
              }}
            >
              <img src={isDarkMode ? joulezIcon : joulezLightIcon} alt="icon" />
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => dispatch(toggleShowModalBookingDetails(false))}
            >
              {isDarkMode ? (
                <img
                  className="navbar-toggler-icon"
                  src={navBarToggleIcon}
                  alt="navbarToggleIcon"
                />
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </button>
            <div className="collapse navbar-collapse bg23" id="navbarNav">
              {profileDetails && (
                <div>
                  {!profileDetails?.profileImage ? (
                    <div className="d-flex justify-content-center my-2">
                      <img
                        className="d-flex d-lg-none mobileScreenProfileImage"
                        src={
                          profileDetails?.profileImage
                            ? profileDetails?.profileImage // If profileImage exists, use it
                            : userProfileLogo // Otherwise, use the default userProfileLogo
                        }
                        alt="userProfilePic"
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center my-2">
                      <img
                        className="d-flex d-lg-none mobileScreenProfileImage"
                        src={
                          profileDetails?.profileImage
                            ? profileDetails?.profileImage // If profileImage exists, use it
                            : userProfileLogo // Otherwise, use the default userProfileLogo
                        }
                        alt="userProfilePic"
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="d-flex justify-content-center d-lg-none  fontSize17 fontAntartica my-2">
                {profileDetails?.firstName}
              </div>
              <div className="flex-column justify-content-start align-items-start d-flex flex-lg-row justify-content-lg-between align-items-lg-center w100">
                <ul className="navbar-nav col-12 col-lg-8  d-flex justify-content-between align-items-end">
                  {NAVBAR_LIST.map((nav, index) => {
                    return (
                      <li
                        key={index}
                        className="fontSize14 w-100 m-1 m-lg-0 p-1 p-lg-0 fontAntarctica text-lg-center text-nowrap"
                      >
                        <Link
                          className={`text-decoration-none px-3 ${
                            isDarkMode ? "darkMode" : "lightMode"
                          }`}
                          to={nav?.path}
                          onClick={handleNavLinkClick}
                        >
                          {nav.identifer}
                        </Link>
                        <div className="border733 my-1 d-block d-lg-none opacity70"></div>
                      </li>
                    );
                  })}
                  {profileDetails && (
                    <li className="w-100 fontSize14 m-1 m-lg-0 p-1 p-lg-0 fontAntarctica fontSize13 d-block d-lg-none">
                      <Link
                        className={`text-decoration-none px-3 ${
                          isDarkMode ? "darkMode" : "lightMode"
                        }`}
                        to="/user-profile"
                        onClick={handleNavLinkClick}
                      >
                        My Profile
                      </Link>
                      <div className="border733 my-1 d-block d-lg-none opacity70"></div>
                    </li>
                  )}
                  {profileDetails && (
                    <li className="w-100 fontSize14 m-1 m-lg-0 p-1 p-lg-0 fontAntarctica fontSize13 d-block d-lg-none">
                      <Link
                        className={`text-decoration-none px-3 ${
                          isDarkMode ? "darkMode" : "lightMode"
                        }`}
                        to="/booking-history"
                        onClick={handleNavLinkClick}
                      >
                        My Trips
                      </Link>
                      <div className="border733 my-1 d-block d-lg-none opacity70"></div>
                    </li>
                  )}
                  {profileDetails?.accountId && (
                    <li className="w-100 fontSize14 m-1 m-lg-0 p-1 p-lg-0 fontAntarctica fontSize13 d-block d-lg-none">
                      <Link
                        className="text-decoration-none d-flex d-lg-none redColor fontAntarctica fontSize14 py-1 px-3"
                        onClick={handleCustomLogout}
                      >
                        Sign out
                      </Link>
                      <div className="border733 my-1 d-block d-lg-none opacity70"></div>
                    </li>
                  )}
                </ul>
                {/* {profileDetails?.accountId ? (
                  <div
                    onClick={handleCustomLogout}
                    className="d-flex d-lg-none redColor fontAntarctica fontSize13 py-1"
                  >
                    Sign out
                  </div>
                ) : (
                  ""
                )} */}
                <div className="flex-column justify-content-center col-12 col-lg-4 col-xl-3 px-2 d-flex flex-lg-row justify-content-lg-end align-items-lg-center">
                  {/* <div className="mx-lg-3 mb-2 mb-lg-0 text-center borderRadius8 p-1 callUSOn">
                    <a
                      href="tel:+14452568539"
                      className="text-white text-decoration-none fontAntarctica fontSize14"
                    >
                      <span className="d-lg-none">Call Us On</span> +1 (445)
                      2JOULEZ
                    </a>
                  </div> */}
                  {/* light and dark mode */}
                  {/* <div>
                    <img
                      className="d-none d-lg-block cursorPointer"
                      src={isDarkMode ? lightModeIcon : darkModeIcon}
                      alt="lightmode"
                      onClick={handleChangeMode}
                    />
                  </div> */}
                  <div>
                    {!profileDetails ? (
                      ""
                    ) : (
                      <div className="d-flex justify-content-between align-items-center">
                        <img
                          className="d-none d-lg-block profileImage1"
                          src={
                            profileDetails?.profileImage
                              ? profileDetails?.profileImage // If profileImage exists, use it
                              : userProfileLogo // Otherwise, use the default userProfileLogo
                          }
                          alt="userProfilePic"
                        />
                        <div
                          onMouseEnter={() => setToggleProfile(true)} // Show profile on mouse enter
                          className="mx-2"
                        >
                          <img
                            src={navBarToggleIcon}
                            alt="toggleAccountIcon"
                            className="cursorPointer d-none d-lg-block"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {(profileDetails || isProfileLoggedIn) ? "" : (
                      <div className="d-flex">
                      <div
                      className="br8 cursorPointer w-100 m-lg-0 loginButton fontSize13"
                      onClick={() => {
                        openModal("SignInAndSignUpModal")
                        setSignIn(false)
                      }}
                    >
                      Log in
                    </div>
                      <div
                        className="br8 cursorPointer w-100 m-lg-0  joinButton text-nowrap fontSize13"
                        onClick={() => {
                          openModal("SignInAndSignUpModal")
                          setSignIn(true)
                        }}
                      >
                        Join Us
                      </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* if the current page is the carlist the  only the below one is added to navbar. */}
          {currentPage === "car-list" && (
            <div className="bgf8f9fa w-100 d-flex d-lg-none p-1">
              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <div className="d-flex justify-content-between align-items-center w-100 text-dark">
                  <div className="d-flex align-items-center w-75">
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        className="searchIcon m-2"
                        src={searchIcon}
                        alt="searchIcon"
                      />
                    </div>
                    <div
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                      className="m-2 w-100"
                      onClick={() => {
                        setCollapseDropDown(!collapseDropDown);
                        dispatch(toggleShowModalMap(false));
                      }}
                    >
                      {collapseDropDown ? (
                        <div>
                          <div className="row">
                            <p className="mb-0 fontSize13 col-5 col-sm-5 d-block text-truncate">
                              {pickAndDropLocation?.pickup}
                            </p>
                            <p className="mb-0 fontSize13 col-5 col-sm-5 d-block text-truncate">
                              {pickAndDropLocation?.drop}
                            </p>
                          </div>
                          <div className="row">
                            <p className="mb-0 fontSize13 col-12 col-sm-4 text-truncate">
                              {
                                currentDatesAndTimings?.pickupAndDropDates
                                  ?.pickupDate
                              }{" "}
                              {
                                currentDatesAndTimings?.pickupAndDropTimings
                                  ?.pickupTime
                              }{" "}
                              -{" "}
                              {
                                currentDatesAndTimings?.pickupAndDropDates
                                  ?.dropDate
                              }{" "}
                              {
                                currentDatesAndTimings?.pickupAndDropTimings
                                  ?.dropTime
                              }
                            </p>
                          </div>
                        </div>
                      ) : (
                        <p className="mb-0 text-dark">Search Criteria</p>
                      )}
                    </div>
                  </div>
                  {/* <div className="text-white hrLineSearch "></div> */}
                  <div
                    onClick={() => {
                      dispatch(toggleShowModalFilterOptions(true));
                      dispatch(toggleShowModalMap(false));
                    }}
                    className="d-flex justify-content-center align-items-center m-2 p-2"
                  >
                    <img
                      className="height-14px"
                      src={filterIcon}
                      alt="filterIcon"
                    />
                  </div>
                </div>
                <div className="collapse w-100" id="collapseExample">
                  <hr className="my-2" />
                  <PickupLocation
                    locationOptions={locationOptions}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                  />
                  <hr className="my-2" />
                  <TimeSelector
                    locationTimeData={JSON.parse(
                      localStorage.getItem("allLocationsTimeZones")
                    )}
                    dateNewCount={dateNewCount}
                  />
                  <hr className="my-2" />
                  <div className="d-flex justify-content-between w-100">
                    <button
                      className="p-2 br8 bgCE1 text-white text-dark w-100 m-1"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseExample"
                      onClick={() => setCollapseDropDown(!collapseDropDown)}
                    >
                      Close
                    </button>
                    {/* <button className="btn w-100 m-1 bgCE1 text-white">
                      Search
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* if the current page is the booking then only the below one is added to navbar. */}
          {currentPage === "booking" && (
            <div className="bgf8f9fa w-100 d-flex justify-content-between d-lg-none p-1">
              {/* navbarHeaderUI prop is used to change the ui of the navbar in the booking screen  */}
              <TimeSelector
                locationTimeData={JSON.parse(
                  localStorage.getItem("allLocationsTimeZones")
                )}
                navbarHeaderUI={true}
              />
            </div>
          )}
        </nav>
        <div>
          {toggleProfile && (
            <div
              ref={profileRef}
              onMouseEnter={() => setToggleProfile(true)} // Show profile on mouse enter
              onMouseLeave={() => {
                setToggleProfile(false);
              }}
              className="myProfile border4D fontSize13 fontAntartica borderRadius8 cursorPointer"
            >
              {/* <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="p-1" onClick={() => navigate("/user-profile")}>
                  <div className="d-flex">
                    <div className="mx-1">
                      <img src={profilePersonLogo} alt="profilePersonLogo" />
                    </div>
                    <div className="mx-1">My Profile1</div>
                  </div>
                </div>
                <hr className="m-1 w-100" />
                <div
                  className="p-1"
                  onClick={() => navigate("/booking-history")}
                >
                   <img src={MyTripLogo} alt="mytriplogo" />
                  My Trips{" "}
                </div>
                <hr className="m-1 w-100" />
                <div
                  className="p-1"
                  onClick={() => {
                    handleCustomLogout();
                    setToggleProfile(false);
                  }}
                >
                   <img src={signoutLogo} alt="signoutLogo" />
                  Sign out
                </div>
              </div> */}
              <div className="d-flex flex-column justify-content-center align-items-center">
                {/* Profile Section */}
                <div
                  className="d-flex align-items-center w-100 p-2"
                  onClick={() => navigate("/user-profile")}
                >
                  <img
                    src={profilePersonLogo}
                    alt="profilePersonLogo"
                    className="me-2 rounded-circle"
                  />
                  <span className="fontWeight400 text-end">My Profile</span>
                </div>
                <hr className="w-100 my-1" />

                {/* My Trips Section */}
                <div
                  className="d-flex align-items-center w-100 p-2"
                  onClick={() => navigate("/booking-history")}
                >
                  <img src={MyTripLogo} alt="mytriplogo" className="me-2" />
                  <span className="fontWeight400 text-end">My Trips</span>
                </div>
                <hr className="w-100 my-1" />

                {/* Sign Out Section */}
                <div
                  className="d-flex align-items-center w-100 p-2"
                  onClick={() => {
                    handleCustomLogout();
                    setToggleProfile(false);
                  }}
                >
                  <img src={signoutLogo} alt="signoutLogo" className="me-2 " />
                  <span className="fontWeight400 redColor">Sign Out</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/*if there is profile details its null or else we will open the SignInAndSignUpModal desktopmodal */}
      {profileDetails?.accountId ? (
        ""
      ) : (
        <DesktopCustomModal
          isModalOpen={activeModal === "SignInAndSignUpModal"}
          closeModal={() => {
            closeModal()
            setSignupErrors({
              email: "",
              password: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              dob: "",
            })

            setSignUp({
              email: "",
              password: "",
              firstName: "",
              middleName: "",
              lastName: "",
              countryCode: "",
              phoneNumber: "",
              phoneNumberCountryCode: "",
              dob: "",
              loginType: "CUSTOM",
            });

            setSignUpcheck(false)
            setCaptchaVerified(false);
            setSignInData({
              email: "",
              password: "",
              errors: "",
            })
           }
          }
          modalCenter={true}
          modalId={signIn ? "signinModal" : "loginModal"}
          modalClassHeight={signIn ? "modalHeightSignUp" : "modalLogin"}
          modalTitle={
            signIn ? (
              <div className="text-center py-3">
                <div className="fontSize30 fontcolorFFF fontAntarctica fontWeight600">
                  Create Account
                </div>
              </div>
            ) : (
              <div className="text-center py-3 fontAntarctica fontcolorFFF">
                <div className="fontSize30 fontWeight600">
                  Welcome back
                </div>
                <div className="fontSize17 fontWeight300">
                  Log in with
                </div>
              </div>
            )
          }
          modalButton=""
          closeOnOutsideClick={
            true
            // signIn ? false : signInData?.errors === "" ? true : false
          }
          modalContent={
            signIn ? (
              <div className="text-center text-white w-100 py-2">
                <div className="fontSize13 fontAntarctica">
                  <span>Already have an account?</span>
                  <span
                    onClick={() => {
                      setSignIn(false)

                      setSignupErrors({
                        email: "",
                        password: "",
                        firstName: "",
                        lastName: "",
                        phoneNumber: "",
                        dob: "",
                      })

                      setSignUp({
                        email: "",
                        password: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        countryCode: "",
                        phoneNumber: "",
                        phoneNumberCountryCode: "",
                        dob: "",
                        loginType: "CUSTOM",
                      });
                      
                    }}
                    className="mx-2 cursorPointer fontColorCE1 fontWeight600"
                  >
                    Log In
                  </span>
                </div>
              </div>
            ) : (
              <div className="text-center text-white w-100 py-2">
                <div className="fontSize13 fontAntarctica">
                  <span>Don't have an account yet?</span>
                  <span
                    onClick={() => {
                      setSignIn(true)

                      setSignInData({
                        email: "",
                        password: "",
                        errors: "",
                      })
                    }}
                    className="mx-2 cursorPointer fontColorCE1 fontWeight600"
                  >
                    Sign Up
                  </span>
                </div>
              </div>
            )
          }
        >
          <div className="input-group w-100 d-flex justify-content-center align-items-center flex-wrap">
            <div className="d-flex justify-content-around iconsWidthContainer">
              <div
                onClick={handleGoogleLogin}
                className="p-2 border3B3936 borderRadius8 cursor social-icon"
              >
                <img src={googleIcon} alt="google_icon" />
              </div>
              <div
                onClick={handleAppleSignIn}
                className="p-2 border3B3936 borderRadius8 cursor social-icon"
              >
                <img src={appleIcon} alt="apple_icon" />
              </div>
              {/* <SocialLogins
                handleCloseAmazonSigninModal={handleCloseAmazonSigninModal}
              /> */}
            </div>
            <div className="my-3 d-flex justify-content-center align-items-center">
              <div className="border mx-3"></div>
              <div className="fontcolorFFF fontWeight300 fontSize17 text-nowrap">or using email</div>
              <div className="border mx-3"></div>
            </div>
            {signIn ? (
              <div className="w-100 paddingLeft2Px">
                <form onSubmit={handleSubmit}>
                  {/* see here we are using the support page css (supportFormFocus) */}
                  <div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="namewidth">
                      <input
                        placeholder="First Name"
                        type="text"
                        className="form-control supportFormFocus p-2"
                        name="firstName"
                        value={signUp?.firstName}
                        onChange={handleSignUp}
                        onBlur={handleBlur}
                        maxLength={50}
                      />
                    </div>
                    <div className="namewidth">
                      <input
                        placeholder="Middle Name (Optional)"
                        type="text"
                        className="form-control supportFormFocus p-2"
                        name="middleName"
                        value={signUp?.middleName}
                        onChange={handleSignUp}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  {/**only firtName error shown in big screen */}
                  {signupErrors.firstName && (
                        <span className="fontColor4031 my-2">
                          {signupErrors.firstName}
                        </span>
                      )}
                  </div>
                  <input
                    placeholder="Last Name"
                    type="text"
                    className="form-control supportFormFocus mt-3 p-2"
                    name="lastName"
                    value={signUp?.lastName}
                    onChange={handleSignUp}
                    onBlur={handleBlur}
                    maxLength={50}
                  />
                  {signupErrors.lastName && (
                    <span className="fontColor4031 my-2">
                      {signupErrors.lastName}
                    </span>
                  )}
                  <div className="mt-3 borderRadius8 p-1">
                    <PhoneInput country={"us"} onChange={handlePhoneChange} placeholder="" />
                  </div>
                  {signupErrors.phoneNumber && (
                    <span className="fontColor4031 my-2">
                      {signupErrors.phoneNumber}
                    </span>
                  )}
                  {/* <input
                    placeholder="Date Of Birth"
                    type="text"
                    className="form-control supportFormFocus mb-3 p-2"
                    name="dob"
                    value={signUp?.dob}
                    onChange={() => {}}
                    onClick={() =>
                      setShowCalendarForSignUp(!showCalendarForSignUp)
                    }
                    onBlur={handleBlur}
                  /> */}
                  {/* {showCalendarForSignUp && (
                    <div className="d-flex justify-content-center">
                      <CommonCalendar onDateSelect={handleDateSelect} />
                    </div>
                  )} */}

                  {/* <div className="mt-3">
                    <CommonCalendar
                      maxDate={
                        new Date(
                          2006,
                          new Date().getMonth(),
                          Math.min(new Date().getDate(), 31)
                        )
                      }
                      handleBlur={handleBlur}
                      value={signUp?.dob}
                      placeholder="Date Of Birth"
                      onDateSelect={handleDateSelect}
                    />
                  </div> */}

                  <div className="mt-3">
                    <DateOfBirthPicker
                      maxDate={
                        new Date(
                          2006,
                          new Date().getMonth(),
                          Math.min(new Date().getDate(), 31)
                        )
                      }
                      handleBlur={handleBlur}
                      value={signUp?.dob}
                      placeholder="Date of Birth mm/dd/yyyy"
                      onDateSelect={handleDateSelect}
                      name="dob"
                    />
                  </div>

                  {(signupErrors.dob || !signUp?.dob) && (
                    <span className="fontColor4031 my-2">
                      {signupErrors.dob}
                    </span>
                  )}
                  <input
                    placeholder="Email Address"
                    type="text"
                    name="email"
                    className="form-control supportFormFocus p-2 mt-3"
                    value={signUp?.email}
                    onChange={handleSignUp}
                    onBlur={handleBlur}
                  />
                  {signupErrors.email && (
                    <span className="fontColor4031 my-2">
                      {signupErrors.email}
                    </span>
                  )}
                  {/* <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    className="form-control supportFormFocus  p-2 mt-3"
                    value={signUp?.password}
                    onChange={handleSignUp}
                    onBlur={handleBlur}
                  /> */}
                  <div className="mb-3 position-relative">
                    <input
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control supportFormFocus  p-2 mt-3"
                      value={signUp?.password}
                      onChange={handleSignUp}
                      onBlur={handleBlur}
                      autoComplete="new-password"
                    />
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <img
                          src={showPasswordIcon}
                          className="passwordIcon cursor"
                          alt="hidePasswordIcon"
                        />
                      ) : (
                        <img
                          src={hidePasswordIcon}
                          className="passwordIcon cursor"
                          alt="hidePasswordIcon"
                        />
                      )}
                    </div>
                    <div className="mt-1 signInError fontAntarctica fontSize13">
                      {signInData?.errors?.password &&
                        signInData?.errors?.password}
                    </div>
                  </div>
                  {signupErrors.password && (
                    <p className="fontColor4031 mt-0">
                      {signupErrors.password}
                    </p>
                  )}

                  <div className="d-flex justify-content-between align-items-start cursor">
                    <input
                      id="signUpCheckbox"
                      type="checkbox"
                      className="signupCheckbox mt-1"
                      onChange={() => setSignUpcheck(!signupCheck)}
                      checked={signupCheck ? true : false}
                    />
                    <label
                      htmlFor="signUpCheckbox"
                      className="mx-3 text-white cursor fontSize13 fontWeight300 fontAntarctica"
                    >
                      {/* Check here to indicate that you agree to the terms and
                      conditions and privacy policy of joulez */}
                      {/* <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                        style={{
                          cursor: "pointer",
                          marginRight: "3px",
                          top: "5px",
                          fontSize: "14px",
                        }}
                      > */}
                      I agree to Joulez{" "}
                      <Link
                        to="/terms-and-conditions"
                        className="fontColorCE1 fontWeight400"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                      ,{" "}
                      <Link
                        to="/gdpr-privacy-policy"
                        className="fontColorCE1 fontWeight400"
                        target="_blank"
                      >
                        Cookie Settings
                      </Link>
                      ,{" "}
                      <Link
                        to="/rental-agreement"
                        className="fontColorCE1 fontWeight400"
                        target="_blank"
                      >
                        Rental Agreement
                      </Link>
                      ,{" "}
                      <Link
                        to="/privacy-policy"
                        className="fontColorCE1 fontWeight400"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>{" "}
                      which includes receiving email, SMS, and mobile text
                      communications from Joulez.
                      {/* </label> */}
                    </label>
                  </div>
                  <div className="py-2">
                    <Recaptcha onCaptchaChange={handleCaptchaChange} />
                  </div>
                  {SignupErrorsFromBackend && (
                    <div className="fontColor4031 my-2 fontAntarctica fontSize13">
                      {SignupErrorsFromBackend}
                    </div>
                  )}
                  <button
                    type="submit"
                    className={`signInButton bgCE1 text-white w-100 mt-2 ${
                      !captchaVerified ||
                      signupErrors?.email ||
                      signupErrors?.password ||
                      signupErrors?.firstName ||
                      signupErrors?.lastName ||
                      signupErrors?.dob ||
                      signupErrors?.phoneNumber ||
                      !signupCheck ||
                       signUp?.dob === "" ||
                      !signUp?.email ||
                      !signUp?.password ||
                      !signUp?.firstName ||
                      !signUp?.lastName ||
                      !signUp?.phoneNumber
                        ? "disabled-class"
                        : ""
                    }`}
                  >
                    {signUpLoader ? <SpinnerLoader /> : "Sign Up"}
                  </button>
                </form>
              </div>
            ) : (
              <div className="w-100 paddingLeft2Px">
                {/* see here we are using the support page css (supportFormFocus) */}
                <form>
                  <div className="mb-3">
                    <input
                      id="email"
                      placeholder="Email Address"
                      type="email"
                      className="form-control supportFormFocus p-2"
                      value={signInData?.email}
                      onChange={handleChangeForSignIn}
                    />
                    <div className="mt-1 signInError fontAntarctica fontSize13">
                      {signInData?.errors?.email && signInData?.errors?.email}
                    </div>
                  </div>
                  <div className="mb-3 position-relative">
                    <input
                      id="password"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      className="form-control supportFormFocus p-2"
                      value={signInData?.password}
                      onChange={handleChangeForSignIn}
                      autoComplete="new-password"
                    />
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <img
                          src={showPasswordIcon}
                          className="passwordIcon cursor"
                          alt="hidePasswordIcon"
                        />
                      ) : (
                        <img
                          src={hidePasswordIcon}
                          className="passwordIcon cursor"
                          alt="hidePasswordIcon"
                        />
                      )}
                    </div>
                    <div className="mt-1 signInError fontAntarctica fontSize13">
                      {signInData?.errors?.password &&
                        signInData?.errors?.password}
                    </div>
                  </div>
                  <div className="mt-1 signInError fontAntarctica fontSize13">
                    {error && error}
                  </div>
                  <div className="d-flex justify-content-end text-white mb-2 cursorPointer">
                    <div
                      onClick={() => openModal("ForgotPassWord")}
                      className="fontSize13 fontAntarctica"
                    >
                      Forgot Password?
                    </div>
                  </div>
                  <button
                    type="button"
                    className="signInButton bgCE1 text-white w-100"
                    onClick={() => {
                      handleSubmitSignInData();
                    }}
                  >
                    Log in
                  </button>
                </form>
              </div>
            )}
          </div>
        </DesktopCustomModal>
      )}
      {loading && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      {/*Fortgot Password Modal */}
      <DesktopCustomModal
        isModalOpen={activeModal === "ForgotPassWord"}
        closeModal={() => closeModal("ForgotPassWord")}
        closeOnOutsideClick={true}
        modalCenter={true}
        isCloseIconRequired={true}
        modalId="ForgotPassWord"
        modalButton=""
        modalTitle={
          <div className="p-3 text-center text-white fontSize17">
            Reset Password ?
          </div>
        }
      >
        <div className="w-100">
          {resetPasswordResponse === "" ? (
            <div>
              <div className="mb-3">
                <input
                  placeholder="Email address"
                  type="text"
                  value={resetEmailAddress}
                  onChange={(e) => setResetEmailAddress(e.target.value)}
                  className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                />
              </div>
              <div>
                <button
                  onClick={() => handleResetPassword(resetEmailAddress)}
                  className={`${
                    !resetEmailAddress?.match(
                      "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}"
                    ) && "disabled-class"
                  } w-100 p-0 borderRadius8 outlineNone p-2 bgCE1 border-0 text-white`}
                >
                  Reset
                </button>
              </div>
              <div className="text-white text-end mx-2 mt-1 fontSize13 fontAntarctica pt-2 ">
                <span
                  onClick={() => openModal("SignInAndSignUpModal")}
                  className="cursorPointer text-white fontSize14  text-decoration-none"
                >
                  Try Sign In
                </span>
              </div>
            </div>
          ) : (
            <div className="text-white fontAntarctica fontSize14">
              <div className="px-3 py-1 opacity-75">
                {resetPasswordResponse}
              </div>
            </div>
          )}
        </div>
      </DesktopCustomModal>
    </>
  );
};

export default Navbar;
