export const content1 = {
    title1: "EV Rentals",
    title2: "World-Class Experience",
    locations: ["New York", "Los Angeles"]
  };
  
  export const content2 = {
    title: "Discover EV",
    description: "Embrace the future where every drive is a journey of modern transportation, sustainability, and a seamless experience. With Joulez, you're not just renting an EV, you're part of a movement."
  };
  
  export const content3 = {
    title: "How Joulez Works",
    steps: [
      {
        title: "Choose Your Electric Vehicle",
        description: "Choose an Electric Vehicle and pick it up at one of our convenient garages or have it delivered to your location in our service area."
      },
      {
        title: "First-Time Driver Support",
        description: "Get a hands-on tutorial of the Electric Vehicle if this is your first time behind the wheel of a specific model of vehicle."
      },
      {
        title: "Drive Anywhere with Ease",
        description: "Take off and drive anywhere in the USA or Canada with multiple charging options included."
      },
      {
        title: "Convenient Return Options",
        description: "Let us know when you are ready to return the vehicle and we will pick it up in the same service area we dropped it off."
      }
    ]
  };

  export const content4 = {
    title: "What our founder has to say",
    quote: "I founded Joulez for one reason. The Electric Vehicle revolution is coming, and the car rental industry is not ready. Whether it be historically poor customer service or a customer experience that is not adaptable to the real world needs of driving an EV, Joulez is ready to change the rental car game. We are excited to have you join us on that journey.",
    founderName: "Micah Bergdale",
    founderTitle: "CEO & Founder",
  };

  // export const content5 = {
  //   title: "Discover new horizons with our wide range of rental cars",
  //   description1: "Unlock freedom with our premium car rental service. Experience comfort on wheels with our reliable car rentals. Discover new horizons with our wide range of rental cars. Your journey starts here. Rent the perfect car today!",
  //   description2: "Our EV car rental app is designed to make your life easier. With our user-friendly interface, you can easily book a car and get it for rental. We offer a wide range of rental cars that are perfect for any occasion. Whether you need a car for a business trip or a family vacation, we have the perfect car for you.",
  // };

  export const content5 = {
    title: "Enhance your rental experience with access to the EV mobile app",
    title1 :"Full Vehicle Access",
    description1:"Renters can unlock, control climate, and monitor charging via their smartphone, eliminating physical keys.",
    title2 :"Personalized Experience",
    description2:"Users can adjust seat, climate, and navigation settings for a customized drive.",
    title3 :"Real-Time Monitoring",
    description3:" Check battery levels and charging status anytime for better trip planning.",
     title4 :"Navigation",
    description4:"Get real-time navigation and traffic updates, plus easy access to Tesla’s Supercharger network.",
    title5:"Safety and Support",
    description5:"GPS tracking helps locate the car, with Tesla support just a tap away.",
     title6:"Streamlined Handover",
    description6:"Renters can connect their profile to the vehicle for a quick, contactless pick-up and return."
  };

  