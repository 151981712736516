import React, { useCallback, useEffect, useRef, useState } from "react";
import ShimmerUI from "../Components/ShimmerUI";
import SearchContainer from "../Components/SearchComponent/SearchContainer";
import axios from "axios";
import Card from "../Components/Card";
import { useDispatch, useSelector } from "react-redux";
import filterIcon from "../assets/svg/filterIcon.svg";
import filterLightIcon from "../assets/svg/filterLightIcon.svg";
import mapIcon from "../assets/svg/mapIcon.svg";
import membersIcon from "../assets/svg/membersIcon.svg";
import milesIcon from "../assets/svg/milesIcon.svg";
import notchIcon from "../assets/svg/notchIcon.svg";
import locationIcon from "../assets/svg/locationIcon.svg";
import wishlistIcon from "../assets/svg/whislistIcon.svg";
import Button from "../Components/Button";
import GoogleMaps from "../Components/GoogleMaps";
import { setCurrentPage } from "../Store/pageSlice";
import ModalContainer from "../Components/ModalContainer";
import {
  toggleShowModalFilterOptions,
  toggleShowModalMap,
  toggleShowModalPickupLocation,
} from "../Store/modalStatusSlice";
import FilterOptions from "../Components/FilterOptions";
import { parse, format } from "date-fns";
import { BASE_URL } from "../api/index";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import useScrollY from "../Hooks/useScrollY";
import MetaTags from "../Components/MetaTag";

const CarList = () => {
  const dispatch = useDispatch();
  const modalFilterOption = useSelector(
    (store) => store?.modalStatus?.showModalFilterOptions
  ); //this will get a boolean value of filterOption
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const modalMap = useSelector((store) => store?.modalStatus?.showModalMap); //this will get a boolean value of mapOption
  const currentDates = useSelector((store) => store?.currentDatesAndTimings);
  const currentLocationID = useSelector(
    (store) => store?.currentLocations?.pickupIdAndDropId?.pickupId
  );
  const currentLocations = useSelector((store) => store?.currentLocations);
  const [response, setResponse] = useState(null);
  const [filterResponse, setFilterResponse] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const [showMap, setShowMap] = useState(false); //this is for desktop purpose only
  const [pathNameFromSiteMap, setpathNameFromSiteMap] = useState("");
  const scrollY = useScrollY();
  const convertedPickupDate = format(
    parse(
      currentDates?.pickupAndDropDates?.pickupDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  );
  const convertedDropDate = format(
    parse(
      currentDates?.pickupAndDropDates?.dropDate,
      "MMMM d, yyyy",
      new Date()
    ),
    "yyyy-MM-dd"
  );

  const convertTo24HourFormat = (time12hr) => {
    const parsedTime = parse(time12hr, "hh:mm a", new Date());
    if (isNaN(parsedTime)) {
      return "";
    }

    return format(parsedTime, "HH:mm:ss");
  };

  const formatPickupTIme = convertTo24HourFormat(
    currentDates?.pickupAndDropTimings?.pickupTime
  );
  const formatDropTIme = convertTo24HourFormat(
    currentDates?.pickupAndDropTimings?.dropTime
  );

  const currentUserDetails = JSON.parse(
    sessionStorage.getItem("CurrentUserDetails")
  );

  const filterSectionState = {
    ageGroup: currentUserDetails?.ageGroup ? currentUserDetails?.ageGroup : 3,
    autoPilotIds: [],
    brand: [],
    pickupDate: convertedPickupDate + " " + formatPickupTIme,
    dropoffDate: convertedDropDate + " " + formatDropTIme,
    locationId: currentLocationID,
    vehicleModel: [],
    vehicleType: [],
    seats: [],
    colors: [],
    ranges: [],
    minPrice: "",
    maxPrice: "",
  };

  
  const [carDataAvailable, setCarDataAvailable] = useState(false)
  //this is used to subfilter the cars based on the type or brand or seats or anything
  const [subFilterData, setSubFilterData] = useState({
    autoPilotIds: [],
    brand: [],
    vehicleModel: [],
    vehicleType: [],
    seats: [],
    colors: [],
    ranges: [],
    minPrice: "",
    maxPrice: "",
  });

  const locationData = JSON.parse(localStorage.getItem("allLocationsData"));
  const location = useLocation();
  const pathLength = location?.pathname?.split("/")?.length - 1;
  const locationNameFromSiteMap = location.pathname.split("/")[pathLength];
  const [locationID, setLocationID] = useState(null);
  const [locationSitMapName, setLocationSitMapName] = useState(null);
  const [filterData, setFilterData] = useState({ ...filterSectionState });
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  // Update filterData when pickupDate or dropoffDate changes or currentLocationID changes
  useEffect(() => {
    // Ensure all date and time values are correctly formatted before updating state
    if (
      convertedPickupDate &&
      formatPickupTIme &&
      convertedDropDate &&
      formatDropTIme
    ) {
      handlePostData({
        ageGroup: currentUserDetails?.ageGroup
          ? currentUserDetails?.ageGroup
          : 3,
        autoPilotIds: [],
        brand: [],
        pickupDate: convertedPickupDate + " " + formatPickupTIme,
        dropoffDate: convertedDropDate + " " + formatDropTIme,
        locationId: currentLocationID,
        vehicleModel: [],
        vehicleType: [],
        seats: [],
        colors: [],
        ranges: [],
        minPrice: "",
        maxPrice: "",
      });

      setSubFilterData({
        autoPilotIds: [],
        brand: [],
        vehicleModel: [],
        vehicleType: [],
        seats: [],
        colors: [],
        ranges: [],
        minPrice: "",
        maxPrice: "",
      });

      // setFilterData((prev) => ({
      //   ...prev,
      //   locationId:currentLocationID,
      //   pickupDate: `${convertedPickupDate} ${formatPickupTIme}`,
      //   dropoffDate: `${convertedDropDate} ${formatDropTIme}`,
      // }));
    }
  }, [
    convertedPickupDate,
    convertedDropDate,
    formatPickupTIme,
    formatDropTIme,
    currentLocationID,
  ]);

  // useEffect(() => {
  //   // Ensure filterData is updated correctly
  //   const updatedFilterData = {
  //     ...filterData,
  //     locationId: currentLocationID,
  //     pickupDate: convertedPickupDate + " " + formatPickupTIme,
  //     dropoffDate: convertedDropDate + " " + formatDropTIme,
  //     //brand:location.pathname.split("/")?.includes('make') ? [location.pathname.split("/")[pathLength]] : []
  //   };

  //   if (location.pathname.split("/")?.includes("make")) {
  //     setFilterData((prev) => ({
  //       ...prev,
  //       locationId: currentLocationID,
  //       brand: [
  //         ...new Set([
  //           ...prev?.brand,
  //           location.pathname.split("/")[pathLength],
  //         ]),
  //       ],
  //     }));
  //     setSubFilterData((prev) => ({
  //       ...prev,
  //       brand: [
  //         ...new Set([
  //           ...prev?.brand,
  //           location.pathname.split("/")[pathLength],
  //         ]),
  //       ],
  //     }));
  //   }

  //   // Check if all required values are available to make the API call
  //   if (
  //     currentLocationID &&
  //     currentDates?.pickupAndDropDates?.pickupDate &&
  //     currentDates?.pickupAndDropDates?.dropDate && location.pathname.split("/")?.includes("make")
  //   ) {
  //     handlePostData(updatedFilterData); // Trigger the API call with the updated filter data
  //   }

  //   // eslint-disable-next-line
  // }, [currentLocationID, currentDates, pathNameFromSiteMap]); // Runs when currentLocationID or currentDates change

  useEffect(() => {
    if (
      location.pathname.split("/")?.includes("make") &&
      window?.innerWidth < 992 &&
      currentLocations?.pickup === "" &&
      currentLocations?.drop === ""
    ) {
      setTimeout(() => {
        dispatch(toggleShowModalPickupLocation(true));
      }, 1000);
    }
    //eslint-disable-next-line
  }, [location?.pathname, dispatch]);

  useEffect(() => {
   if(filterResponse?.data?.cars?.length > 0 && location.pathname.split("/")?.includes("make") && window?.innerWidth < 992){
    dispatch(toggleShowModalFilterOptions(true))
   }
  }, [location?.pathname, filterResponse])

  const handlePostData = useCallback(async (payload) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}car/availableCarsV4/open`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponse(res?.data);
      setFilterResponse(res?.data);
      const carFilterData = res?.data?.data?.carFilters
      //console.log(carFilterData?.autoPilotList?.filter((car) => car.available === true).map((type) => type?.type));
      
      // setSubFilterData((prev) => ({
      //   ...prev,
      //   vehicleType: [...carFilterData?.types?.filter((car) => car.available === true).map((type) => type?.name)],
      //   brand:[...carFilterData?.brands?.filter((car) => car.available === true).map((type) => type?.name)],
      //   vehicleModel:[...carFilterData?.models?.filter((car) => car.available === true).map((type) => type?.name)],
      //   seats:[...carFilterData?.seats?.filter((car) => car.available === true).map((type) => type?.name)],
      //   ranges: [...carFilterData?.ranges?.filter((car) => car.available === true).map((type) => type?.id)],
      //   autoPilotIds: [...carFilterData?.autoPilotList?.filter((car) => car.available === true).map((type) => type?.id)],
      //   minPrice: carFilterData?.minPrice,
      //   maxPrice: carFilterData?.maxPrice,
      // }))
      setLoading(false); // Set loading to false after data is fetched
      setError("");
    } catch (error) {
      setResponse("");
      setFilterResponse("");
      setError(error?.response?.data?.message); // Set error message
      setLoading(false); // Set loading to false in case of an error
    }
  }, []);

  useEffect(() => {
    if (response !== "") {
      callGoogletag();
    }
    // eslint-disable-next-line
  }, [response]);

  //on small screen it send the name as car-list so we can remove the footer
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 992) {
        dispatch(setCurrentPage("car-list"));
      } else {
        dispatch(setCurrentPage("showSearchContainer"));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    //if you want to render the filteroption first just switch these two
    if (modalMap) {
      setShowMap(false);
    } else {
      setShowMap(true);
    }
    if (modalFilterOption) {
      setShowMap(false);
    } else {
      setShowMap(true);
    }
  }, [modalFilterOption, modalMap]);

  useEffect(() => {
    localStorage.removeItem("isRentalCoverageModal");
    localStorage.removeItem("PaymentCard");
    if (window?.innerWidth > 992) {
      setShowMap(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    //localStorage.removeItem("estimatedPriceDetails")
    localStorage.removeItem("localIsId1Disabled");
    localStorage.removeItem("bookingInputs");
  }, []);

  const callGoogletag = () => {
    window.gtag("event", "view_listing", {
      send_to: "G-DE7JLFVL37",
      cars_available: response?.data?.cars?.length,
    });
  };

  useEffect(() => {
    if (currentLocations?.pickup === "" && currentLocations?.drop === "") {
      toast.info("Please select the location");
    }
    const location = locationData.filter(
      (item) =>
        item?.id === Number(currentLocations?.pickupIdAndDropId?.pickupId)
    );
    setLatitude(location[0]?.latitude);
    setLongitude(location[0]?.longitude);
    // eslint-disable-next-line
  }, [
    currentLocations?.pickup,
    currentLocations?.drop,
    currentLocations?.pickupIdAndDropId?.pickupId,
  ]);

  // const handleOnSelect = (pathNameFromSiteMap) => {
  //   setFilterData((prev) => ({
  //     ...prev,
  //     brand: [...new Set([...prev?.brand, pathNameFromSiteMap])], // Handle empty brand array
  //   }));
  //   setpathNameFromSiteMap(pathNameFromSiteMap);
  // };

  useEffect(() => {
    if (location) {
      // Find the location ID based on the last part of the URL (location)
      const matchingLocation =
        locationData.filter(
          (item) =>
            item?.locationName?.toLowerCase() ===
            decodeURIComponent(locationNameFromSiteMap?.toLowerCase())
        ) ||
        locationData.filter(
          (item) =>
            item?.city?.toLowerCase() ===
            decodeURIComponent(locationNameFromSiteMap?.toLowerCase())
        );
      if (matchingLocation?.length > 0) {
        // Set the locationID if a match is found
        setLocationID(matchingLocation[0]?.id);
        setLocationSitMapName(matchingLocation[0]?.displayAddress);
      }
    }
  }, [locationNameFromSiteMap, locationData, location]);

  useEffect(() => {
    handlePostData({ ...filterSectionState, ageGroup: filterData?.ageGroup });
  }, [filterData?.ageGroup]);

//   const timeoutRef = useRef(null);

// useEffect(() => {
//   if (timeoutRef.current) {
//     clearTimeout(timeoutRef.current); // Clear previous timeout
//   }

//   timeoutRef.current = setTimeout(() => {
//     handlePostData({
//       ...filterSectionState,
//       minPrice: filterData?.minPrice,
//       maxPrice: filterData?.maxPrice,
//     });
//   }, 500); // Debounce time (adjust as needed)

//   return () => clearTimeout(timeoutRef.current); // Cleanup on unmount
// }, [filterData?.minPrice, filterData?.maxPrice]);

  return (
    <>
      <MetaTags
        title="Joulez - Electric Vehicle Car Rental Company Based in New York City and Los Angeles."
        description="Joulez is an Electric Vehicle company based out of New York City and Los Angeles. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen and Hyundai cars."
      />
      <div
        className={`d-flex flex-column justify-content-between h-100  ${
          isDarkMode ? "darkMode" : "bgFEFDFD"
        }`}
      >
        <div className="py-5 min-vh-100 ">
          <div className="d-none d-lg-flex justify-content-start">
            <div
              className={`d-flex align-items-center position-fixed top-0 ${
                scrollY > 500 ? "pt-0" : "pt-5"
              } zindexCarList`}
            >
              <SearchContainer
                showSearch={false}
                siteMapLocationID={locationID}
                locationSitMapName={locationSitMapName}
              />
              <div className="text-white hrLineSearch mt-3 d-none d-sm-none  d-md-none d-lg-block"></div>
              <div className="filterContainer d-none d-lg-flex">
                <div
                  className={`${
                    isDarkMode ? "bgf8f9fa text-white" : "lightMode"
                  } filterBox ${
                    scrollY > 500 ? "mt-0" : "mt-3"
                  } d-flex justify-content-center align-items-center`}
                >
                  <div className="bgf8f9fa  borderRadius8 p-2 d-flex justify-content-between align-items-center ">
                    <div
                      className={showMap ? "" : "bgCE1 p-1 rounded rounded-2"}
                      onClick={() => setShowMap(false)}
                    >
                      <img
                        className="mx-1"
                        src={`${isDarkMode ? filterIcon : filterLightIcon}`}
                        alt="filterIcon"
                      />
                    </div>
                    <div
                      className={`${
                        showMap ? "bgCE1 p-1 rounded rounded-2" : ""
                      } ${
                        response?.data?.cars && response?.data?.cars.length > 0
                          ? ""
                          : "disabled-class"
                      }`}
                      onClick={() => setShowMap(true)}
                    >
                      <img
                        className="padding-12px mx-1"
                        src={mapIcon}
                        alt="filterMapIcon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="filterContainer d-none d-lg-flex">
            <div
              className={`${
                isDarkMode ? "bg3B3936 text-white" : "lightMode"
              } filterBox mx-3 mt-5 d-flex justify-content-around align-items-center`}
            >
              <div
                className={showMap ? "" : "bgCE1 rounded rounded-2"}
                onClick={() => setShowMap(false)}
              >
                <img
                  className="padding-12px"
                  src={`${isDarkMode ? filterIcon : filterLightIcon}`}
                  alt="filterIcon"
                />
              </div>
              <div className="text-white hrLineSearch d-none d-sm-none  d-md-none d-lg-block"></div>
              <div
                className={`${showMap ? "bgCE1 rounded rounded-2" : ""} ${
                  response?.data?.cars && response?.data?.cars.length > 0
                    ? ""
                    : "disabled-class"
                }`}
                onClick={() => setShowMap(true)}
              >
                <img
                  className="padding-12px"
                  src={mapIcon}
                  alt="filterMapIcon"
                />
              </div>
            </div>
          </div> */}
          </div>
          {loading ? (
            <div>
              <ShimmerUI />
            </div>
          ) : (
            <div className="p-3 d-flex justify-content-center paddingTopCarList">
              <div>
                <div className="d-flex justify-content-center justify-content-sm-center justify-content-md-start flex-wrap col-md-8 carsCardContainer customizedScrollBar fontSize13 overflow-x-hidden">
                  {error && (
                    <p className="fontSize13 fontAntarctica">{error}</p>
                  )}
                  {response?.data?.cars?.length > 0 ? (
                    response?.data?.cars?.map((car) => {
                      return (
                        <Card
                          key={car?.id}
                          membersIcon={membersIcon}
                          milesIcon={milesIcon}
                          locationIcon={locationIcon}
                          wishlistIcon={wishlistIcon}
                          detailsOfTheCar={car}
                        />
                      );
                    })
                  ) : (
                    <div className="pt-2 fontsize14 fontweight400 text-white">
                      {response?.message}
                    </div>
                  )}
                  {/* <div
                  onClick={() => dispatch(toggleShowModalMap(true))}
                  className={`viewContainer w-100 ${response?.data?.cars && response?.data?.cars.length > 0 ? "" : "disabled-class"}`}
                >
                  <div className="text-white bg191916 text-center w-100 borderTopRadius">
                    <div>
                      <img
                        className="notchImage"
                        src={notchIcon}
                        alt="Notch Icon"
                      />
                    </div>
                    <div>
                      <img
                        className="padding-12px"
                        src={mapIcon}
                        alt="filterMapIcon"
                      />
                      View Car Location
                    </div>
                  </div>
                </div> */}
                </div>
                {response?.data?.cars?.length > 0 && (
                  <div className="fontSize13 fontAntarctica">
                    * Only Rental Cost
                  </div>
                )}
              </div>
              <div className="py-2 d-flex flex-column justify-content-start">
                <div
                  className={`${
                    isDarkMode ? "bg23 text-white border3B3936" : "lightMode"
                  } rounded rounded-4`}
                >
                  {showMap ? (
                    modalMap && window.innerWidth < 1000 ? (
                      <ModalContainer
                        isVisible={modalMap}
                        onClose={() => dispatch(toggleShowModalMap(false))}
                        height="80%"
                        zindex="1000"
                        modalTopHeading="Car Locations"
                      >
                        <div>
                          {response?.data?.cars &&
                            response?.data?.cars.length > 0 && (
                              <GoogleMaps
                                // lat={response?.data?.cars[0]?.location?.lat}
                                // long={response?.data?.cars[0]?.location?.lng}
                                lat={latitude}
                                long={longitude}
                                height="100vh"
                                width="100vw"
                              />
                            )}
                        </div>
                      </ModalContainer>
                    ) : (
                      <div className="text-center d-none d-lg-block">
                        {response?.data?.cars &&
                          response?.data?.cars.length > 0 && (
                            <GoogleMaps
                              // lat={response?.data?.cars[0]?.location?.lat}
                              // long={response?.data?.cars[0]?.location?.lng}
                              lat={latitude}
                              long={longitude}
                            />
                          )}
                      </div>
                    )
                  ) : modalFilterOption ? (
                    <ModalContainer
                      isVisible={modalFilterOption}
                      onClose={() =>
                        dispatch(toggleShowModalFilterOptions(false))
                      }
                      modalTopHeading="Filter By"
                    >
                      <div className="customizedScrollBar">
                        <FilterOptions
                          // btnColor={`${
                          //   isDarkMode ? "bg3B3936 text-white" : "lightGreyMode"
                          // }`}
                          // handleOnSelect={handleOnSelect} //this to filter data from sitemap
                          data={response}
                          setResponse={setResponse}
                          filterResponse={filterResponse}
                          filterData={filterData}
                          setFilterData={setFilterData}
                          subFilterData={subFilterData}
                          setSubFilterData={setSubFilterData}
                          carDataAvailable={carDataAvailable}
                          setCarDataAvailable={setCarDataAvailable}
                        />
                      </div>
                      <div className="bg3B3936 p-3 width100 modal-footerNew">
                        {/* <div className="d-flex justify-content-center align-items-center"> */}
                        <Button
                          onClick={() => {
                            handlePostData(filterSectionState);
                            setFilterData({ ...filterSectionState });
                            dispatch(toggleShowModalFilterOptions(false));
                            setSubFilterData({
                              autoPilotIds: [],
                              brand: [],
                              vehicleModel: [],
                              vehicleType: [],
                              seats: [],
                              colors: [],
                              ranges: [],
                              minPrice: "",
                              maxPrice: "",
                            });
                            setCarDataAvailable(false)
                          }}
                          btnClassName={`ClearAllAndApplyBtn fontSize13 fontAntarctica bg-secondary text-white ${
                            (currentLocations?.pickup === "" ||
                              currentLocations?.drop === "") &&
                            "disabled-class"
                          }`}
                        >
                          Clear All
                        </Button>
                        {/* </div> */}
                        {/* <div>
                        <Button
                          onClick={() => {
                            handlePostData(filterData);
                            dispatch(toggleShowModalFilterOptions(false));
                          }}
                          btnClassName={`ClearAllAndApplyBtn fontSize13 fontAntarctica bgCE1 ${
                            (currentLocations?.pickup === "" ||
                              currentLocations?.drop === "") &&
                            "disabled-class"
                          }`}
                        >
                          Apply Filter
                        </Button>
                      </div> */}
                      </div>
                    </ModalContainer>
                  ) : (
                    <div className="filterBoxContainer d-none d-lg-block carFilterScrollBar">
                      <FilterOptions
                        // btnColor={`${
                        //   isDarkMode ? "bg3B3936 text-white" : "lightGreyMode"
                        // }`}
                        //handleOnSelect={handleOnSelect} //this to filter data from sitemap
                        data={response}
                        setResponse={setResponse}
                        filterResponse={filterResponse}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        subFilterData={subFilterData}
                        setSubFilterData={setSubFilterData}
                        carDataAvailable={carDataAvailable}
                        setCarDataAvailable={setCarDataAvailable}
                      />
                      <div
                        className={`${
                          isDarkMode ? "bg3B3936 text-white" : "lightMode"
                        } p-3 width100 position-sticky z-3 bottom-0 border-bottom-radius`}
                      >
                        <div
                          // onClick={() => setFilterData({ ...filterSectionState })}
                          className="d-flex justify-content-center align-items-center"
                        >
                          {/* <p className="fontSize13 fontAntarctica mb-0">
                          Clear All
                        </p> */}
                          <Button
                            onClick={() => {
                              handlePostData(filterSectionState);
                              setFilterData({ ...filterSectionState });
                              setSubFilterData({
                                autoPilotIds: [],
                                brand: [],
                                vehicleModel: [],
                                vehicleType: [],
                                seats: [],
                                colors: [],
                                ranges: [],
                                minPrice: "",
                                maxPrice: "",
                              });
                              setCarDataAvailable(false)
                            }}
                            btnClassName={`ClearAllAndApplyBtn fontSize13 fontAntarctica bg-secondary text-white ${
                              (currentLocations?.pickup === "" ||
                                currentLocations?.drop === "") &&
                              "disabled-class"
                            }`}
                          >
                            Clear All
                          </Button>
                        </div>
                        {/* <div>
                        <Button
                          onClick={() => handlePostData(filterData)}
                          btnClassName={`ClearAllAndApplyBtn fontSize13 fontAntarctica bgCE1 ${
                            (currentLocations?.pickup === "" ||
                              currentLocations?.drop === "") &&
                            "disabled-class"
                          }`}
                        >
                          Apply Filter
                        </Button>
                      </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          onClick={() => dispatch(toggleShowModalMap(true))}
          className={`viewContainer w-100 ${
            response?.data?.cars && response?.data?.cars.length > 0
              ? ""
              : "disabled-class"
          }`}
        >
          <div className="text-white bg191916 text-center w-100 borderTopRadius">
            <div>
              <img className="notchImage" src={notchIcon} alt="Notch Icon" />
            </div>
            <div>
              <img className="padding-12px" src={mapIcon} alt="filterMapIcon" />
              View Car Location
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarList;
