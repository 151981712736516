import React, { useEffect, useState } from "react";
import locateIcon from "../../src/assets/svg/colorLocateIcon.svg";
import cancelTripIcon from "../../src/assets/svg/cancelBooking.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../api";
import DesktopCustomModal from "../Components/DesktopCustomModal";
import SpinnerLoader from "../Components/SpinnerLoader";
import moment from 'moment';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";
import DuplicateImage from "../assets/Images/Duplicate.png";
import carDuplicateImage from "../assets/Images/carDuplicateImage.png"

const CancelBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()
  const { bookingDetails } = location.state || {}; // Access the passed state
  const [transactionDetails, setTransactionDetails] = useState("");
  const [transactionError, setTransactionError] = useState("");
  const [cancelBookingResponse, setCancelBookingResponse] = useState("");
  const [cancelBookingError, setCancelBookingError] = useState("");
  const [cancelLoader, setCancelLoader] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const token = sessionStorage.getItem("AccessToken");
  const userProfile = JSON.parse(sessionStorage.getItem("CurrentUserDetails"));
  const {carModel,carName,carType, pickupLocation,dropOffLocation,dropOffDateTime,pickupDateTime} = bookingDetails
  const openModal = (modalId) => setActiveModal(modalId);
  const closeModal = () => {
    setActiveModal(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("cancel-booking"));
  }, [dispatch]);

  // Step 1: Detect if the page was refreshed
  useEffect(() => {
    const isPageRefreshed = sessionStorage.getItem("isRefreshed");

    if (isPageRefreshed) {
      sessionStorage.removeItem("isRefreshed"); // Remove flag after redirection
      navigate("/"); // Redirect to home page on refresh
    } else {
      sessionStorage.setItem("isRefreshed", "true"); // Set flag on initial load
    }
  }, [navigate]);

  useEffect(() => {
    const getAmountDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}booking/cancelBookingInfo/${bookingDetails?.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransactionDetails(response?.data?.data);
      } catch (err) {
        setTransactionError(
          "Failed to fetch transaction details. Please try again later."
        );
      }
    };

    getAmountDetails();
  }, [bookingDetails?.id, token]);

  const cancelTripBooking = async (id) => {
    setCancelLoader(true);
    try {
      const response = await axios.delete(`${BASE_URL}booking/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCancelBookingResponse(response);
      closeModal("CancelBookingModal");
      setCancelLoader(false);
      toast.success(response?.data?.message || "Booking cancelled successfully.")
      //console.log(response?.data?.message)
    } catch (error) {
      toast.error(error?.response?.data?.message)
      setCancelBookingError("error whil cancel booking");
      setCancelLoader(false);
    }
  };
  const formatPhoneNumber = (number) => {
    const cleaned = ('' + number).replace(/\D/g, ''); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number;
  };

  return (
    <div className="min-vh-100 pt-5 darkMode fontAntarctica">
      <div className="pt-5 container cancel-container">
        <h1 className="text-center mb-4  fontWeight400 fontAntarctica py-3">
          {cancelBookingResponse?.data?.data
            ? "Cancellation completed"
            : "Cancel Booking"}
        </h1>
        {cancelLoader ? (
          <SpinnerLoader />
        ) : (
          <div className="row fontWeight400">
            {/* Left Section - Vehicle Details */}
            <div className="col-md-6">
              <div className="mb-4">
                <h5>Vehicle Details</h5>
                <img
                  src={transactionDetails?.carImage ? transactionDetails?.carImage : carDuplicateImage}
                  alt="Vehicle"
                  className="img-fluid1 rounded"
                  style={{ width: "100%" }}
                />
                <div className="row mt-3">
                  <div className="col-6">
                    <h5 className="mb-0">{carName} {carModel}</h5>
                  </div>
                  <div className="col-6 text-end">
                    <p className="text mb-0">{carType}</p>
                  </div>
                </div>
              </div>

              <div className="card-custom mb-4 p-3 bg-dark text-white cancel-div">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h6 className="mb-0">Pick Up</h6>
                  <p className="mb-0 ">
                  {moment(pickupDateTime).format("MMM DD, YYYY hh:mm A")}
                    {/* {pickupDateTime} */}
                    </p>
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src={locateIcon}
                    alt="Location Icon"
                    className="me-2"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="mb-0 opacity">
                    {pickupLocation}
                  </p>
                </div>
              </div>

              <div className="card-custom mb-4 p-3 bg-dark text-white cancel-div">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h6 className="mb-0">Drop Off</h6>
                  <p className="mb-0">
                  {moment(dropOffDateTime).format("MMM DD, YYYY hh:mm A")}
                    {/* {dropOffDateTime} */}
                    </p>
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src={locateIcon}
                    alt="Location Icon"
                    className="me-2"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p className="mb-0 opacity">
                   {dropOffLocation}
                  </p>
                </div>
              </div>
            </div>

            {/* Right Section - Booking and Customer Info */}
            <div className="col-md-6">
              <div className=" card-custom p-3 mb-3">
                <h5 className="mb-2">Booking Details</h5>
                <div className="d-flex justify-content-between mt-2">
                  <p className="opacity">Booking ID</p>
                  <p className="">{bookingDetails?.bookingRefId}</p>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                <p className="opacity mb-0">Booking Status</p>
                {cancelBookingResponse?.data?.data ? (
                  <div className="nohover text-decoration-none p-1 br2 bgB5757 text-white" >
                    Cancelled
                  </div>
                ) : (
                  <div
                    className={`text-white p-2 fontSize14 text-decoration-none borderRadius8 ${
                      bookingDetails?.statusMessage === "Upcoming"
                        ? "bgCE1"
                        : bookingDetails?.statusMessage === "Review Pending"
                        ? "bg4ACC"
                        : ""
                    }`} 
                  >
                   {bookingDetails?.statusMessage}
                  </div>
                )}
              </div>

                {/* <div className="d-flex justify-content-between  align-items-end">
                  <p className="opacity">Booking Status:</p>
                  {cancelBookingResponse?.data?.data ? (
                    <button className="btn bgB5757 text-white">
                      Cancelled
                    </button>
                  ) : (
                    
                    <button className={`text-white p-2 fontSize14 borderRadius8 ${
                                 bookingDetails?.statusMessage === "Upcoming"
                                   ? "bgCE1"
                                   : bookingDetails?.statusMessage ===
                                     "Review Pending"
                                   ? "bg4ACC" : ''}`}>
                      Review Pending
                    </button>
                  )}
                </div> */}
              </div>

              <div className=" card-custom p-3 pb-0 mb-3">
                <h5 className="mb-2">Customer Info</h5>
                <div className="d-flex justify-content-between">
                  <p className="opacity">Name</p>
                  <p className="">{userProfile?.firstName } {userProfile?.lastName }</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="opacity">Email</p>
                  <p>{userProfile?.email }</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="opacity">Contact</p>
                  <p>+{userProfile?.countryCode} {formatPhoneNumber(userProfile?.primaryContact)}</p>
                </div>
              </div>
              <div>{transactionError && transactionError}</div>
              <div>{cancelBookingError && cancelBookingError}</div>
              <div className="card-custom p-3 cancel-div">
                <h5 >Refund Details</h5>
                {(transactionDetails?.bookingStatus !== 13 ||
                  Number(transactionDetails?.cancellationFee) !== 0 ||
                  Number(transactionDetails?.otherFees) !== 0) && <hr />}
                {transactionDetails?.bookingStatus !== 13 && (
                  <div className="d-flex justify-content-between">
                    <p className="opacity">Amount Paid</p>
                    <p>${transactionDetails?.amountPaid}</p>
                  </div>
                )}
                {Number(transactionDetails?.cancellationFee) !== 0 && (
                  <div className="d-flex justify-content-between">
                    <p className="opacity">Cancellation Fee</p>
                    <p>- ${transactionDetails?.cancellationFee}</p>
                  </div>
                )}

                {Number(transactionDetails?.otherFees) !== 0 && (
                  <div className="d-flex justify-content-between">
                    <p className="opacity">Other Fee</p>
                    <p>$ {transactionDetails?.otherFees}</p>
                  </div>
                )}
                <hr />
                <div className="d-flex justify-content-between">
                  <p className="opacity">Refundable Amount</p>
                  {transactionDetails?.bookingStatus !== 13 && (
                    <p className="fontSize19">${transactionDetails?.refundableAmount}</p>
                  )}
                  {transactionDetails?.bookingStatus === 13 && (
                    <p >${transactionDetails?.amountRelease}</p>
                  )}
                </div>
              </div>
              <div className="p-3">
                <p className="text mb-3 opacity">
                  Refund will be credited to your account within 72 hours. For
                  any other queries, contact our support team.
                </p>
              </div>
              <div className=" card-custom p-3 mb-3"> 
                <h5>Support Details</h5> 
                <div className="d-flex justify-content-between align-items-center mb-0">
                  <h6 className="mb-0 opacity">Email</h6>
                  <p className="mb-0">
                    <i className="bi bi-envelope me-2"></i>
                    <a className="fontColorCE1" href="mailto:support@drivejoulez.com">
                      support@drivejoulez.com
                    </a>
                  </p>
                </div>
                <div className="d-flex  justify-content-between align-items-center">
                  <h6 className="mb-0 opacity">Phone No</h6>
                  <p>
                    <i className="bi bi-telephone me-2 text-white"></i>
                    {/* <a className="fontColorCE1" href="tel:+14452568539">+1 (445) 2JOULEZ</a> */}
                    <a className="fontColorCE1" href="tel:+14452568539">+1 (445) 256-8539</a>
                    
                  </p>
                </div>
              </div>

              <DesktopCustomModal
                isModalOpen={activeModal === "CancelBookingModal"}
                closeModal={() => closeModal("CancelBookingModal")}
                closeOnOutsideClick={true}
                modalId="CancelBookingModal"
                modalTitle={
                  <div className="text-center p-3">
                    <img
                      className="mb-3"
                      src={cancelTripIcon}
                      alt="cancelTripIcon"
                    />
                    <div className="text-white text-center">Cancel Trip</div>
                  </div>
                }
                modalButton=""
                modalCenter={true}
              >
                <div className="text-center p-2">
                  <div className="pb-2">
                    Are you certain you want to cancel the trip?
                  </div>
                  <div className="d-flex justify-content-center my-3">
                    <button
                      onClick={() => closeModal("CancelBookingModal")}
                      className="mx-2 px-5 py-2 borderRadius8 bg3B3936 border-0 text-white outlineNone"
                    >
                      Discard
                    </button>
                    <button
                      onClick={() => cancelTripBooking(bookingDetails?.id)}
                      className="mx-2 px-5 py-2 borderRadius8 bgEA4335 border-0 text-white outlineNone"
                    >
                      Cancel Trip
                    </button>
                  </div>
                </div>
              </DesktopCustomModal>

              {cancelBookingResponse?.data?.data ? (
                <div className="d-flex justify-content-center align-items-center mb-5">
                  <button
                    onClick={() => navigate("/")}
                    className="mx-2 px-5 py-2 borderRadius8 bg3B3936 border-0 text-white outlineNone"
                  >
                    Home
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-end mb-5">
                  <button
                    onClick={() => navigate("/booking-history")}
                    className="btn btn-secondary me-2"
                  >
                    Discard
                  </button>
                  <button
                    onClick={() => openModal("CancelBookingModal")}
                    className="btn btn-danger"
                  >
                    Confirm Cancellation
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelBooking;
