import { useDispatch } from "react-redux";
import CurbsideDeliveryImage from "../assets/Images/CurbsideDeliveryImage.png"
import { toggleShowPickupDropdown } from "../Store/modalStatusSlice";

const CurbsideDeliveryContainer = () => {
    const dispatch = useDispatch()
  return (
    <div className="d-flex flex-column flex-lg-row justify-content-around align-items-center p-4 fontAntarctica">
      <div className="text-white px-lg-5 col-12 col-lg-6 order-1 order-lg-0">
        <div className="CurbsideHeader py-2">Curbside Delivery</div>
        <div className="CurbsidePara py-2">Delivery right to your door. Available across New York City, Northern New Jersey, and Los Angeles Metros.</div>
        <button onClick={() => {
             dispatch(toggleShowPickupDropdown(true));
        }} className="p-2 bgCE1 border border-0 br8 reserveNowBtn text-white mt-3">Reserve Now</button>
      </div>
      <div className="col-12 col-lg-4 order-0 order-lg-1">
        <img className="w-100" src={CurbsideDeliveryImage} alt="CurbsideDeliveryImage" />
      </div>
    </div>
  )
}

export default CurbsideDeliveryContainer
