import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import corporateImage from "../assets/Images/coperateRentalWeb.webp";
import corporateMobile from "../assets/Images/CorporateMobile2.png";
import corporateImage2 from "../assets/Images/CorporateRentals2.webp";
import corporateImage3 from "../assets/Images/CorporateRentals3.webp";
import corporateImage4 from "../assets/Images/corporateImage4.png";
import corporateImage5 from "../assets/Images/corporateImage5.png";
import corporate1 from "../assets/Images/eco2.jpeg";
import corporate2 from "../assets/Images/eco1.jpeg";
import corporate3 from "../assets/Images/eco3.jpeg";
import EcoImage from "../assets/Images/EcoImage.png";
import checkbox from "../assets/Images/checkbox1.png";
import DiscoverContainer from "../Components/DiscoverContainer";
import CarouselContainer from "../Components/CarouselContainer";
import emailWhiteIcon from "../assets/svg/emailWhiteIcon.svg";
import phoneWhiteIcon from "../assets/svg/phoneWhiteIcon.svg";
import SpinnerLoader from "../Components/SpinnerLoader";
import { setCurrentPage } from "../Store/pageSlice";
import axios from "axios";
import { BASE_URL } from "../api";
import { toast } from "react-toastify";
import Recaptcha from "../Components/Recaptcha";
const CorporateRentals = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [initialLoading, setInitialLoading] = useState(true);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    email: "",
    message: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("corporateRentals"));
  }, [dispatch]);

  // This effect runs once when the component is mounted (page load)
  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialLoading(false);
    }, 2000);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const validateForm = () => {
    let newErrors = {};

    // Validate first name
    if (!formData.firstName) {
      newErrors.firstName = "First name is required.";
    }

    // Validate last name
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required.";
    }

    // Validate job title
    if (!formData.jobTitle) {
      newErrors.jobTitle = "Job title is required.";
    }

    // Validate company name
    if (!formData.companyName) {
      newErrors.companyName = "Company name is required.";
    }

    // Validate email using regex
    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email.";
    }

    // Validate message length
    if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters long.";
    }

    setErrors(newErrors);
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
    
  //   //const value1 = formData[name];

  //   let error = "";
  //   if (name === "firstName" && !value) {
  //     error = `First name is required.`;
  //   } 
  //   else if (name === "lastName" && !value) {
  //     error = `Last name is required.`;
  //   } 
  //   else if (name === "jobTitle" && !value) {
  //     error = `Job title is required.`;
  //   }
  //   else if (name === "companyName" && !value) {
  //     error = `Company name is required.`;
  //   }
  //   else if (
  //     name === "email" &&
  //     !/^[^ @]+@[^ @]+\.[^ @]+$/.test(value)
  //     //!/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value)
  //   ) {
  //     error = "Please provide a valid email.";
  //   }
  //    else if (name === "message" && value.length < 10) {
  //     error = "Message must be at least 10 characters long.";
  //   }  

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: error,
  //   }));

  //   // Optional: You can validate on input change as well if needed
  //   // validateForm();
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Update form data state
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  
    let error = "";
  
    // Define a regex to check for special characters and consecutive spaces
    const noSpecialCharsOrSpaces = /^[a-zA-ZÀ-ÿ\s]+$/; // Allows letters (including accented) and spaces
    const noConsecutiveSpaces = /\s{3,}/; // Checks for consecutive spaces
    const noLeadingSpace = /^\s/; // Checks for leading spaces
  
    // Validation checks
    if (name === "firstName" && !value) {
      error = `First name is required.`;
    } else if (name === "firstName" && noLeadingSpace.test(value)) {
      error = `First name cannot start with a space.`;
    } else if (name === "firstName" && !noSpecialCharsOrSpaces.test(value)) {
      error = `First name cannot contain special characters.`;
    } else if (name === "firstName" && noConsecutiveSpaces.test(value)) {
      error = `First name cannot contain multiple spaces.`;
    } else if (name === "lastName" && !value) {
      error = `Last name is required.`;
    }  else if (name === "lastName" && noLeadingSpace.test(value)) {
      error = `Last name cannot start with a space.`;
    } else if (name === "lastName" && !noSpecialCharsOrSpaces.test(value)) {
      error = `Last name cannot contain special characters.`;
    } else if (name === "lastName" && noConsecutiveSpaces.test(value)) {
      error = `Last name cannot contain multiple spaces.`;
    } else if (name === "jobTitle" && !value) {
      error = `Job title is required.`;
    }  else if (name === "jobTitle" && noLeadingSpace.test(value)) {
      error = `Job title cannot start with a space.`;
    } else if (name === "jobTitle" && !noSpecialCharsOrSpaces.test(value)) {
      error = `Job title cannot contain special characters.`;
    } else if (name === "jobTitle" && noConsecutiveSpaces.test(value)) {
      error = `Job title cannot contain multiple spaces.`;
    } else if (name === "companyName" && !value) {
      error = `Company name is required.`;
    } else if (name === "companyName" && noLeadingSpace.test(value)) {
      error = `Company name cannot start with a space.`;
    } else if (name === "companyName" && !noSpecialCharsOrSpaces.test(value)) {
      error = `Company name cannot contain special characters.`;
    } else if (name === "companyName" && noConsecutiveSpaces.test(value)) {
      error = `Company name cannot contain multiple spaces.`;
    } else if (name === "email" && !/^[^ @]+@[^ @]+\.[^ @]+$/.test(value)) {
      error = "Please provide a valid email.";
    } else if (name === "message" && value.length < 10) {
      error = `Message must be at least 10 characters long.`;
    }
  
    // Update errors state
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  

  const handleBlur = (e) => {
    const { name } = e.target;
    const value = formData[name];

    if (name === "firstName" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "First name is required.",
      }));
    } 
    else if (name === "lastName" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Last name is required.",
      }));
    } 
    else if (name === "jobTitle" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Job title is required.",
      }));
    }
    else if (name === "companyName" && !value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Company name is required.",
      }));
    } else if (
      name === "email" &&
      !/^[^ @]+@[^ @]+\.[^ @]+$/.test(value)
      //!/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Please provide a valid email.",
      }));
    }
     else if (name === "message" && value.length < 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Message must be at least 10 characters long.",
      }));
    }  
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();
    
    if (Object.values(errors).every((error) => error === "")) {
      setErrors({
        firstName: "",
        lastName: "",
        jobTitle: "",
        companyName: "",
        email: "",
        message: "",
      })

      setInitialLoading(true)
      try {
        const response = await axios.post(
          `${BASE_URL}common/createCorporate/open`,
          formData
        );
        if (response.status === 200) {
          toast.success(" Your request has been successfully submitted.");
          setFormData({
            firstName: "",
            lastName: "",
            jobTitle: "",
            companyName: "",
            email: "",
            message: "",
          })
          handleCaptchaChange(false)
        } else {
          toast.error("Your submission could not be processed due to errors.");
        }
        setInitialLoading(false)
      } catch (error) {
        toast.error("Your submission could not be processed due to errors.");
        setInitialLoading(false)
      }
    } else {
      toast.error("Some information is missing or incorrect.");
      setInitialLoading(false)
    }
  };

  const [captchaVerified, setCaptchaVerified] = useState(false);
  // Handle captcha response
  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  return (
    <div
      className={`${
        isDarkMode ? "bg-dark text-white" : "bg-light text-dark"
      } fontWeight400 overflow-x-hidden`}
    >
      {initialLoading && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      <div className="container-fluid d-flex flex-column px-0">
        {/* <div className="">
          <img
            className="object-fit-cover w-100 d-none d-md-block corporateBgImage"
            src={corporateImage}
            alt="Corporate Rentals"
          />
          
          <img
            className="object-fit-cover w-100 d-md-none"
            src={corporateImage}
            style={{ height: "50vh" }} 
            alt="Corporate Rentals"
          />

     
          <div className="corporateTextOverlay">
            <div className="responsive-landing-header">
              <div className="mb-3 fontWeight400">
                Discover Sustainable Corporate Travel with Joulez
              </div>
              <div className="desc fs-6 mt-2 fontWeight400">
                {" "} 
              
                Empower Your Business with Electric Vehicle Rentals
              </div>
            </div>
          </div>
        </div> */}

      <div className="corporate-container">
        {/* Image for desktop & tablet */}
        <img
          className="object-fit-cover w-100 d-none d-md-block corporateBgImage"
          src={corporateImage}
          alt="Corporate Rentals"
        />
        {/* Image for mobile screens */}
        <img
          className=" w-100 d-md-none"
          src={corporateMobile}
          style={{ height: "50vh" }} // Adjust height for mobile
          alt="Corporate Rentals"
        />

        {/* Overlay text for mobile */}
        <div className="d-md-none">
          <div className="responsive-landing-header d-sm-none m-2 p-2">
            <div className="corporate-heading">
              <div>Discover Sustainable Business Travel with Joulez</div>
              {/* <div>Travel with Joulez</div> */}
            </div>
            <div className="corporate-subheading mt-2">
              Empower Your Business with Electric Vehicle Rentals
            </div>
            <div className="location">
              New York <span className="separator">|</span> Los Angeles
            </div>
          </div>
        </div>

        {/* Text below the image for larger screens */}
        <div className="corporateTextBelow d-none d-md-block">
          <div className="responsive-landing-header">
            <div className="corporate-heading">
              <div>Discover Sustainable</div>
              <div>Business Travel with Joulez</div>
            </div>
            <div className="corporate-subheading mt-2">
              Empower Your Business with Electric Vehicle Rentals
            </div>
            <div className="location">
              New York <span className="separator">|</span> Los Angeles
            </div>
          </div>
        </div>
      </div>


        <div className="bg191916 p-4">
          <div className="container d-flex flex-column flex-lg-row align-items-center text-white p-lg-4">
            <div className="col-lg-6 order-1 order-lg-2">
              <img
                src={corporateImage4}
                alt="Corporate Rentals 2"
                className="img-fluid rounded"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 px-lg-4  ">
              <h2 className="fontWeight600 mb-4 uniqueProgramHeading">
                A Unique Program Tailored to <br/> Your Business
              </h2>
              <p className="lead fontWeight300 mb-4 fontSize17">
                As a member, you get access to amazing benefits such as rewards, discounts, and more every time you or your team rents with Joulez. 
                The more your company rents, the more rewards you’ll receive. 
              </p>
              <button type="button" className="btn bg725CE1  width30 fontWeight400 p-2"
                onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
                Contact Us
              </button>
            </div>
          </div>
        </div>

        {/* <div className="bg23 py-4">
          <div className="container text-center "> // old code
            <h3 className="mb-4 text-white fontWeight400 fs-5">
              Why Choose Joulez for Your Corporate Needs?
            </h3>
            <div className="row">
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate2}
                  alt="Eco-Friendly Innovation"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">Eco-Friendly Innovation</h5>
                <p className="fontWeight400 bgInfo">
                  Reduce your carbon footprint and showcase your commitment to
                  sustainability.
                </p>
              </div>
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate1}
                  alt="Cost-Effective Solutions"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">Cost-Effective Solutions</h5>
                <p className="fontWeight400 bgInfo">
                  Enjoy significant savings on fuel costs with our
                  top-of-the-line electric vehicles.
                </p>
              </div>
              <div className="col-12 col-md-4 mb-4">
                <img
                  src={corporate3}
                  alt="Seamless Booking Experience"
                  className="img-fluid"
                />
                <h5 className="mt-2 fontWeight400">
                  Seamless Booking Experience
                </h5>
                <p className="fontWeight400 bgInfo">
                  Our user-friendly platform ensures a hassle-free reservation
                  process.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="corporate-custom bg-section py-4">
      <div className="container text-center">
        <h3 className="mb-4 corporate-custom-heading">
          <div> Why Choose Joulez for Your
          </div>
          Corporate Needs?
        </h3>
        <div className="row">
          <div className="col-12 col-md-4 mb-4">
            <div className="corporate-custom-image-container">
              <img
                src={corporate2}
                alt="Eco-Friendly Innovation"
                className="corporate-custom-img"
              />
              <div className="corporate-custom-text-overlay">
                <h5 className="corporate-custom-title">Eco-Friendly Innovation</h5>
                <p className="corporate-custom-description">
                  Reduce your carbon footprint and showcase your commitment to sustainability.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <div className="corporate-custom-image-container">
              <img
                src={corporate1}
                alt="Cost-Effective Solutions"
                className="corporate-custom-img"
              />
              <div className="corporate-custom-text-overlay">
                <h5 className="corporate-custom-title">Cost-Effective Solutions</h5>
                <p className="corporate-custom-description">
                  Enjoy significant savings on fuel costs with our top-of-the-line electric vehicles.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-4">
            <div className="corporate-custom-image-container">
              <img
                src={corporate3}
                alt="Seamless Booking Experience"
                className="corporate-custom-img"
              />
              <div className="corporate-custom-text-overlay">
                <h5 className="corporate-custom-title">Seamless Booking Experience</h5>
                <p className="corporate-custom-description">
                  Our user-friendly platform ensures a hassle-free reservation process.
                </p>
              </div>
            </div>
          </div>
          <div className="corporate-custom-btn-container">
          <button type="button" className="btn corporate-custom-btn"
            onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
            Contact Us
          </button>
        </div>
        </div>
      </div>
    </div> */}

<div className="corporate-custom bg-section py-4">
  <div className="container text-center">
    <h3 className="mb-4 corporate-custom-heading d-none d-lg-block">
      <div>Why Choose Joulez for Your Business Needs?</div>
    </h3>
    
    <h3 className="mb-4 corporate-custom-heading d-block d-lg-none">
      <div>Why Choose Joulez for</div>
      Your Business Needs?
    </h3>

    {/* Grid for Desktop */}
    <div className="row d-none d-md-flex custom-widthNew">
      <div className="col-12 col-md-4 mb-4">
        <div className="corporate-custom-image-container">
          <img src={corporate2} alt="Eco-Friendly Innovation" className="corporate-custom-img" />
          <div className="corporate-custom-text-overlay">
            <h5 className="corporate-custom-title">Sustainable Innovation</h5>
            <p className="corporate-custom-description">
              Reduce your carbon footprint and showcase your commitment to sustainability.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-4 ">
        <div className="corporate-custom-image-container">
          <img src={EcoImage} alt="Cost-Effective Solutions" className="corporate-custom-img" />
          <div className="corporate-custom-text-overlay">
            <h5 className="corporate-custom-title">Cost-Effective Solutions</h5>
            <p className="corporate-custom-description">
              Enjoy significant savings on fuel costs with our top-of-the-line electric vehicles.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 mb-4">
        <div className="corporate-custom-image-container">
          <img src={corporate3} alt="Seamless Booking Experience" className="corporate-custom-img" />
          <div className="corporate-custom-text-overlay">
            <h5 className="corporate-custom-title">Seamless Booking Experience</h5>
            <p className="corporate-custom-description">
              Our user-friendly platform ensures a hassle-free reservation process.
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Carousel for Mobile */}
    <div id="corporateCarousel" className="carousel slide d-md-none" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="corporate-custom-image-container">
            <img src={corporate2} alt="Eco-Friendly Innovation" className="corporate-custom-img" />
            <div className="corporate-custom-text-overlay">
              <h5 className="corporate-custom-title">Eco-Friendly Innovation</h5>
              <p className="corporate-custom-description">
                Reduce your carbon footprint and showcase your commitment to sustainability.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="corporate-custom-image-container">
            <img src={EcoImage} alt="Cost-Effective Solutions" className="corporate-custom-img" />
            <div className="corporate-custom-text-overlay">
              <h5 className="corporate-custom-title">Cost-Effective Solutions</h5>
              <p className="corporate-custom-description">
                Enjoy significant savings on fuel costs with our top-of-the-line electric vehicles.
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="corporate-custom-image-container">
            <img src={corporate3} alt="Seamless Booking Experience" className="corporate-custom-img" />
            <div className="corporate-custom-text-overlay">
              <h5 className="corporate-custom-title">Seamless Booking Experience</h5>
              <p className="corporate-custom-description">
                Our user-friendly platform ensures a hassle-free reservation process.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Carousel Controls */}
      <button className="carousel-control-prev" type="button" data-bs-target="#corporateCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#corporateCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>

    {/* Contact Us Button */}
    <div className="corporate-custom-btn-container">
      <button type="button" className="btn corporate-custom-btn"
        onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
        Contact Us
      </button>
    </div>
  </div>
</div>


        {/* <div className="bg3B3936">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-1 order-lg-1">
              <img
                src={corporateImage3}
                alt="Corporate Rentals 3"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-6 order-2 order-lg-2 text-center text-lg-start mt-4 mt-lg-0 fontWeight400">
              <h2 className="fontWeight400 fontAntarctica">
                Exclusive Corporate Rental Perks with Joulez:
              </h2>
              <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Special Discounts"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400 fw-500">
                      Special Discounts
                    </strong>
                    <p className="mb-0 bgInfo">
                      Enjoy up to 10% off on base rates, making your corporate
                      travel more affordable.
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Reward Days"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400">Reward Days</strong>
                    <p className="mb-0 bgInfo">
                      Earn a Reward Day after your first rental and for every 20
                      rental days.
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={checkbox}
                    alt="Business Member Site"
                    className="me-2"
                    width="30"
                    height="30"
                  />
                  <div>
                    <strong className="fontWeight400">
                      Business Member Site
                    </strong>
                    <p className="mb-0 bgInfo">
                      Access the Joulez for Business portal for effortless
                      tracking of rentals and rewards.
                    </p>
                  </div>
                </li>
              </ul>
              <button type="button" className="btn bg725CE1  width30 fontWeight400"
                onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
                Contact Us
              </button>
            </div>
          </div>
        </div>

        </div> */}
     


 
      </div>
      {/* <div className=" d-flex justify-content-center w-100 mt-0">
        <DiscoverContainer marginTop="mt-0" hideContent={true} />
      </div> */}
      <div className="bg262523">
      <div className="col-12 col-md-7 m-auto text-center p-3 pt-5">
        <div className="fontAntarctica fontWeight600 corporatePagediscoverEV">Discover EV</div>
        <div className="fontAntarctica fontSize17 fontWeight400 p-1 p-lg-3 mb-1">
        Embrace the future where every drive is a journey of modern transportation, sustainability, and a seamless experience. With Joulez, you're not just renting an EV, you're part of a movement.
        </div>
      </div>
      </div>
      <div className="text-white">
        <CarouselContainer />
      </div>

      <div className="p-0" style={{ backgroundColor: "#191916" }}>
          <div className="m-0 p-0 w-100">
            <div className="row align-items-stretch">
              {/* Left Column - Image */}
              <div className="col-lg-6 order-1 order-lg-1 d-flex align-items-stretch">
                <img
                  src={corporateImage3}
                  alt="Corporate Rentals 3"
                  className="img-fluid1 img-fluid "
                  style={{ objectFit: "cover", height: "100%" }} // Ensures full height stretch
                />
              </div>

              {/* Right Column - Content */}
              <div className="p-4 p-lg-5 col-lg-6 order-2 order-lg-2 mt-5 mb-3 text-center text-lg-start d-flex flex-column justify-content-center" 
              >
                <h2 className="fontWeight400 fontAntarctica textLeft " style={{ fontSize: "1.8rem" , color: "#F2EFE9"}}>
                <div className="textLeft text-wrap"> Exclusive Business Rental Perks with Joulez :</div> 
                </h2>
                <h6 className="textLeft1 py-3" >Elevate Your Business Travel Experience.</h6>

                <ul className="list-unstyled">
                  {[
                    { title: "Special Discounts", desc: "Enjoy up to 10% off on base rates, making your corporate travel more affordable." },
                    { title: "Reward Days", desc: "Earn a Reward Day after your first rental and for every 20 rental days." },
                    { title: "Business Member Site", desc: "Access the Joulez for Business portal for effortless tracking of rentals and rewards." },
                    { title: "Joulez Preferred Membership", desc: "Benefit from expedited service with no lines or paperwork, complimentary with your corporate account." },
                    { title: "24/7 Support", desc: "Our dedicated customer service team is always ready to assist, ensuring a seamless rental experience." }
                  ].map((item, index) => (
                    <li key={index} className="d-flex align-items-center mb-3">
                      <img src={checkbox} alt={item.title} className="me-2" width="30" height="30" />
                      <div className="list-text">
                        <strong className="fontWeight400" style={{ fontSize: "1rem" }}>{item.title}</strong>
                        <p className="mb-0 bgInfo mt-1" style={{ fontSize: "0.85rem", marginBottom: "5px" }}>{item.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>

                {/* Contact Us Button */}
              <div className="text-center text-md-start">
                <button type="button" className="btn bg725CE1 width30 fontWeight400 fontSize13"
                  style={{ padding: "8px 16px", marginTop: "10px" }}
                  onClick={() => document.getElementById('contactUsSection').scrollIntoView({ behavior: 'smooth' })}>
                  Contact Us
                </button>
              </div>

              </div>
            </div>
          </div>
        </div>

      <div   id="contactUsSection" className="d-flex  flex-column corporateContactUsSection  contactForm justify-content-center align-items-center ">
        <div className="headingNew my-3">Contact Us</div>
        <div className="mb-3 headingNext">
        Contact us today to learn more about our corporate rental programs and special offers.
        </div>
        <div className="input-group d-flex flex-column fontWeight400">
          <div className="d-flex flex-row">
            <div className="d-flex flex-column w-100">
              <input
                name="firstName"
                placeholder="First Name"
                type="text"
                value={formData.firstName}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus1"
                style={{ marginRight: "4px" ,color: "#191916", // Text color
                  backgroundColor: "transparent", }}
              />

              {errors.firstName && (
                <div className="fontcolor336 fontSize13">{errors.firstName}</div>
              )}
            </div>

            <div className="d-flex flex-column w-100 mx-1 ">
              <input
                name="lastName"
                placeholder="Last Name"
                type="text"
                value={formData.lastName}
                maxLength={50}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus1"
                style={{ marginLeft: "1px" }}
              />
              {errors.lastName && (
                <div className="fontcolor336 fontSize13">{errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="d-flex mt-2 flex-row">
            <div className="d-flex flex-column w-100">
              <input
                name="jobTitle"
                placeholder="Job Title"
                type="text"
                value={formData.jobTitle}
                maxLength={150}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus1"
                style={{ marginRight: "4px" }}
              />
              {errors.jobTitle && (
                <div className="fontcolor336 fontSize13">{errors.jobTitle}</div>
              )}
            </div>
            <div className="d-flex flex-column w-100 mx-1">
              <input
                name="companyName"
                placeholder="Company Name"
                type="text"
                value={formData.companyName}
                maxLength={150}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                className="form-control supportFormFocus1"
                style={{ marginLeft: "1px" }}
              />
              {errors.companyName && (
                <div className="fontcolor336 fontSize13">{errors.companyName}</div>
              )}
            </div>
          </div>
          <div className="fontWeight400 ">
            <input
              name="email"
              placeholder="Email"
              type="text"
              value={formData.email}
              maxLength={150}
              onChange={handleChange}
              onBlur={handleBlur} // Add onBlur here
              className="form-control supportFormFocus1 my-2 fontWeight400"
            />
            {errors.email && <div className="fontcolor336 fontSize13">{errors.email}</div>}
            <div className="form-floating my-2">
              <textarea
                name="message"
                className="form-control supportFormFocus1 corporateTextArea"
                placeholder=""
                value={formData.message}
                maxLength={2000}
                onChange={handleChange}
                onBlur={handleBlur} // Add onBlur here
                id="floatingTextarea"
              ></textarea>
               {!formData?.message && <label htmlFor="floatingTextarea" className="supportFormFocus">Message</label>}
             
            </div>
            {errors.message && (
              <div className="fontcolor336 fontSize13">{errors.message}</div>
            )}
            <div className="py-2">
              <Recaptcha onCaptchaChange={handleCaptchaChange} />
            </div>
            <button
              onClick={handleSubmit}
              className={`w-100 my-2 p-2 text-white bgCE1 borderRadius12 supportFormFocus1 outlineNone borderE1
                ${ 
                  !captchaVerified ||
                  formData?.companyName === "" ||
                  formData?.email === "" ||
                  formData?.firstName === "" ||
                  formData?.lastName === "" ||
                  formData?.jobTitle === "" ||
                  formData?.message === "" ||
                  errors?.companyName !== "" ||
                  errors?.email !== "" ||
                  errors?.firstName !== "" ||
                  errors?.lastName !== "" ||
                  errors?.jobTitle !== "" ||
                  errors?.message !== ""
                    ? "disabled-class"
                    : ""
                }
                `}
            >
              Submit
            </button>
            <div className="my-2 text-center fontAntarctica mt-3">
              {/* <div className="fontAntarctica fontSize25 my-3">Contact Us</div> */}
              <div className="fontSize16 my-2 d-flex justify-content-center p-2 borderRadius12">
                <div className="bgFFF supportPageWidth p-2 borderRadius12 d-flex justify-content-start">
                  <div className="support_Image_div">
                    <img src={emailWhiteIcon} alt="EmailIcon" />
                  </div>
                  <a
                    href="mailto:support@drivejoulez.com"
                    className="m-1 mx-2 cursorPointer text-decoration-none fontColorCE1"
                  >
                    support@drivejoulez.com
                  </a>
                </div>
              </div>
              <div className="fontSize16 my-2 d-flex justify-content-center p-2 borderRadius12">
                <div className="bgFFF supportPageWidth p-2 borderRadius12 d-flex justify-content-start">
                  <div className="support_Image_div">
                    <img src={phoneWhiteIcon} alt="EmailIcon" />
                  </div>
                  <a
                    href="tel:+14452568539"
                    className="m-1 mx-2 text-decoration-none fontColorCE1"
                  >
                    {/* +1 (445) 2JOULEZ */}
                    +1 (445) 256-8539
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateRentals;
