import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import resetImage from "../assets/svg/reset-password.svg";
import { useDispatch, useSelector } from "react-redux";
import "bootstrap-icons/font/bootstrap-icons.css";
import axios from "axios";
import { BASE_URL } from "../api";
import { setOpenLoginDesktopModal, setOpenSignUpDesktopModal } from "../Store/buttonSlice";
import { setCurrentPage } from "../Store/pageSlice";

const ResetPassword = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [infoMessage, setinfoMessage] = useState("");
  const [currentState, setCurrentState] = useState("Wait");
  const [email, setEmail] = useState("");
  const location = useLocation();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const urlParams = new URLSearchParams(window.location.search);
  const hashCode = urlParams.get('hashCode');

  const decodedHashcode = atob(hashCode);
  let splitDecodedHashcode = decodedHashcode?.split("--")
  //console.log(splitDecodedHashcode);

  // useEffect(() => {
  // const decodedHashcode = atob(hashCode);
  // let splitDecodedHashcode = decodedHashcode?.split("--")
  // console.log(splitDecodedHashcode);

  //  if(hashCode !== null){
  //    sessionStorage.setItem("userJdBookingDetails", JSON.stringify(splitDecodedHashcode))
  //  }
  // }, [hashCode])
  
  useEffect(() => {
    verifyHashCode();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("reset-password"));
  }, [dispatch]);

  const [loading, setLoading] = useState(true)
  
  const verifyHashCode = async () => {
    try {
      setLoading(true)
      const userResponse = await axios.get(
        `${BASE_URL}user/resetPassword/verifyEmail/open${location?.search}`
      );
      setinfoMessage(userResponse?.data?.message);
      setEmail(userResponse?.data?.data?.message);
      setCurrentState("Verified");
      setLoading(false)
    } catch (error) {
      setCurrentState("NotVerified");
      setinfoMessage(error?.response?.data?.message);
      setLoading(false)
    }
  };

  const validatePassword = (password) => {
    const pattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/;
    return pattern.test(password);
  };

  


  const handleInputChange = (e, field) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'password') {
      setPassword(value);
      // Validate password
      if (!validatePassword(value)) {
        setPasswordError("Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number, and a special character (such as !@#$%^&*_=+-)");
      } 
      else {
        setPasswordError('');
        setConfirmPasswordError('')
      }
    }

    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      // Validate confirm password
      if (!validatePassword(value)) {
        setConfirmPasswordError("Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number, and a special character (such as !@#$%^&*_=+-)");
      } else if (value && value !== password) {
        setConfirmPasswordError("Passwords do not match!");
      } else {
        setConfirmPasswordError('');
      }
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append("email", email);
    formData.append("hashCode", hashCode);
    formData.append("passwd", confirmPassword);

    try {
      const userResponse = await axios.post(
        `${BASE_URL}user/updatePassword/verifyEmail/open`,
        formData
      );
      setinfoMessage(userResponse?.data?.message);
      setCurrentState("Completed");
    } catch (error) {
      setCurrentState("NotVerified");
      setinfoMessage(error?.response?.data?.message);
    }
  };
  return (
    loading ? <p>Loading...</p> : <div>
      {currentState === "Wait" && (
        <div
          className={`password-reset-modal ${
            isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
          } py-5 min-vh-100 d-flex justify-content-center align-items-center`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div
                  className="custom-card p-4 rounded-4 shadow-lg"
                  style={{
                    backgroundColor: isDarkMode ? "#3b3936" : "#fff",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxWidth: "100%",
                    margin: "0 auto",
                    width: "auto",
                  }}
                >
                  <div className="text-center mb-4">
                    <img
                      src={resetImage}
                      alt="Reset Password"
                      className="img-fluid"
                      style={{
                        maxHeight: "120px",
                        objectFit: "contain",
                        marginBottom: "1rem",
                      }}
                    />
                  </div>
                  <h4 className="text-center mb-3">Password Reset</h4>
                  <div className="text-center mb-3">{infoMessage}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentState === "NotVerified" && (
        <div
          className={`password-reset-modal ${
            isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
          } py-5 min-vh-100 d-flex justify-content-center align-items-center`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div
                  className="custom-card p-4 rounded-4 shadow-lg"
                  style={{
                    backgroundColor: isDarkMode ? "#3b3936" : "#fff",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxWidth: "100%",
                    margin: "0 auto",
                    width: "auto",
                  }}
                >
                  <div className="text-center mb-4">
                    <img
                      src={resetImage}
                      alt="Reset Password"
                      className="img-fluid"
                      style={{
                        maxHeight: "120px",
                        objectFit: "contain",
                        marginBottom: "1rem",
                      }}
                    />
                  </div>
                  <h4 className="text-center mb-3">Password Reset</h4>
                  <div className="text-center mb-3">{infoMessage}</div>

                  <div
                    onClick={() => {
                      navigate("/");
                    }}
                    className="mt-3 fontAntarctica text-white thankyou-button text-center cursorPointer"
                  >
                    Home
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentState === "Completed" && (
        <div
          className={`password-reset-modal ${
            isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
          } py-5 min-vh-100 d-flex justify-content-center align-items-center`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div
                  className="custom-card p-4 rounded-4 shadow-lg"
                  style={{
                    backgroundColor: isDarkMode ? "#3b3936" : "#fff",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxWidth: "100%",
                    margin: "0 auto",
                    width: "auto",
                  }}
                >
                  <div className="text-center mb-4">
                    <img
                      src={resetImage}
                      alt="Reset Password"
                      className="img-fluid"
                      style={{
                        maxHeight: "120px",
                        objectFit: "contain",
                        marginBottom: "1rem",
                      }}
                    />
                  </div>
                  <h4 className="text-center mb-3">Password Reset</h4>
                  <div className="text-center mb-3">{infoMessage}</div>
                  <div
                    onClick={() => {
                      const newUrl = window.location.href.replace('/reset-password', '');
                      if(splitDecodedHashcode?.length === 4){
                        window.history.pushState({}, '', newUrl);
                      } else {
                          navigate("/")
                      }
                      
                      dispatch(setOpenLoginDesktopModal(true));
                    }}
                    className="text-center mt-3 fontMontserrat text-white thankyou-button cursorPointer"
                  >
                    Continue to login
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentState === "Verified" && (
        <div
          className={`password-reset-modal ${
            isDarkMode ? "bg-dark text-light" : "bg-light text-dark"
          } py-5 min-vh-100 d-flex justify-content-center align-items-center`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 col-lg-6">
                <div
                  className="custom-card p-4 rounded-4 shadow-lg"
                  style={{
                    backgroundColor: isDarkMode ? "#3b3936" : "#fff",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    maxWidth: "100%",
                    margin: "0 auto",
                    width: "auto",
                  }}
                >
                  <div className="text-center mb-4">
                    <img
                      src={resetImage}
                      alt="Reset Password"
                      className="img-fluid"
                      style={{
                        maxHeight: "120px",
                        objectFit: "contain",
                        marginBottom: "1rem",
                      }}
                    />
                  </div>
                  <h4 className="text-center mb-3">{splitDecodedHashcode?.length === 4 ? "Set Password" : "Password Reset"}</h4>
                  {splitDecodedHashcode?.length === 4 ? 
                  ""
                  :
                  <p
                    className="text-center mb-4 fontColor757575"
                  >
                    Your new password must be different from previously used
                    passwords.
                  </p>}
                  <form onSubmit={handleResetPassword}>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group" style={{ width: "100%" }}>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="form-control reset-input"
                          placeholder="Enter password"
                          value={password}
                          // onBlur={handlePasswordBlur}
                          // onChange={(e) => setPassword(e.target.value)}
                          // onChange={handlePasswordChange}
                          onChange={(e) => handleInputChange(e, 'password')}
                        />
                        
                        <span
                          className="input-group-text"
                          onClick={togglePasswordVisibility}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className={`bi ${
                              showPassword ? "bi-eye" : "bi-eye-slash-fill"
                            }`}
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </span>
                        <div className="mt-1 signInError fontAntarctica fontSize13">
                      {passwordError }
                    </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <div className="input-group" style={{ width: "100%" }}>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          
                          className={`form-control reset-input ${password === "" && "disabled-class"}`}
                          placeholder="Confirm your password"
                          value={confirmPassword}
                          // onBlur={handleConfirmPasswordBlur}
                          // onChange={(e) => setConfirmPassword(e.target.value)}
                          // onChange={handleConfirmPasswordChange}
                          onChange={(e) => handleInputChange(e, 'confirmPassword')}
                        />
                        <span
                          className="input-group-text"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <i
                            className={`bi ${
                              showConfirmPassword
                                ? "bi-eye"
                                : "bi-eye-slash-fill"
                            }`}
                            style={{
                              fontSize: "20px",
                            }}
                          />
                        </span>
                        
                        <div className="mt-1 signInError fontAntarctica fontSize13">
                          {confirmPasswordError }
                         </div>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className={`btn resetButton w-100 mt-3 ${(passwordError || confirmPasswordError || password === ""  || confirmPassword === "" ) ? "disabled-class"  : ""}`}
                      style={{
                        padding: "12px 20px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        transition: "all 0.3s ease",
                        width: "100%",
                      }}
                    >
                      {splitDecodedHashcode?.length === 4 ? "Set Password" : "Reset Password"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
