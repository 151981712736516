import React, { useCallback, useEffect, useRef } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";

const markers = [
  { id: 1, position: { lat: 40.837, lng: -73.865 } },
  { id: 2, position: { lat: -3.755, lng: -38.533 } },
];

function AdvancedMarker({ position, map }) {
  useEffect(() => {
    if (!map || !window.google || !window.google.maps.marker) return;

    const { AdvancedMarkerElement } = window.google.maps.marker;

    const marker = new AdvancedMarkerElement({
      position,
      map,
    });

    return () => {
      marker.setMap(null);
    };
  }, [map, position]);

  return null;
}

function GoogleMaps({
  height = "738px",
  width = "385px",
  lat = 40.837,
  long = -73.865,
}) {
  const center = {
    lat: lat,
    lng: long,
  };

  const containerStyle = {
    width: width,
    height: height,
    borderRadius: "12px",
  };

  const mapRef = useRef(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = null;
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {markers.map((marker) => (
        <AdvancedMarker
          key={marker.id}
          position={marker.position}
          map={mapRef.current}
        />
      ))}
      <Marker position={center} />
    </GoogleMap>
  );
}

export default GoogleMaps;
