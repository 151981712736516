import React, { useState, useEffect } from "react";
import ModalContainer from "../ModalContainer";
import DateTimeSlotContainer from "./DateTimeSlotContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowModalBookingDetails,
  toggleShowModalCalendar,
  toggleShowModalDropLocation,
  toggleShowModalPickupLocation,
} from "../../Store/modalStatusSlice";
import LineIcon from "../../assets/svg/LineIcon.svg";
import arrowIcon from "../../assets/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
import {
  addPickupAndDropDates,
  addPickupAndDropTimings,
} from "../../Store/dateAndTimeSlice";

function TimeSelector({
  toggle = () => {},
  show,
  bookingUIPage = false,
  dropUI = false,
  navbarHeaderUI = false,
  // locationTimeData = JSON.parse(
  //   localStorage.getItem("allLocationsTimeZones")
  // ) || [],
  filterOptions,
  restrictDropOffLocations,
  dateNewCount
}) {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const [pickupTimeSlotData, setPickupTimeSlotData] = useState([]);
  const [dropTimeSlotData, setdropTimeSlotData] = useState([]);
  const [pickupTimeIndex, setPickupTimeIndex] = useState("");
  const calendarStatus = useSelector((store) => store?.modalStatus);
  const currentLocations = useSelector((store) => store?.currentLocations);
  const currentDatesAndTimings = useSelector(
    (store) => store?.currentDatesAndTimings
  );
  const currentButtonStatus = useSelector((store) => store?.currentButton);

  const currentPage = useSelector((state) => state?.currentPage?.page);

  // Format options for displaying date
  const options = { year: "numeric", month: "short", day: "2-digit" };

  // Get the current day formatted
  // const currentDay = new Date().toLocaleDateString("en-US", options);

  const filterCurrentDate = JSON.parse(localStorage.getItem("allLocationsTimeZones"))
  const locationTimeSlot =
  filterCurrentDate?.timeSlots?.currentDate || "";
    
  const date = new Date(locationTimeSlot + "T00:00:00");
  const currentDay = date.toLocaleString("en-US", options);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date(
    currentDatesAndTimings?.pickupAndDropDates?.pickupDate
  );
  const nextDate = new Date(currentDate);
  nextDate.setDate(currentDate.getDate() + 1);
  const nextDay = nextDate.toLocaleDateString("en-US", options);

  const lsCondition = localStorage.getItem("allLocationsTimeZones");
  useEffect(() => {
    const timeData = JSON.parse(localStorage.getItem("allLocationsTimeZones"));
    let updatedtimeSlots;
    if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDay === currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay === currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDatesAndTimings?.pickupAndDropTimings?.pickupTime !== "11:30 PM"
    ) {
      setPickupTimeSlotData(timeData?.timeSlots?.currentDateTimeSlots);
      updatedtimeSlots = timeData?.timeSlots?.currentDateTimeSlots.slice(
        pickupTimeIndex + 1
      );
      setdropTimeSlotData(updatedtimeSlots);
      //dispatch(addPickupAndDropTimings({ dropTime: updatedtimeSlots[0] }));
    } else if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDatesAndTimings?.pickupAndDropTimings?.pickupTime === "11:30 PM"
    ) {
      dispatch(
        addPickupAndDropDates({
          pickupDate: currentDatesAndTimings?.pickupAndDropDates?.pickupDate,
          dropDate: nextDay,
        })
      );
    } else if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate !==
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDay === currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(timeData?.timeSlots?.currentDateTimeSlots);
      setdropTimeSlotData(timeData?.timeSlots?.generalTimeSlots);
      if (
        !timeData?.timeSlots?.currentDateTimeSlots?.includes(
          currentDatesAndTimings?.pickupAndDropTimings?.pickupTime
        )
      ) {
        dispatch(
          addPickupAndDropTimings({
            pickupTime: timeData?.timeSlots?.currentDateTimeSlots[0],
            dropTime: timeData?.timeSlots?.currentDateTimeSlots[0],
          })
        );
      }
    } else if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDay !== currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(timeData?.timeSlots?.generalTimeSlots);
      updatedtimeSlots = timeData?.timeSlots?.generalTimeSlots.slice(
        pickupTimeIndex + 1
      );
      setdropTimeSlotData(updatedtimeSlots);
    } else if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDatesAndTimings?.pickupAndDropTimings?.pickupTime === "11:30 PM"
    ) {
      dispatch(
        addPickupAndDropDates({
          pickupDate: currentDatesAndTimings?.pickupAndDropDates?.pickupDate,
          dropDate: nextDay,
        })
      );
      dispatch(
        addPickupAndDropTimings({
          dropTime: currentDatesAndTimings?.pickupAndDropTimings?.pickupTime,
        })
      );
    } else if (
      currentDatesAndTimings?.pickupAndDropDates?.pickupDate !==
        currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
      currentDay !== currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
      currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
    ) {
      setPickupTimeSlotData(timeData?.timeSlots?.generalTimeSlots);
      updatedtimeSlots = timeData?.timeSlots?.generalTimeSlots;
      setdropTimeSlotData(updatedtimeSlots);
    } else {
      setPickupTimeSlotData(timeData?.timeSlots?.generalTimeSlots);
      setdropTimeSlotData(timeData?.timeSlots?.generalTimeSlots);
    }
  }, [
    currentDay,
    currentDatesAndTimings,
    currentLocations?.pickupIdAndDropId?.pickupId,
    currentLocations?.pickupIdAndDropId?.pickupId,
    pickupTimeIndex,
    dispatch,
    nextDay,
    lsCondition,
  ]);

  // useEffect(() => {
  //   if (locationTimeData?.length !== 0) {
  //     const timeData = locationTimeData?.filter(
  //       (ele) => ele?.id === Number(currentPickupId)
  //     );
  //     let updatedtimeSlots;
  //     if (
  //       currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
  //         currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //       currentDay === currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
  //       currentDay === currentDatesAndTimings?.pickupAndDropDates?.dropDate
  //     ) {
  //       setPickupTimeSlotData(timeData[0]?.timeSlots?.currentDateTimeSlots);
  //       updatedtimeSlots = timeData[0]?.timeSlots?.currentDateTimeSlots.slice(
  //         pickupTimeIndex + 1
  //       );
  //       setdropTimeSlotData(updatedtimeSlots);

  //       if (
  //         currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
  //           currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //         currentDatesAndTimings?.pickupAndDropTimings?.pickupTime ===
  //           "11:30 PM"
  //       ) {
  //         dispatch(
  //           addPickupAndDropDates({
  //             pickupDate:
  //               currentDatesAndTimings?.pickupAndDropDates?.pickupDate,
  //             dropDate: nextDay,
  //           })
  //         );
  //       }
  //     } else if (
  //       currentDatesAndTimings?.pickupAndDropDates?.pickupDate !==
  //         currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //       currentDay === currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
  //       currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
  //     ) {
  //       setPickupTimeSlotData(timeData[0]?.timeSlots?.currentDateTimeSlots);
  //       updatedtimeSlots = timeData[0]?.timeSlots?.generalTimeSlots;
  //       setdropTimeSlotData(updatedtimeSlots);
  //       if (
  //         !timeData[0]?.timeSlots?.currentDateTimeSlots?.includes(
  //           currentDatesAndTimings?.pickupAndDropTimings?.pickupTime
  //         )
  //       ) {
  //         dispatch(
  //           addPickupAndDropTimings({
  //             pickupTime: timeData[0]?.timeSlots?.currentDateTimeSlots[0],
  //             dropTime: timeData[0]?.timeSlots?.currentDateTimeSlots[0],
  //           })
  //         );
  //       }
  //     } else if (
  //       currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
  //         currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //       currentDay !== currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
  //       currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
  //     ) {
  //       setPickupTimeSlotData(timeData[0]?.timeSlots?.generalTimeSlots);
  //       updatedtimeSlots = timeData[0]?.timeSlots?.generalTimeSlots.slice(
  //         pickupTimeIndex + 1
  //       );
  //       setdropTimeSlotData(updatedtimeSlots);
  //       console.log(
  //         currentDatesAndTimings?.pickupAndDropDates?.pickupDate,
  //         currentDatesAndTimings?.pickupAndDropDates?.dropDate,
  //         currentDatesAndTimings?.pickupAndDropTimings?.pickupTime,
  //         currentDatesAndTimings?.pickupAndDropTimings?.dropTime
  //       );

  //       if (
  //         currentDatesAndTimings?.pickupAndDropDates?.pickupDate ===
  //           currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //         currentDatesAndTimings?.pickupAndDropTimings?.pickupTime ===
  //           "11:30 PM"
  //       ) {
  //         dispatch(
  //           addPickupAndDropDates({
  //             pickupDate:
  //               currentDatesAndTimings?.pickupAndDropDates?.pickupDate,
  //             dropDate: nextDay,
  //           })
  //         );
  //         dispatch(
  //           addPickupAndDropTimings({
  //             dropTime: currentDatesAndTimings?.pickupAndDropTimings?.pickupTime,
  //           })
  //         )
  //       }
  //     } else if (
  //       currentDatesAndTimings?.pickupAndDropDates?.pickupDate !==
  //         currentDatesAndTimings?.pickupAndDropDates?.dropDate &&
  //       currentDay !== currentDatesAndTimings?.pickupAndDropDates?.pickupDate &&
  //       currentDay !== currentDatesAndTimings?.pickupAndDropDates?.dropDate
  //     ) {
  //       setPickupTimeSlotData(timeData[0]?.timeSlots?.generalTimeSlots);
  //       updatedtimeSlots = timeData[0]?.timeSlots?.generalTimeSlots;
  //       setdropTimeSlotData(updatedtimeSlots);
  //     }
  //     if (updatedtimeSlots?.length === 0) {
  //       setdropTimeSlotData(timeData[0]?.timeSlots?.generalTimeSlots);
  //     }
  //   }
  // }, [
  //   currentDatesAndTimings,
  //   locationTimeData,
  //   currentPickupId,
  //   currentDay,
  //   pickupTimeIndex,
  //   dispatch,
  //   nextDay,
  // ]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000 && show) {
        // If screen is smaller than 1000px and option container is shown, switch to modal
        // Optionally show the modal immediately
        dispatch(toggleShowModalCalendar(true));
        toggle(); // Hide the option container
      } else if (
        window.innerWidth >= 1000 &&
        calendarStatus?.showModalCalendar
      ) {
        // If screen is resized back to 1000px or more, close the modal
        dispatch(toggleShowModalCalendar(false));
        toggle();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [show, calendarStatus?.showModalCalendar, toggle]);

  const toggleDisplay = () => {
    if (window.innerWidth < 1000) {
      dispatch(toggleShowModalCalendar(true));
    } else {
      toggle();
    }
  };

  //this will use to open the calendar and time component when myTripsPage value is true
  // useEffect(() => {
  //   if (myTripPage) {
  //     toggleDisplay();
  //   }
  //   // eslint-disable-next-line
  // }, [myTripPage]);

  const handleClickSearch = () => {
    // if(!restrictDropOffLocations){
    dispatch(toggleShowModalCalendar(false));
    if(currentPage === "booking"){
      navigate("/booking")
    } else {
    navigate("/cars-list");
    }
    // }
  };

  return (
    <div
      className={`text-white cursorPointer ${
        navbarHeaderUI ? "" : ""
      } text-sm-left text-md-left text-lg-start text-xl-start ${
        bookingUIPage ? "" : "col-lg-3"
      } col-12 col-sm-12 fontAntarctica ${currentPage === "booking" ? "bookingNavbarContainer" : "border-bottom-grey"} commonBgInput px-2 mb-lg-0`}
    >
      {!bookingUIPage && !navbarHeaderUI && (
        <div className="fontSize13">
          {/* <div className="searchComponentHRLIne"></div> */}
          <div
            className={`${isDarkMode ? "fontColor212529 fontWeight700" : "fontcolor936"} d-flex`}
          >
            <span>
              Duration (
              {`${dateNewCount === "" ? 1 : dateNewCount} ${
                dateNewCount > 1 ? "days" : "day"
              }`}
              )
            </span>
          </div>
        </div>
      )}
      <div className="mt-0">
        <div
          className={` fontSize13 mb-0 mt-1 ${
            isDarkMode ? currentPage === "" ? "text-white" : "fontColor212529"  : "fontcolor936"
          }`}
          onClick={toggleDisplay}
        >
          {bookingUIPage ? (
            dropUI ? (
              <div className="d-flex justify-content-between align-items-center width200px">
                <div className="mb-0 fontSize13">
                  {currentDatesAndTimings?.pickupAndDropDates?.dropDate}
                </div>
                <div className="text-white hrLineSearch"></div>
                <div className="mb-0 fontSize13">
                  {currentDatesAndTimings?.pickupAndDropTimings?.dropTime}
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center width200px">
                <div className="mb-0 fontSize13">
                  {currentDatesAndTimings?.pickupAndDropDates?.pickupDate}
                </div>
                <div className="text-white hrLineSearch"></div>
                <div className="mb-0 fontSize13">
                  {currentDatesAndTimings?.pickupAndDropTimings?.pickupTime}
                </div>
              </div>
            )
          ) : navbarHeaderUI ? (
            <div
              onClick={() => dispatch(toggleShowModalBookingDetails(false))}
              className="d-flex justify-content-between align-items-center p-2"
            >
              <div>
                <div>Pick up</div>
                <div>{`${currentDatesAndTimings?.pickupAndDropDates?.pickupDate} ${currentDatesAndTimings?.pickupAndDropTimings?.pickupTime}`}</div>
              </div>
              <div className="px-2">
                <img src={arrowIcon} alt="lineIcon" />
              </div>
              <div>
                <div>Drop off</div>
                <div>{`${currentDatesAndTimings?.pickupAndDropDates?.dropDate}  ${currentDatesAndTimings?.pickupAndDropTimings?.dropTime}`}</div>
              </div>
            </div>
          ) : (
            // <p className={`mb-0 text-truncate textAlignLeft`}>
            //   {`${currentDatesAndTimings?.pickupAndDropDates?.pickupDate} ${currentDatesAndTimings?.pickupAndDropTimings?.pickupTime} - ${currentDatesAndTimings?.pickupAndDropDates?.dropDate}  ${currentDatesAndTimings?.pickupAndDropTimings?.dropTime} `}
            // </p>
            <p className={`mb-0 text-truncate textAlignLeft`}>
              {`${new Date(
                currentDatesAndTimings?.pickupAndDropDates?.pickupDate
              ).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })},
              ${currentDatesAndTimings?.pickupAndDropTimings?.pickupTime}`}
              <img className="mx-3" src={arrowIcon} alt="Arrow" />{" "}
              {`${new Date(
                currentDatesAndTimings?.pickupAndDropDates?.dropDate
              ).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              })},  
              ${currentDatesAndTimings?.pickupAndDropTimings?.dropTime}`}
            </p>
          )}
        </div>
        {window.innerWidth < 1000 || calendarStatus.showModalCalendar ? (
          <ModalContainer
            isVisible={calendarStatus.showModalCalendar}
            onClose={() => {
              dispatch(toggleShowModalCalendar(false));
            }}
            modalTopHeading="When"
          >
            <div className="d-flex justify-content-center">
              <div>
                <div className="text-start ">
                  <div>
                    {!navbarHeaderUI && (
                      <div className="rounded-3 p-1 mainContainer358 fontColorFE9 bgF0F d-flex justify-content-center  align-items-center ">
                        <div
                          // onClick={() => {
                          //   dispatch(toggleShowModalPickupLocation(true)); //this is to open the modal
                          //   dispatch(toggleShowModalDropLocation(false)); //this is to remove the focus on the input fields
                          //   dispatch(toggleShowModalCalendar(false));
                          // }}
                          className="dataContainer p-1 fontAntarctica fontSize9"
                        >
                          <p className="mb-0">Pickup</p>
                          <p className="mb-0 text-truncate">
                            {currentLocations?.pickup}
                          </p>
                        </div>
                        <div className="px-2">
                          <img src={LineIcon} alt="lineIcon" />
                        </div>
                        <div
                          // onClick={() => {
                          //   dispatch(toggleShowModalDropLocation(true)); //already we are sending the toggleShowModalPickupLocation(true) below so it will open the modal but this is to focus on the drop input fields
                          //   dispatch(toggleShowModalPickupLocation(true)); //this is to open the modal
                          //   dispatch(toggleShowModalCalendar(false));
                          // }}
                          className="dataContainer p-1 fontAntarctica fontSize9"
                        >
                          <p className="mb-0">Drop</p>
                          <p className="mb-0 text-truncate">
                            {currentLocations?.drop}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="rounded-3 mainSecondContainer358 borderE94 mt-2 text-start bg23 d-flex justify-content-center align-items-center">
                  <div className="secondDataContainer fontAntarctica">
                    <p className="mb-0 fontSize9">Pickup</p>
                    <p className="text-truncate mb-0 fontSize13 fontColorFE9">
                      {`${currentDatesAndTimings?.pickupAndDropDates?.pickupDate} ${currentDatesAndTimings?.pickupAndDropTimings?.pickupTime}`}
                    </p>
                  </div>
                  <div className="px-2">
                    <img src={arrowIcon} alt="lineIcon" />
                  </div>
                  <div className="secondDataContainer fontAntarctica">
                    <p className="mb-0 fontSize9">Drop off</p>
                    <p className="text-truncate mb-0 fontSize13 fontColorFE9">
                      {`${currentDatesAndTimings?.pickupAndDropDates?.dropDate} ${currentDatesAndTimings?.pickupAndDropTimings?.dropTime}`}
                    </p>
                  </div>
                </div>
                <DateTimeSlotContainer
                  pickupTimeSlotData={pickupTimeSlotData}
                  dropTimeSlotData={dropTimeSlotData}
                  setPickupTimeIndex={setPickupTimeIndex}
                />
              </div>
            </div>
            <div>
              <div className="d-flex  justify-content-center align-items-end  pt-3 w-100">
                <div className="d-flex justify-content-center align-items-center z-2 w-100 bg23">
                  <button
                    onClick={() => dispatch(toggleShowModalCalendar(false))}
                    className="w-100 searchButton bgFFFFFF1A text-white m-1"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleClickSearch}
                    className={`w-100 searchButton bgCE1 text-white m-1 ${
                      currentButtonStatus?.disableSearchButton
                        ? "bgBA3"
                        : "bgCE1"
                    }`}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          </ModalContainer>
        ) : (
          show && (
            <DateTimeSlotContainer
              pickupTimeSlotData={pickupTimeSlotData}
              dropTimeSlotData={dropTimeSlotData}
              bookingUIPage={bookingUIPage}
              setPickupTimeIndex={setPickupTimeIndex}
            />
          )
        )}
      </div>
    </div>
  );
}

export default TimeSelector;
