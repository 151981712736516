import { Outlet, createBrowserRouter } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Fleet from "./Pages/Fleet";
import CarList from "./Pages/CarList";
import EvangelList from "./Pages/EvangeList";
import Location from "./Pages/Location";
import Support from "./Pages/Support";
import AboutUS from "./Pages/AboutUs";
import { useSelector } from "react-redux";
import Booking from "./Pages/Booking";
import { LoadScript } from "@react-google-maps/api";
import NotFound from "./Pages/NotFound";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import BookingStatus from "./Pages/BookingStatus";
import ProtectedRoute from "./Pages/ProtectedRoute";
import ProfilePage from "./Pages/ProfilePage";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import GdprPrivacyPolicy from "./Pages/GdprPrivacyPolicy";
import RentalAgreement from "./Pages/RentalAgreement";
import CorporateRentals from "./Pages/CorporateRentals";
import Lighting from "./Pages/Lighting";
import MyTrips from "./Pages/MyTrips";
import CancelBooking from "./Pages/CancelBooking";
import Thankyou from "./Pages/Thankyou";
import SpinnerLoader from "./Components/SpinnerLoader";
import AppleLoginSuccessComponent from "./Components/AppleLoginSuccessComponent";
import Sitemap from "./Pages/Sitemap";
import VerifyEmail from "./Pages/VerifyEmail";
import ResetPassword from "./Pages/ResetPassword";
import useScrollY from "./Hooks/useScrollY";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const libraries = ["places"];

const AppLayout = () => {
  const currentPage = useSelector((state) => state?.currentPage?.page);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const scrollY = useScrollY();

  const handleLogin = (value) => {
    setIsLoggedIn(value);
  };

  return (
    <LoadScript
      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&loading=async`} // Replace with your actual API key
      libraries={libraries} // Ensure 'places' is included in the libraries array
      loadingElement={<div>
        <SpinnerLoader />
      </div>} // Show a loading indicator
    >
      <Elements stripe={stripePromise}>
        <div>
         {scrollY> 500 && (currentPage === "home" || currentPage === "showSearchContainer" )? "" : <Navbar onLogin={handleLogin} />}
          <div className="min-vh-100">
            <Outlet context={isLoggedIn} />
          </div>
          {currentPage === "car-list" || currentPage === "booking" ? (
            ""
          ) : (
            <Footer />
          )}
        </div>
      </Elements>
    </LoadScript>
  );
};

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/our-fleet",
        element: <Fleet />,
      },
      {
        path: "/sitemap",
        element: <Sitemap />,
      },
      {
        path: "/booking",
        element: <Booking />,
      },
      // {
      //   path: "/cars-list",
      //   element: <CarList />,
      // },
      {
        path: 'cars-list',
        children: [
          {
            path: '', // Default route for '/cars-list'
            element: <CarList />,
          },
          {
            path: 'country/:country/:location', // Match location within a country
            element: <CarList />,
          },
          {
            path: 'make/:make',
            element: <CarList />,
          },
          {
            path: '*',
            element: <NotFound /> // You can use a NotFound route if necessary
          },
        ],
      },  
      {
        path: "/evangelist",
        element: <EvangelList />,
      },
      {
        path: "/location",
        element: <Location />,
      },
      {
        path:"/lightningOS",
        element:<Lighting/>
      },
      {
        path: "/support",
        element: <Support />,
      },
      {
        path: "/forBusiness",
        element: <CorporateRentals />,
      },
      {
        path: "/about-us",
        element: <AboutUS />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/gdpr-privacy-policy",
        element: <GdprPrivacyPolicy />,
      },
      {
        path: "/rental-agreement",
        element: <RentalAgreement />,
      },
      {
        path: "/apple-login-success",
        element: <AppleLoginSuccessComponent />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/thankyou",
        element: 
        <ProtectedRoute>
          <Thankyou />
        </ProtectedRoute>,
      },
      {
        path: "/user-profile",
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/booking-status",
        element: (
          <ProtectedRoute>
            <BookingStatus />
          </ProtectedRoute>
        ),
      },
      {
        path: "/booking-history",
        element: (
          <ProtectedRoute>
            <MyTrips />
          </ProtectedRoute>
        ),
      },
      {
        path: "/cancel-booking",
        element: (
          <ProtectedRoute>
            <CancelBooking />
          </ProtectedRoute>
        ),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

export default appRouter;
