import React, { useEffect, useState } from "react";
import addressIcon from "../assets/svg/addressIcon.svg";
import licenseIcon from "../assets/svg/licenseIcon.svg";
import paymentMethodIcon from "../assets/svg/paymentMethodIcon.svg";
import profileCoverageIcon from "../assets/svg/profileCoverageIcon.svg";
import insuranceDocIcon from "../assets/svg/insuranceDocumentIcon.svg";
import emergencyContactIcon from "../assets/svg/emergencyContactIcon.svg";
import profileMoreIcon from "../assets/svg/profileMoreIcon.svg";
import profileFamilyIcon from "../assets/svg/profileFamilyIcon.svg";
import profileSocialIcon from "../assets/svg/profileSocialIcon.svg";
import facebookIcon from "../assets/svg/facebookIcon.svg";
import pencilIcon from "../assets/svg/pencilIcon.svg";
import circlePencilIcon from "../assets/svg/circlePencilIcon.svg";
import axleSuccessLogo from "../assets/svg/newAxleVerifiedLogo.svg";
import twitterIcon from "../assets/svg/TwitterNew.svg";
import instaIcon from "../assets/svg/InstaIcon.svg";
import axios from "axios";
import { BASE_URL } from "../api";
import DesktopCustomModal from "../Components/DesktopCustomModal";
import AddressAutocomplete from "../Components/AddressAutocomplete";
import FileUploader from "../Components/FileUploader";
import Checkout from "../Components/Checkout";
import { toast } from "react-toastify";
import { format, parse } from "date-fns";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../Store/pageSlice";
import SpinnerLoader from "../Components/SpinnerLoader";
import images from "../assets/Images";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CommonCalendar from "../Components/CommonCalendar";
import userProfileLogo from "../assets/svg/user-profile.svg";
import DateOfBirthPicker from "../Components/DateOfBirthPicker";
import signoutLogo from "../assets/svg/Signout.svg";
import hidePasswordIcon from "../assets/svg/hidePasswordIcon.svg";
import showPasswordIcon from "../assets/svg/showPasswordIcon.svg";
import ChangePasswordLogo from "../assets/svg/ChangePasswordLogo.png";
import { filter } from "lodash";
import { setCurrentUserDetails } from "../Store/userDetailsSlice";
const ProfilePage = () => {
  const isAxleNeed = process.env.REACT_APP_AXLE;
  const dispatch = useDispatch();
  const [accordionProfile, setAccordionProfile] = useState({
    Address: false,
    PaymentMethods: false,
    License: false,
    Coverages: false,
    Social: false,
    Family: false,
    Documents: false,
    Claims: false,
  });

  const [addressResponse, setAddressResponse] = useState("");
  const [savedCards, setSavedCards] = useState("");
  // const [savedCardError, setSavedCardError] = useState("");
  const [coverages, setCoverages] = useState("");
  // const [coverageError, setCoverageError] = useState("");
  const [licenseDetails, setLicenseDetails] = useState("");
  // const [licenseError, setLicenseError] = useState("");
  const [addressEditDetails, setAddressEditDetails] = useState("");
  const [familyDetails, setFamilyDetails] = useState("");
  const [stateList, setStateList] = useState("");
  const [selectedStateList, setSelectedStateList] = useState("United States");
  const [showConditions, setShowConditions] = useState(false);
  const [licenseDetailsCheckbox, setLicenseDetailsCheckbox] = useState(false);
  const [mailingAddressCheckbox, setMailingAddressCheckbox] = useState(false);
  const [loaderForDelete, setLoaderForDelete] = useState(false);
  const [loaderForLicense, setLoaderForLicense] = useState(false);
  const [addressDetails, setaddressDetails] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    countryCode: "",
    secondAddressline: "",
  }); //this state is using for AddressAutocomplete component

  const [drivingLicenseErrors, setDrivingLicenseErrors] = useState({
    drivingLicenseNumber: "",
  });

  const [errors, setErrors] = useState({
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  }); //address component errors

  const [drivingLicenseDocument, setDrivingLicenseDocument] = useState({
    frontPage: "",
    backPage: "",
    drivingLicenseNumber: "",
    expiryDate: "",
  });

  const [insuranceInputValues, setInsuranceInputValues] = useState({
    documentType: "I",
    policyNumber: "",
    issueState: "",
    provider: "",
    expiryDate: "",
    documentName: "",
  });

  const userProfile = JSON.parse(sessionStorage.getItem("CurrentUserDetails"));
  const [addressValues, setAddressValues] = useState({
    addressLabel: "Home",
    city: "",
    country: "United States",
    flatNo: "",
    isMailingAddress: false,
    lastUpdatedOn: "",
    showInProfile: true,
    state: "Alabama",
    status: 1,
    street: "",
    userFamilyId: 1,
    userId: userProfile?.id,
    zip: "",
  });

  const [addressErrors, setAddressErrors] = useState({
    flatNo: '',
    city: '',
    zip: '',
  });

  const [emergencyDetails, setEmergencyDetails] = useState({
    emergency: 0,
    id: 0,
    userId: userProfile?.id,
    contact: "",
    email: "",
    firstName: "",
    lastName: "",
    relationship: "Select Relation",
    countryCode: "",
    phoneNumberCountryCode: "",
  });

  const [emergencyDetailsError, setEmergencyDetailsError] = useState({
    contact: "",
    email: "",
    firstName: "",
    lastName: "",
    relationship: "",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [editCardDetailsForStripe, setEditCardDetailsForStripe] = useState("");
  const [singleUserCoverage, setSingleUserCoverage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const openModal = (modalId) => setActiveModal(modalId);
  const closeModal = () => {
    setActiveModal("");
    setAddressValues(addressValues);
    setIsEdit(false);
    setEmergencyDetails({
      emergency: 0,
      id: 0,
      userId: userProfile?.id,
      contact: "",
      email: "",
      firstName: "",
      lastName: "",
      relationship: "Select Relation",
      countryCode: "",
      phoneNumberCountryCode: "",
    });
  };

  const [userInsuranceDocs, setUserInsuranceDocs] = useState([]);
  // const [userInsuranceError, setUserInsuranceError] = useState("");
  const [axleDetails, setAxleDetails] = useState("");
  const [axleError, setAxleError] = useState("");
  const [axleLoading, setAxleLoading] = useState(false);
  const userDetails = JSON.parse(sessionStorage.getItem("CurrentUserDetails"));
  const {
    firstName,
    email,
    dob,
    countryCode,
    primaryContact,
    lastName,
    middleName,
    accountId,
    status,
  } = userDetails || {};

  const [editUserDetails, setEditUserDetails] = useState({
    ...userDetails,
    primaryContact:
      Number(userDetails?.countryCode) + userDetails?.primaryContact,
  });

  const [editUserDetailsLoading, setEditUserDetailsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = sessionStorage.getItem("AccessToken");
  const [socialID, setSocialID] = useState({
    fbId: userDetails?.fbId,
    instaId: userDetails?.instaId,
    twitterId: userDetails?.twitterId,
  });

  const [commonLoading, setCommonLoading] = useState(false);

  const [profilePic, setProfilePic] = useState(userProfileLogo); // Initialize with the current profile picture

  const handleImageChange = async (e) => {
    const file = e.target.files[0]; // Get the selected file

    if (file) {
      // Check if the file is an image and is smaller than 5MB
      if (!file.type.startsWith('image/')) {
        toast.error("Please select an image file.");
        return;
      }
  
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        toast.error("File size must be less than 5MB.");
        return;
      }
  
      const reader = new FileReader();
      
      // Read the file as a data URL (base64)
      reader.onloadend = () => {
        setProfilePic(reader.result); // Update the profile picture
      };
      
      reader.readAsDataURL(file); // Start reading the file
  
      // Append the image file to FormData for uploading
      const formData = new FormData();
      formData.append("file", file); // Append the actual file, not `profilePic`
  
      try {
        setCommonLoading(true);
        const response = await axios.post(`${BASE_URL}user/profile_pic`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        sessionStorage.setItem("CurrentUserDetails",JSON.stringify(response?.data?.data));
        setCommonLoading(false);
      } catch (error) {
        setCommonLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //this is used for both post the address and get the address list data
  const getAddressDetails = async (method, data = null) => {

    if (method === "post" || method === "put") {
      setLoaderForDelete(true);
    }
    try {
      const config = {
        method,
        url: `${BASE_URL}address`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (method === "post" || method === "put") {
        config.data = data; // Add data for POST requests
      }
      const response = await axios(config);
      // Handle response based on method
      // Handle response based on method
      if (method === "get") {
        setAddressResponse(response?.data?.data); // Assuming this is an array
      } else if (method === "post") {
        setAddressValues({
          addressLabel: "Home",
          city: "",
          country: "United States",
          flatNo: "",
          isMailingAddress: false,
          lastUpdatedOn: "",
          showInProfile: true,
          state: "Alabama",
          status: 1,
          street: "",
          userFamilyId: 1,
          userId: userProfile?.id,
          zip: "",
        })
        setAddressResponse([response?.data?.data]); // Wrap single object in an array
        toast.success(
          response?.data?.data?.message || "Address Added Successfully"
        );
        closeModal();
        getAddressDetails("get");
      } else if (method === "put") {
        setAddressResponse([response?.data?.data]); // Wrap single object in an array
        getAddressDetails("get"); // Optionally refresh data after updating
        toast.success(
          response?.data?.data?.message || "Address Updated Successfully"
        );
        closeModal();
      }
      setLoaderForDelete(false);
    } catch (error) {
      setLoaderForDelete(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const getSavedCards = async () => {
    try {
      const response = await axios.get(`${BASE_URL}savedcard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSavedCards(response?.data?.data);
      setCoverages(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // setSavedCardError(
      //   `Error fetching in cards due to ${error?.response?.data?.message}`
      // );
    }
  };

  // const getCoverages = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}savedcard/credit`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setCoverages(response?.data?.data);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //     // setCoverageError(
  //     //   `Error fetching in coverage cards due to ${error?.response?.data?.message}`
  //     // );
  //   }
  // };

  const getLicenseDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}licensedetail/getCurrentUserLicenseDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLicenseDetails(response?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // setLicenseError(
      //   `Error fetching in License due to ${error?.response?.data?.message}`
      // );
    }
  };

  const getFamilyDetails = async () => {
    try {
      const response = await axios.get(
        // `${BASE_URL}userFamily/getFamilyDetails`,

        `${BASE_URL}userFamily/getCurrentUserFamilyDetails`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFamilyDetails(response?.data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const getStateList = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}location/getStateList/open`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setStateList(response?.data?.data?.countries);
    } catch (error) {}
  };

  useEffect(
    () => {
      getAddressDetails("get");
      getSavedCards();
      //getCoverages();
      getLicenseDetails();
      getFamilyDetails();
      getStateList();
      getUserInsuranceDocs();
    }, //eslint-disable-next-line
    []
  );

  useEffect(() => {
    dispatch(setCurrentPage("/user-profile"));
  }, [dispatch]);

  const handleCloseAccordion = (type, value) => {
    setAccordionProfile((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  const hanldeAddressFields = (e) => {
    const { id, value } = e.target;
    setAddressValues((prev) => ({
      ...prev,
      [id]: value,
    }));

    // Validation for Street Addres 1 or flat no
    if(id === "flatNo" && value?.trim()?.length === 0){
      setAddressErrors((prev) => ({
        ...prev,
        flatNo: "Please enter valid Street Address 1",
      }));
    } else {
      setAddressErrors((prev) => ({
        ...prev,
        flatNo: "",
      }));
    }

     // Validation for City
  if (id === "city" && value.trim().length === 0) {
    setAddressErrors((prev) => ({
      ...prev,
      city: "Please enter valid city",
    }));
  } else {
    setAddressErrors((prev) => ({
      ...prev,
      city: "",
    }));
  }

  // Validation for Zip Code
  if (id === "zip" && (value.trim().length === 0 || !/^[a-zA-Z0-9]+$/.test(value))) {
    setAddressErrors((prev) => ({
      ...prev,
      zip: "Please provide a valid ZIP code.",
    }));
  } else {
    setAddressErrors((prev) => ({
      ...prev,
      zip: "",
    }));
  }

    if (id === "country") {
      const res = stateList.filter((state) => state?.name === value);
      setSelectedStateList(res);
    }
  };

  const isDisabled = () => {
    // Destructure the fields from addressValues
    const { city, flatNo, zip, addressLabel } = addressValues;

    // Check if any of the required fields are empty
    return !city || !flatNo || !zip || !addressLabel;
  };

  const deleteLicense = async (licenseId) => {
    setLoaderForLicense(true);
    try {
      await axios.delete(`${BASE_URL}licensedetail/${licenseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // toast.success("License Deleted Successfully");
      closeModal("DeleteDrivingLicense");
      getLicenseDetails();
      setLoaderForLicense(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoaderForLicense(false);
    }
  };

  const deletePaymentCard = async (paymentCard) => {
    setLoaderForDelete(true);
    try {
      await axios.delete(`${BASE_URL}savedcard/${paymentCard?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Card Deleted Successfully");
      closeModal("DeletePaymentCard");
      getSavedCards();
      setLoaderForDelete(false);
      // if (paymentCard?.funding === "credit") {
      //   //getCoverages();
      //   getSavedCards()
      // }
    } catch (error) {
      setLoaderForDelete(false);
      toast.error(error?.response?.data?.message || "Unable to Process");
      // toast.error(error?.response?.data?.message);
    }
  };

  const makeDefaultCardCard = async (paymentCard) => {
    setLoaderForDelete(true);
    try {
      const response = await axios.get(
        `${BASE_URL}savedcard/makeDefault/${paymentCard?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Card added as default");
      closeModal("makeDefaultCardCard");
      setSavedCards(response?.data?.data);
      // getSavedCards();
      setLoaderForDelete(false);
    } catch (error) {
      setLoaderForDelete(false);
      toast.error(error?.response?.data?.message || "Unable to Process");
      // toast.error(error?.response?.data?.message);
    }
  };
  const handleRemoveCoverage = async (coverageCard) => {
    setLoaderForDelete(true);
    try {
      await axios.delete(`${BASE_URL}userDocument/${coverageCard?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Insurance deleted successfully.");
      closeModal("deleteCoverage");
      getUserInsuranceDocs();
      setLoaderForDelete(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoaderForDelete(false);
    }
  };

  const deleteAddress = async (AddressId) => {
    setLoaderForDelete(true);
    try {
      await axios.delete(`${BASE_URL}address/${AddressId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // toast.success("Address deleted successfully.");
      closeModal("DeleteAddress");
      getAddressDetails("get");
      setLoaderForDelete(false);
      toast.success("Address deleted successfully.");
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setLoaderForDelete(false);
    }
  };

  const [postDrivingLicenseLoading, setPostDrivingLicenseLoading] =
    useState(false);

  const postDrivingLicenseData = async () => {
    // Create FormData instance
    setPostDrivingLicenseLoading(true);
    const formData = new FormData();

    // Append addressDetails data to FormData (replace with actual fields)
    formData.append("licenseFrontSide", drivingLicenseDocument?.frontPage);
    formData.append("licenseBacksideSide", drivingLicenseDocument?.backPage);
    formData.append(
      "licenseNumber",
      drivingLicenseDocument?.drivingLicenseNumber
    );
    formData.append("expiration", drivingLicenseDocument?.expiryDate);
    formData.append("issuingCountryCode", addressDetails?.countryCode);
    formData.append("issuingState", addressDetails.state);
    formData.append("issuingCityZipCode", addressDetails.zipCode);
    formData.append("addressLine1", addressDetails.address);
    formData.append("addressLine2", addressDetails.secondAddressline);
    formData.append("country", addressDetails.country);
    formData.append("city", addressDetails.city);
    formData.append("isMailingAddress", mailingAddressCheckbox);

    try {
      // Send the POST request with FormData and token header
      const response = await axios.post(
        `${BASE_URL}licensedetail/createLicenseDetail`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
          },
        }
      );
      toast.success(" Driver License details updated Successfully.");
      closeModal("DrivingLicenseModal");
      // Handle the response if needed
      getLicenseDetails();
      setPostDrivingLicenseLoading(false);
      
      setDrivingLicenseDocument({
        frontPage: "",
        backPage: "",
        drivingLicenseNumber: "",
        expiryDate: "",
      })
      
      setaddressDetails({
        address: "",
        city: "",
        state: "",
        zipCode: "",
        country: "",
        countryCode: "",
        secondAddressline: "",
      })
      setLicenseDetailsCheckbox(false)
      setMailingAddressCheckbox(false)

      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPostDrivingLicenseLoading(false);
    }
  };

  const getUserInsuranceDocs = async () => {
    setCommonLoading(true);
    try {
      const userResponse = await axios.get(
        `${BASE_URL}userDocument/currentUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserInsuranceDocs(userResponse?.data?.data);
      setCommonLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      // setUserInsuranceError("Error in getting Insurance docs");
      setCommonLoading(false);
    }
  };

  const handleChangeInsuranceDetails = (e) => {
    const { id, value } = e.target;
    setInsuranceInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeInsuranceDateDetails = (selectedDate) => {
    setInsuranceInputValues((prev) => ({
      ...prev,
      expiryDate: selectedDate,
    }));
  };

  const handleSubmitInsuranceDetails = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("documentType", insuranceInputValues?.documentType);
    formData.append("documentId", insuranceInputValues?.policyNumber);
    formData.append("issueState", insuranceInputValues?.issueState);
    formData.append("provider", insuranceInputValues?.provider);
    formData.append(
      "expiration",
      format(insuranceInputValues?.expiryDate, "yyyy-MM-dd HH:mm:ss")
    );
    formData.append("documentName", insuranceInputValues?.documentName); // The binary file
    setInsuranceInputValues((prev) => ({
      ...prev,
      documentType: "I",
      policyNumber: "",
      issueState: "",
      provider: "",
      expiryDate: "",
      documentName: "",
    }));
    try {
      const response = await axios.post(
        `${BASE_URL}userDocument/createProtectionPlan`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 200) {
        toast.success("Insurance added Successfully.");
        closeModal("AddInsurance");
        getUserInsuranceDocs(); // to get the user details
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSubmitUserDetails = async () => {
    // Clear any previous errors before validating again
    setEditUserDetailsLoading(true);
    setUserErrors({
      firstName: "",
      middleName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      dob: "",
    });

    let hasError = false;
    const newErrors = { ...userErrors };

    // Validate firstName (at least 2 characters)
    if (
      !editUserDetails.firstName ||
      editUserDetails.firstName.trim().length < 2
    ) {
      newErrors.firstName =
        "Please provide a valid first name (at least 2 characters)";
      hasError = true;
    }

    // Validate lastName (at least 2 characters)
    if (
      !editUserDetails.lastName ||
      editUserDetails.lastName.trim().length < 2
    ) {
      newErrors.lastName =
        "Please provide a valid last name (at least 2 characters)";
      hasError = true;
    }

    // If there are validation errors, update the state and exit
    if (hasError) {
      setUserErrors(newErrors);
      return; // Prevent the API call if there are validation errors
    }

    try {
      const response = await axios.put(
        `${BASE_URL}user`,
        {
          ...editUserDetails,
          dob: format(editUserDetails?.dob, 'yyyy-MM-dd'),
          primaryContact: editUserDetails?.primaryContact?.slice(
            editUserDetails?.countryCode?.length
          ),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
            Accept: "application/json", // Include Accept header
          },
        }
      );
      // Handle response here if needed, e.g., show a success message
      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(response?.data?.data)
      );
      closeModal("UserProfile");
      toast.success("Profile details updated successfully");
      setEditUserDetailsLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setEditUserDetailsLoading(false);
    }
  };

  const handleChangeUser = (e) => {
    const { id, value } = e.target;
    setEditUserDetails((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleChangeUserDateOfBirth = (selectedDate) => {
    //let formattedDate =  format(selectedDate, 'yyyy-MM-dd')
    setEditUserDetails((prevState) => ({
      ...prevState,
      dob: selectedDate,
    }));
  };

  const [userErrors, setUserErrors] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dob: "",
  });

  const validateField = (id, value) => {
    let error = ""; // Default to no error

    switch (id) {
      case "firstName":
        if (!value.trim()) {
          error = "First name is required.";
        } else if (value.trim().length < 2) {
          error = "First name must be at least 2 characters long.";
        }
        break;

      case "lastName":
        if (!value.trim()) {
          error = "Last name is required.";
        } else if (value.trim().length < 2) {
          error = "Last name must be at least 2 characters long.";
        }
        break;

      // Additional validation can go here for other fields like phoneNumber, etc.

      default:
        break;
    }

    return error;
  };

  const handlePhoneChange = (value, country) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue?.length < 3) {
      setUserErrors({
        ...userErrors,
        phoneNumber: "Please enter valid number",
      });
      setEmergencyDetailsError({
        ...emergencyDetailsError,
        contact: "Please enter valid number",
      });
    } else {
      setUserErrors({ ...userErrors, phoneNumber: "" });
      setEmergencyDetailsError({ ...emergencyDetailsError, contact: "" });
    }

    if (numericValue !== "") {
      setEditUserDetails((prev) => ({
        ...prev,
        countryCode: country?.dialCode,
        phoneNumberCountryCode: country?.countryCode,
        primaryContact: numericValue,
      }));
      setEmergencyDetails((prev) => ({
        ...prev,
        countryCode: country?.dialCode,
        contact: value,
        phoneNumberCountryCode: country?.countryCode,
      }));
    }
  };

  const handleEmergencyDetails = (e) => {
    const { id, value } = e.target;
    // Update emergency details first
    setEmergencyDetails((prev) => {
      const updatedDetails = {
        ...prev,
        [id]: value,
      };
      // Validate the field immediately after update
      validateEmergencyField(id, updatedDetails);
      return updatedDetails;
    });
  };

  // Validate fields immediately
  const validateEmergencyField = (field, updatedDetails = emergencyDetails) => {
    let errors = { ...emergencyDetailsError };

    switch (field) {
      case "firstName":
        if (updatedDetails.firstName.trim().length < 2) {
          errors.firstName = "First name must be at least 2 characters long.";
        } else {
          errors.firstName = "";
        }
        break;
      case "lastName":
        if (updatedDetails.lastName.trim().length < 2) {
          errors.lastName = "Last name must be at least 2 characters long.";
        } else {
          errors.lastName = "";
        }
        break;
      case "relationship":
        if (updatedDetails.relationship === "Select Relation") {
          errors.relationship = "Please select a relationship.";
        } else {
          errors.relationship = "";
        }
        break;
      case "email":
        if (!updatedDetails.email.trim()) {
          errors.email = "Email is required.";
        } else {
          const pattern = /^[^@]+@[^@]+\.[^@]+$/; // Email regex pattern
          if (!pattern.test(updatedDetails.email.trim())) {
            errors.email = "Please enter a valid email.";
          } else {
            errors.email = "";
          }
        }
        break;
      default:
        break;
    }

    // Set the updated errors
    setEmergencyDetailsError(errors);
  };

  // Check if form has any errors (for disabling the Save button)
  const hasEmeregencyErrors = Object.values(emergencyDetailsError).some(
    (error) => error !== ""
  );

  const [addressLoader, setAddressLoader] = useState(false)
  const handleSubmitEmergencyDetails = async (method) => {
    try {
      setAddressLoader(true)
      const config = {
        method, // Use dynamic method (POST or PUT)
        url: `${BASE_URL}userFamily`,
        headers: {
          Authorization: `Bearer ${token}`, // Include token in Authorization header
          Accept: "application/json", // Include Accept header
        },
      };

      // Add data for POST or PUT requests
      if (method === "post" || method === "put") {
        config.data = {
          ...emergencyDetails,
          contact: emergencyDetails?.contact?.slice(
            emergencyDetails?.countryCode?.length
          ),
        }; // Attach emergencyDetails as the payload
      }

      // Make the API call
      const response = await axios(config);

      // Handle POST or PUT responses
      if (method === "post" || method === "put") {
        getFamilyDetails(); // Optionally refresh data after adding or updating
        closeModal("AddFamilyContact"); // Close the modal
      }

      if (method === "post") {
        toast.success("Successfully Added"); // Show success message
      } else if (method === "put") {
        toast.success("Successfully Updated"); // Show success message
      }
      setAddressLoader(false)
    } catch (error) {
      toast.error(
        `Error: ${error?.response?.data?.message || "Something went wrong"}`
      ); // Show error message if something fails
      setAddressLoader(false)
    }
  };

  const handleChangeSocial = (e) => {
    const { id, value } = e.target;
    setSocialID((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const formatPhoneNumber = (number) => {
    const cleaned = ("" + number).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : number;
  };

  const handleSubmitSocialIDs = async (userId) => {
    setLoaderForDelete(true);
    try {
      const response = await axios.put(
        `${BASE_URL}user/socialId/${userId}`,
        socialID,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include token in Authorization header
            Accept: "application/json", // Include Accept header
          },
        }
      );
      getUserDetails();
      // toast.success(response?.data?.message);
      closeModal("SocialModal");
      setLoaderForDelete(false);
    } catch (error) {
      setLoaderForDelete(false);
      toast.error("Unable to update now");
    }
  };

  const getUserDetails = async () => {
    setAxleLoading(true);
    const token = sessionStorage.getItem("AccessToken");
    try {
      const userResponse = await axios.get(`${BASE_URL}user/currentUser`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      sessionStorage.setItem(
        "CurrentUserDetails",
        JSON.stringify(userResponse?.data?.data)
      );
      setSocialID({
        fbId: userResponse?.data?.data?.fbId,
        instaId: userResponse?.data?.data?.instaId,
        twitterId: userResponse?.data?.data?.twitterId,
      });
      setAxleLoading(false);
      toast.success("Social ID updated successfully.");
    } catch (error) {
      setAxleLoading(false);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const axleStatus = queryParams.get("status");

  const getAxleLink = async () => {
    setAxleLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}axle/generateIgnitionToken?returnUrl=user-profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAxleLoading(false);
      // const parsedData = JSON.parse(response?.data?.data);
      // window.location.href = parsedData.data.ignitionUri;
      const ignitionUri = response?.data?.data?.ignitionUri;
      if(ignitionUri){
        window.location.href = ignitionUri;
      }
      // localStorage.setItem("", true);
      setAxleDetails(response?.data);
    } catch (error) {
      setAxleLoading(false);
      setAxleError(
        `Error fetching in License due to ${error?.response?.data?.message}`
      );
    }
  };

  useEffect(() => {
    if (axleStatus === "complete" || axleStatus === "error") {
      if(axleStatus === "complete"){
        getAxleDetails();
        }
      clearQueryParams();
    } else {
      if (axleStatus === "exit") {
        clearQueryParams();
      }
    }
    // eslint-disable-next-line
  }, [axleStatus]);

  const clearQueryParams = () => {
    // Remove all specific query parameters
    searchParams.delete("status");
    searchParams.delete("authCode");
    searchParams.delete("client");
    searchParams.delete("result");
    // Update the URL with the new query parameters (which will be empty)
    setSearchParams(searchParams); // This will trigger a URL update
  };
  const getAxleDetails = async () => {
    setAxleLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}axle/getIgnitionResponse${location?.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.message || "Insurance details added successfully");
      setAccordionProfile((prev) => ({
        ...prev,
        Coverages: true,
      }));
      getUserInsuranceDocs();
      clearQueryParams();
      setAxleLoading(false);
    } catch (error) {
      setAxleLoading(false);
      toast.error(error?.response?.data?.message || "Unable to process");
    }
  };

  const handleDateSelect = (selectedDate) => {
    setDrivingLicenseDocument((prevValues) => ({
      ...prevValues,
      expiryDate: format(
        parse(selectedDate, "MM/dd/yyyy", new Date()),
        "yyyy-MM-dd"
      ),
    }));
  };

  const navigate = useNavigate()
  const [changePassword, setChangePassword] = useState({
   currentPassword:"",
   newPassword:"",
   confirmPassword:""
  })
  const [changePasswordError, setChangePasswordError] = useState({
    currentPassword:"",
    newPassword:"",
    confirmPassword:""
   })

   const [showPassword, setShowPassword] = useState({
    currentPassword:false,
    newPassword:false,
    confirmPassword:false
   })

   const [loaderForChangePassword, setLoaderForChangePassword] = useState(false)

 const handleCustomLogout = () => {
    toast.success("Signed out successfully");
    sessionStorage.removeItem("CurrentUserDetails");
    // setResponse("");
    sessionStorage.removeItem("AccessToken");
    // onLogin(false);
    dispatch(setCurrentUserDetails({ userDetails: "" }));
    navigate("/");
    localStorage.removeItem("selectedCar");
    localStorage.removeItem("bookingInputs");
    localStorage.removeItem("disableDropLocation");
    localStorage.removeItem("serviceCount");
    localStorage.removeItem("newFilteredPickupGoogleLocations");
    localStorage.removeItem("newFilteredDropGoogleLocations");
    //localStorage.removeItem("estimatedPriceDetails");
    localStorage.removeItem("localIsId1Disabled");
    localStorage.removeItem("localCurrentPickupLocation");
    localStorage.removeItem("localCurrentDropLocation");
    localStorage.removeItem("bookingInputs");
    localStorage?.removeItem("PaymentCard");
    localStorage.removeItem("insuranceDoc")
    localStorage.removeItem("daysCount");
    localStorage.removeItem("isRentalCoverageModal")
    sessionStorage.removeItem("userJDDetails")
    // Refresh the page after logout
    window.location.reload();
  };

  const validatePasswordField = (name, value) => {
    let error = "";
    const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,20}$/;

    switch (name) {
      case "currentPassword":
       if (!passwordRegex.test(value)) {
          error =
            "Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number and a special character (such as !@#$%^&*_=+-)";
        }
        break; 
        case "newPassword":
       if (!passwordRegex.test(value)) {
          error =
            "Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number and a special character (such as !@#$%^&*_=+-)";
        }
        break; 
        case "confirmPassword":
          if (!passwordRegex.test(value)) {
            error =
              "Please provide a valid password with 8-20 characters containing an uppercase, a lowercase, a number and a special character (such as !@#$%^&*_=+-)";
          }
        break; 
      default:
        break;
    }
    
    return error;
  }

  const handleChangePassword = (e) => {
  const {name, value} = e.target
  // console.log(name, value);
  setChangePassword((prev) => ({
    ...prev,
    [name]: value
  }))
  const error =  validatePasswordField(name, value);
  setChangePasswordError((prev) => ({
    ...prev,
    [name]:error
  }))

  // If the "newPassword" field is changed, check if the "confirmPassword" field matches
  if (name === "newPassword" || name === "confirmPassword") {
    const newPassword = name === "newPassword" ? value : changePassword.newPassword;
    const confirmPassword = name === "confirmPassword" ? value : changePassword.confirmPassword;

    if (newPassword !== confirmPassword && changePassword?.confirmPassword?.length > 0) {
      setChangePasswordError((prev) => ({
        ...prev,
        confirmPassword: "New Password and Confirm Password must match.",
      }));
    } else {
      setChangePasswordError((prev) => ({
        ...prev,
        confirmPassword: "",
      }));
    }
  }
  }

  const handleSubmitNewPassword = async () => {
    const payload = {
      oldPassword:changePassword?.currentPassword,
      newPassword:changePassword?.newPassword
    }
    if(changePassword?.currentPassword === changePassword?.newPassword){
      toast.error("Old and new passwords cannot be the same.")
    } else {
    try {
      setLoaderForChangePassword(true)
      await axios.post(`${BASE_URL}user/changePassword`, payload, {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      });
      toast.success("Password changed successfully.");
      closeModal("ChangePassword");
      setChangePassword({
        currentPassword:"",
        newPassword:"",
        confirmPassword:""
      })
      setChangePasswordError({
        currentPassword:"",
        newPassword:"",
        confirmPassword:""
      })
      setShowPassword({
        currentPassword:false,
        newPassword:false,
        confirmPassword:false
      })
    } catch (error) {
      setLoaderForChangePassword(false)
      toast.error(error?.response?.data?.message);
    }  
    setLoaderForChangePassword(false)
   }  
  }

  return (
    <>
      {(axleLoading || commonLoading || addressLoader) && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}

      <div className="profilePagePadding bg23 min-vh-100">
        <div className="pt-5 d-flex flex-column justify-content-center ">
          {/*UserProfile Modal */}
          <DesktopCustomModal
            isModalOpen={activeModal === "UserProfile"}
            closeModal={() => {
              closeModal("UserProfile")

              setUserErrors({
                firstName: "",
                middleName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                dob: "",
              })

              setEditUserDetails({
                ...userDetails,
                primaryContact:
                  Number(userDetails?.countryCode) + userDetails?.primaryContact,
              })
            }}
            closeOnOutsideClick={false}
            modalCenter={false}
            modalId="UserProfile"
            modalButton=""
            isCloseIconRequired={true}
            modalTitle={
              <div className="p-3 fontSize17 text-center text-white">
                User Details
              </div>
            }
          >
            <div className="px-2 input-group w-100">
              <div className="w-100">
                <div className="mb-1">
                  <img
                    className="mobileScreenProfileImage object-fit-cover mb-2"
                    src={userProfile?.profileImage ? userProfile?.profileImage : userProfileLogo} 
                    alt="userProfilePic"
                  />
                </div>
                <div className="mb-3">
                  <input
                    id="firstName"
                    placeholder="First Name"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3 cursor"
                    maxLength={50}
                    onChange={handleChangeUser}
                    onBlur={(e) => {
                      const error = validateField(e.target.id, e.target.value);
                      setUserErrors((prevErrors) => ({
                        ...prevErrors,
                        firstName: error,
                      }));
                    }}
                    value={editUserDetails?.firstName}
                  />
                </div>
                {userErrors.firstName && (
                  <div className="text-danger">{userErrors.firstName}</div>
                )}
                <div className="mb-3">
                  <input
                    id="middleName"
                    placeholder="Middle Name"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3 cursor"
                    maxLength={50}
                    onChange={handleChangeUser}
                    value={editUserDetails?.middleName}
                  />
                </div>
                <div className="mb-3">
                  <input
                    id="lastName"
                    placeholder="Last Name"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3 cursor"
                    maxLength={50}
                    onChange={handleChangeUser}
                    onBlur={(e) => {
                      const error = validateField(e.target.id, e.target.value);
                      setUserErrors((prevErrors) => ({
                        ...prevErrors,
                        lastName: error,
                      }));
                    }}
                    value={editUserDetails?.lastName}
                  />
                </div>
                {userErrors.lastName && (
                  <div className="text-danger">{userErrors.lastName}</div>
                )}
                <div className="mb-3">
                  {/* <input
                    id="dob"
                    placeholder="DOB"
                    type="date"
                    className="form-control supportFormFocus p-2 mb-3"
                    onChange={handleChangeUser}
                    value={editUserDetails?.dob}
                    disabled
                  /> */}
                  <DateOfBirthPicker
                      maxDate={
                        new Date(
                          2006,
                          new Date().getMonth(),
                          Math.min(new Date().getDate(), 31)
                        )
                      }
                      value={editUserDetails?.dob}
                      placeholder="Date Of Birth mm/dd/yyyy"
                      onDateSelect={handleChangeUserDateOfBirth}
                      isDisabled={userDetails?.dob ? true : false}
                    />
                </div>
                <div className="mb-3 borderRadius8 p-1 cursor">
                  <PhoneInput
                    value={editUserDetails?.primaryContact}
                    country={"us"}
                    placeholder="Phone Number"
                    onChange={handlePhoneChange}
                  />
                  <div className="fontColor4031">
                    {" "}
                    {userErrors?.phoneNumber && userErrors?.phoneNumber}
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    id="email"
                    placeholder="Email"
                    type="text"
                    //className="form-control supportFormFocus p-2 mb-3"
                    className="w-100 br8 p-2 mb-3 text-white profileDisableBg"
                    onChange={handleChangeUser}
                    value={editUserDetails?.email}
                    disabled
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => {
                      // Clear any previous errors before validating again
                      setEditUserDetails({
                        ...userDetails,
                        primaryContact:
                          Number(userDetails?.countryCode) +
                          userDetails?.primaryContact,
                      });
                      closeModal("UserProfile");
                    }}
                    className={`w-100 mt-2 px-3 py-2 text-white bg3B3936 borderRadius8 border-0 fontSize13`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSubmitUserDetails}
                    className={`${
                      (userErrors?.phoneNumber ||
                        editUserDetails?.firstName?.length < 2 ||
                        editUserDetails?.lastName?.length < 2) &&
                      "disabled-class "
                    } w-100 mt-2 mx-1 px-3 py-2 text-white bgCE1 borderRadius8 border-0 fontSize13`}
                  >
                    {editUserDetailsLoading ? <SpinnerLoader /> : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </DesktopCustomModal>
          <div className="pt-3">
            <div className="pt-3 text-center fontcolorFFF fontAntarctica cursor">
              {/* Profile picture */}
              <img
                className="mobileScreenProfileImage object-fit-cover profilePageMarginLeft profileBorder mb-2"
                src={userProfile?.profileImage ? userProfile?.profileImage : userProfileLogo} // Use the state for profile picture
                alt="userProfilePic"
              />

              {/* Edit icon for changing the profile picture */}
              <label htmlFor="fileInput">
                <div className="profilePencilIconContainer">
                <img
                  className="cursorPointer pencilIcon"
                  src={pencilIcon}
                  alt="circlePencilIcon"
                />
                </div>
              </label>

              {/* Hidden file input */}
              <input
                type="file"
                onChange={handleImageChange} // Trigger the change handler
                accept="image/*"
                id="fileInput"
                className="d-none"
              />

              <div className="fontSize17 mb-1">
                {firstName} {middleName} {lastName}
              </div>
              <div className="opacity60 fontSize13 mb-1">{email}</div>
              <div className="opacity60 fontSize13 mb-1">
                +{countryCode} {formatPhoneNumber(primaryContact)}
              </div>
              <div className="opacity60 fontSize13 mb-1">{dob}</div>
              <span
                onClick={() => {
                  openModal("UserProfile");
                }}
                className="opacity60 fontSize13 mb-1 text-decoration-underline cursorPointer"
              >
                Edit
              </span>
            </div>
            <div className="pt-3 d-flex justify-content-center mb-2">
              <div className="d-flex justify-content-between align-items-center fontAntarctica">
                <div className="d-flex flex-column justify-content-between align-items-center mx-3 fontcolorFFF">
                  <div className="accountTitles">Account ID</div>
                  <div className="opacity60 fontSize13">{accountId}</div>
                </div>
                <div className="hrLineSearch mx-3"></div>
                <div className="d-flex flex-column justify-content-between align-items-center mx-3 fontcolorFFF">
                  <div className="accountTitles">Account Status</div>
                  <div className="opacity60 fontSize13">
                    {status === 1 ? "Active" : "Inactive"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3">
            <div
              className={`${
                accordionProfile?.Address ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion("Address", !accordionProfile.Address)
                }
                className="d-flex align-items-center cursorPointer px-2"
              >
                <div>
                  <img src={addressIcon} alt="addressIcon" />
                </div>
                <div className="text-white mx-3 my-2 ">Addresses</div>
              </div>
              {accordionProfile?.Address && (
                <div>
                  <div className="row">
                    {addressResponse?.length === 0
                      ? ""
                      : addressResponse?.map((addressDetails) => {
                          return (
                            <div
                              key={addressDetails?.id}
                              className="col-12 col-lg-6 mb-1 px-3"
                            >
                              <div className="bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                                <div>
                                  <div className="mb-2">
                                    {addressDetails?.addressLabel}
                                  </div>
                                  <div className="mb-2">
                                    <div>
                                      <span>{addressDetails?.flatNo} </span>
                                      <span>{addressDetails?.street} </span>
                                      <span>{addressDetails?.city}, </span>
                                    </div>
                                    <div>
                                      <span>{addressDetails?.state} </span>
                                      <span>{addressDetails?.zip} </span>
                                      <span>{addressDetails?.country}</span>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center">
                                    {addressDetails?.isMailingAddress ? (
                                      <div className="d-flex align-items-center">
                                        <input type="radio" checked />
                                        <div className="mx-2 opacity-50">
                                          Mailing Address
                                        </div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                    <div className="d-flex cursorPointer">
                                      {!addressDetails?.isMailingAddress && (
                                        <div
                                          onClick={() => {
                                            openModal("Address");
                                            setAddressValues({
                                              ...addressDetails,
                                              showInProfile: true,
                                            });
                                            // setAddressValues((prev)=>({
                                            //   ...prev,
                                            //   addressDetails
                                            //  }));
                                            setIsEdit(true);
                                          }}
                                        >
                                          Edit
                                        </div>
                                      )}
                                      <div
                                        onClick={() => {
                                          openModal("DeleteAddress");
                                          setAddressEditDetails(addressDetails);
                                        }}
                                        className="fontColorB5757 mx-3 cursorPointer"
                                      >
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </div>
                  {/*Add dektopModal */}
                  <DesktopCustomModal
                    isModalOpen={activeModal === "Address"}
                    closeModal={() =>  {
                      closeModal()
                      setAddressValues({
                        addressLabel: "Home",
                        city: "",
                        country: "United States",
                        flatNo: "",
                        isMailingAddress: false,
                        lastUpdatedOn: "",
                        showInProfile: true,
                        state: "Alabama",
                        status: 1,
                        street: "",
                        userFamilyId: 1,
                        userId: userProfile?.id,
                        zip: "",
                      })
                    }}
                    modalCenter={true}
                    modalId={"Address"}
                    closeOnOutsideClick={false}
                    isCloseIconRequired={true}
                    modalTitle={
                      <div className="p-3 fontSize22 text-center text-white">
                        Add New Address
                      </div>
                    }
                  >
                    <div className="fontAntarctica fontSize17">
                      <div className="text-white d-flex justify-content-between">
                        <div className="w-50 p-2">Address Type</div>
                        <select
                          id="addressLabel"
                          value={addressValues?.addressLabel}
                          onChange={(e) => hanldeAddressFields(e)}
                          className="w-50 p-2 cursorPointer outlineNone text-white bg23 supportFormFocus borderRadius8"
                        >
                          <option value="Home">Home</option>
                          <option value="office">Office</option>
                          <option value="others">Others</option>
                        </select>
                      </div>
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">Street Address 1</div>
                        <input
                          id="flatNo"
                          onChange={(e) => hanldeAddressFields(e)}
                          value={addressValues?.flatNo}
                          maxLength={150}
                          type="text"
                          className="cursorPointer w-50 bg23 border3B3936 text-white p-2 outlineNone border-0 supportFormFocus borderRadius8"
                        />
                      </div>
                      {addressErrors?.flatNo &&  <div className="text-danger text-end mt-1 fontSize14">{addressErrors?.flatNo}</div>}
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">Street Address 2</div>
                        <input
                          id="street"
                          onChange={(e) => hanldeAddressFields(e)}
                          value={addressValues?.street}
                          maxLength={150}
                          type="text p-2"
                          className="cursorPointer w-50 bg23 border3B3936 text-white p-2 outlineNone border-0 supportFormFocus borderRadius8"
                        />
                      </div>
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">City</div>
                        <input
                          id="city"
                          onChange={(e) => hanldeAddressFields(e)}
                          maxLength={150}
                          value={addressValues?.city}
                          type="text"
                          className="cursorPointer w-50 bg23 border3B3936 text-white p-2 outlineNone border-0 supportFormFocus borderRadius8"
                        />
                      </div>
                      {addressErrors?.city &&  <div className="text-danger fontSize14 text-end mt-1">{addressErrors?.city}</div>}
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">Country</div>
                        {stateList.length !== 0 && (
                          <select
                            id="country"
                            onChange={(e) => hanldeAddressFields(e)}
                            value={addressValues?.country}
                            className="cursorPointer w-50 p-2 outlineNone text-white bg23 supportFormFocus borderRadius8"
                            // onChange={(e) => handleSelectChange(e.target.value)}
                          >
                            {stateList.length !== 0 &&
                              stateList.map((state, index) => (
                                <option
                                  key={state?.id || index}
                                  value={state?.id}
                                >
                                  {state?.name}
                                </option>
                              ))}
                          </select>
                        )}
                      </div>
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">State/Territory</div>
                        {stateList.length !== 0 && (
                          <select
                            id="state"
                            onChange={(e) => hanldeAddressFields(e)}
                            value={addressValues?.state}
                            className="cursorPointer w-50 p-2 outlineNone text-white bg23 supportFormFocus borderRadius8"
                          >
                            {selectedStateList === "United States"
                              ? stateList[0].states.map((state, index) => (
                                  <option
                                    key={state?.id || index}
                                    value={state?.id}
                                  >
                                    {state?.name}
                                  </option>
                                ))
                              : selectedStateList[0]?.states?.map(
                                  (state, index) => (
                                    <option
                                      key={state?.id || index}
                                      value={state?.id}
                                    >
                                      {state?.name}
                                    </option>
                                  )
                                )}
                          </select>
                        )}
                      </div>
                      <div className="text-white d-flex justify-content-between mt-2">
                        <div className="w-50 p-2">ZIP</div>
                        <input
                          id="zip"
                          onChange={(e) => hanldeAddressFields(e)}
                          value={addressValues?.zip}
                          maxLength={10}
                          className="cursorPointer w-50 bg23 border3B3936 text-white p-2 outlineNone border-0 supportFormFocus borderRadius8"
                          placeholder="ZIP Code"
                          pattern="[a-zA-Z0-9]"
                          onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "")} // Removes special characters dynamically
                        />
                      </div>
                      {addressErrors?.zip &&  <div className="text-danger fontSize14 text-end mt-1">{addressErrors?.zip}</div>}
                      <div>
                        <button
                          disabled={isDisabled()}
                          onClick={() => {
                            getAddressDetails(
                              isEdit ? "put" : "post",
                              addressValues
                            );
                          }}
                          className={`${
                            isDisabled() ? "opacity-25" : ""
                          } w-100 mt-3 borderRadius8 bgCE1 text-white p-2 border-0 outlineNone`}
                        >
                          {loaderForDelete ? <SpinnerLoader /> : "Save"}
                        </button>
                      </div>
                    </div>
                  </DesktopCustomModal>
                  {/*Delete address modal */}
                  <DesktopCustomModal
                    isModalOpen={activeModal === "DeleteAddress"}
                    closeModal={() => closeModal("DeleteAddress")}
                    closeOnOutsideClick={true}
                    modalCenter={true}
                    modalId="DeleteAddress"
                    modalButton=""
                    modalTitle={
                      <div className="p-3 text-center text-white fontSize14">
                        Are you sure do you want to delete address?
                      </div>
                    }
                  >
                    <div className="d-flex justify-content-between w-100">
                      <button
                        onClick={() => closeModal("DeleteAddress")}
                        className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                      >
                        Cancel
                      </button>

                      <button
                        onClick={() => deleteAddress(addressEditDetails?.id)}
                        className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                      >
                        {loaderForDelete ? <SpinnerLoader /> : "Confirm"}
                      </button>
                    </div>
                  </DesktopCustomModal>
                  {/* Add New Address button aligned within a col-12 */}
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => openModal("Address")}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add New Address
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*Payment div */}
          {/* {savedCardError && (
            <div className="fontColor4031">{savedCardError}</div>
          )} */}
          <div className="pt-3">
            <div
              className={`${
                accordionProfile?.PaymentMethods ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion(
                    "PaymentMethods",
                    !accordionProfile.PaymentMethods
                  )
                }
                className="d-flex align-items-center cursorPointer px-2"
              >
                <div>
                  <img src={paymentMethodIcon} alt="paymentMethodIcon" />
                </div>
                <div className="text-white mx-3 my-2">Payment Methods</div>
              </div>
              {accordionProfile?.PaymentMethods && (
                <div>
                  <div className="row">
                    {savedCards?.length !== 0 &&
                      savedCards?.map((cards) => {
                        return (
                          <div
                            key={cards?.id}
                            className="col-12 col-lg-6 mb-1  px-3"
                          >
                            <div className="bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                              <div>
                              <div className="mb-2">
                                  {cards?.nickName  ? cards?.nickName : `${cards?.cardIssuedBy} Card`}
                                </div>
                                <div className="mb-2">
                                  XXXX XXXX XXXX {cards?.cardNumber}
                                </div>
                                <div className="mb-2">{cards?.name}</div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="mb-2">
                                    {cards?.expiryDate}
                                  </div>
                                  <div className="mx-2">
                                    <img
                                      className="mx-1 height35px"
                                      src={
                                        images[cards?.cardIssuedBy] ||
                                        images.defaultCard
                                      }
                                      alt="images"
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  {/* <div className="d-flex">
                                    <input type="radio" />
                                    <div className="mx-2 opacity-50">
                                      Primary
                                    </div>
                                  </div> */}
                                  <div className="d-flex">
                                    {!cards?.defaultSource ? (
                                      <div
                                        onClick={() => {
                                          openModal("makeDefaultCard");
                                          setEditCardDetailsForStripe(cards);
                                        }}
                                        className="opacity-50 cursorPointer"
                                      >
                                        Make Default
                                      </div>
                                    ) : (
                                      <div className="fontC885">Default</div>
                                    )}
                                  </div>
                                  <div className="d-flex">
                                    {  
                                    !cards?.readOnly &&
                                    <div
                                    className={`cursorPointer ${cards?.readOnly ? "cardEditpaddingRight" : ""}`}
                                      onClick={() => {
                                        openModal("EditPaymentCard");
                                        setEditCardDetailsForStripe(cards);
                                      }}
                                    >
                                      Edit
                                    </div>
                                    }
                                    {
                                      !cards?.readOnly &&
                                      <div
                                      onClick={() => {
                                        openModal("DeletePaymentCard");
                                        setEditCardDetailsForStripe(cards);
                                      }}
                                      className="mx-3 cursorPointer fontColorB5757"
                                    >
                                      Remove
                                    </div>
                                   
                                    }
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => {
                          openModal("AddNewCard");
                        }}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add New Card
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/*Add New card */}
              <DesktopCustomModal
                isModalOpen={activeModal === "AddNewCard"}
                closeModal={() => closeModal("AddNewCard")}
                closeOnOutsideClick={false}
                modalId="AddNewCard"
                modalTitle={
                  <div className="fontColorCE1 p-3">Add New Card</div>
                }
                modalButton=""
                modalCenter={true}
                modalBackgroudColor="transparent"
                isCloseIconRequired={true}
              >
                <Checkout
                  closeModal={() => {
                    closeModal("AddNewCard");
                    getSavedCards();
                    //getCoverages();
                  }}
                />
              </DesktopCustomModal>
              {/*Edit payment card */}
              <DesktopCustomModal
                isModalOpen={activeModal === "EditPaymentCard"}
                closeModal={closeModal}
                modalCenter={true}
                modalId={"EditPaymentCard"}
                closeOnOutsideClick={false}
                isCloseIconRequired={true}
                modalClassHeight="modalHeightEditPaymentCard"
                modalBackgroudColor="fff"
                modalTitle={
                  <div className="fontSize22 fontAntarctica text-dark text-center p-2">
                    Edit Card
                  </div>
                }
              >
                <div className="text-white">
                  <Checkout
                    editCardDetailsForStripe={editCardDetailsForStripe}
                    isCardElement={false}
                    closeModal={() => {
                      closeModal("EditPaymentCard");
                      getSavedCards();
                      //getCoverages();
                    }}
                  />
                </div>
              </DesktopCustomModal>
              {/*Delete Payment Card*/}
              <DesktopCustomModal
                isModalOpen={activeModal === "DeletePaymentCard"}
                closeModal={() => closeModal("DeletePaymentCard")}
                closeOnOutsideClick={true}
                modalCenter={true}
                modalId="DeletePaymentCard"
                modalButton=""
                modalTitle={
                  <div className="p-3 text-center text-white fontSize14">
                    Are you sure do you want to delete saved card?
                  </div>
                }
              >
                <div className="d-flex justify-content-between w-100">
                  <button
                    onClick={() => closeModal("DeletePaymentCard")}
                    className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => deletePaymentCard(editCardDetailsForStripe)}
                    className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                  >
                    {loaderForDelete ? <SpinnerLoader /> : "Confirm"}
                  </button>
                </div>
              </DesktopCustomModal>

              {/*make Default Card*/}
              <DesktopCustomModal
                isModalOpen={activeModal === "makeDefaultCard"}
                closeModal={() => closeModal("makeDefaultCard")}
                closeOnOutsideClick={true}
                modalCenter={true}
                modalId="makeDefaultCard"
                modalButton=""
                modalTitle={
                  <div className="p-3 text-center text-white fontSize14">
                    Are you sure to set this card as Default ?
                  </div>
                }
              >
                <div className="d-flex justify-content-between w-100">
                  <button
                    onClick={() => closeModal("makeDefaultCard")}
                    className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() =>
                      makeDefaultCardCard(editCardDetailsForStripe)
                    }
                    className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                  >
                    {loaderForDelete ? <SpinnerLoader /> : "Confirm"}
                  </button>
                </div>
              </DesktopCustomModal>
            </div>
          </div>
          {/*License div */}
          {/* {licenseError && <div className="fontColor4031">{licenseError}</div>} */}
          <div className="">
            licenseDetails
            <div
              className={`${
                accordionProfile?.License ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion("License", !accordionProfile.License)
                }
                className="d-flex align-items-center cursorPointer px-2"
              >
                <div>
                  <img src={licenseIcon} alt="licenseIcon" />
                </div>
                <div className="text-white mx-3 my-2">Licenses</div>
              </div>
              {accordionProfile?.License &&
                (licenseDetails?.data === null ? (
                  // <div className="text-white p-2 fontSize17">
                  //   {licenseDetails?.message}
                  // </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => openModal("DrivingLicenseModal")}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add License
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                      <div className="col-12 col-lg-6">
                        <div className=" bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                          <div className="d-flex justify-content-between">
                            <div className="p-1">
                              <div className="mb-2 fontColor5BD">
                                Driver License Number
                              </div>
                              <div className="mb-2">
                                {licenseDetails?.data?.licenseNumber}
                              </div>
                              <div className="mb-2 fontColor5BD">
                                Issuing Country
                              </div>
                              <div className="mb-2">
                                {licenseDetails?.data?.issuingCountryCode}
                              </div>
                              <div className="mb-2 fontColor5BD">
                                Valid Till
                              </div>
                              <div className="mb-2">
                                {licenseDetails?.data?.expiration}
                              </div>
                            </div>
                            <div className="px-4 p-1">
                              <div className="mb-2 fontColor5BD">
                                Issuing State/Territory
                              </div>
                              <div className="mb-2">
                                {licenseDetails?.data?.issuingState}
                              </div>
                              <div className="mb-2 fontColor5BD">
                                Issuing City ZIP Code
                              </div>
                              <div className="mb-2">
                                {" "}
                                {licenseDetails?.data?.issuingCityZipCode}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-end">
                            <div className="fontColorCE1 mx-1 cursorPointer">
                              <a
                                href={licenseDetails?.data?.licenseFrontpage} // Set this to the actual document link property
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fontColorCE1 mx-1 cursorPointer"
                              >
                                View Front Page
                              </a>
                              <a
                                href={licenseDetails?.data?.licenseBackpage} // Set this to the actual document link property
                                target="_blank"
                                rel="noopener noreferrer"
                                className="fontColorCE1 mx-1 cursorPointer"
                              >
                                View Back Page
                              </a>
                            </div>
                            <div
                              onClick={() => openModal("DeleteDrivingLicense")}
                              className="fontColorB5757 mx-1 cursorPointer"
                            >
                              Remove
                            </div>
                          </div>
                          <DesktopCustomModal
                            isModalOpen={activeModal === "DeleteDrivingLicense"}
                            closeModal={() =>
                              closeModal("DeleteDrivingLicense")
                            }
                            closeOnOutsideClick={true}
                            modalCenter={true}
                            modalId="DeleteDrivingLicense"
                            modalButton=""
                            modalTitle={
                              <div className="p-3 text-center fontSize14">
                                Are you sure do you want to delete Driver
                                License details?
                              </div>
                            }
                          >
                            <div className="d-flex justify-content-between w-100">
                              <button
                                onClick={() =>
                                  closeModal("DeleteDrivingLicense")
                                }
                                className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                              >
                                Cancel
                              </button>

                              <button
                                onClick={() =>
                                  deleteLicense(licenseDetails?.data?.id)
                                }
                                className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                              >
                                {loaderForLicense ? <SpinnerLoader /> : "Confirm"}
                              </button>
                            </div>
                          </DesktopCustomModal>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-2 px-1 d-flex justify-content-between  col-12 col-lg-6">
                    <button className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white">
                      Add TLC
                    </button>
                    <button className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white">
                      Change License
                    </button>
                  </div> */}
                  </div>
                ))}
            </div>
          </div>
          {/* driving license modal */}
          <DesktopCustomModal
            isModalOpen={activeModal === "DrivingLicenseModal"}
            closeModal={() => {
              closeModal("DrivingLicenseModal")
              setShowConditions(false)
              setDrivingLicenseDocument({
                frontPage: "",
                backPage: "",
                drivingLicenseNumber: "",
                expiryDate: "",
              })
              
              setaddressDetails({
                address: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                countryCode: "",
                secondAddressline: "",
              })
              setLicenseDetailsCheckbox(false)
              setMailingAddressCheckbox(false)

              setDrivingLicenseErrors({
                ...drivingLicenseErrors,
                drivingLicenseNumber:""
              })

              setErrors({
                country: "",
                state: "",
                city: "",
                zipCode: "",
                address: "",
              })
              
            }}
            closeOnOutsideClick={false}
            isCloseIconRequired={true}
            modalId="driverLicenseModal"
            modalCenter={true}
            modalClassHeight="modalHeightDrivingLicenseFromProfile"
            modalTitle={
              <div className="d-flex justify-content-center text-white align-items-center py-3">
                Add Driver License
              </div>
            }
            // modalButton={
            //   licenseDetails?.data === null ?  ( <div
            //     onClick={() => {
            //       openModal("DrivingLicenseModal");
            //       // getStateListAll();
            //     }}
            //   >
            //     Upload Now
            //   </div> ) : (
            //     "Verified"
            //   )
            // }
          >
            <div className="mb-2 d-flex flex-column flex-lg-row justify-content-between align-items-center text-white">
              <div className="m-1 cursor w-100">
                <FileUploader
                  onFileChange={(file) => {
                    setDrivingLicenseDocument((prevValues) => ({
                      ...prevValues,
                      frontPage: file,
                    }));
                  }}
                  handleDeleteFile={() => {
                    setDrivingLicenseDocument((prevValues) => ({
                      ...prevValues,
                      frontPage: "",
                    }));
                  }}
                  label="Front Page"
                  fileTextMessage="Drag & drop some files here, or click to select files"
                />
              </div>
              <div className="m-1 cursor w-100">
                <FileUploader
                  onFileChange={(file) => {
                    setDrivingLicenseDocument((prevValues) => ({
                      ...prevValues,
                      backPage: file,
                    }));
                  }}
                  handleDeleteFile={() => {
                    setDrivingLicenseDocument((prevValues) => ({
                      ...prevValues,
                      backPage: "",
                    }));
                  }}
                  label="Back Page"
                  fileTextMessage="Drag & drop some files here, or click to select files"
                />
              </div>
            </div>
            <div>
              <form>
                <div  
                onClick={
                  () => {
                if(showConditions){
                setShowConditions(false)
                }
                }}>
                <div className="mb-3">
                  <input
                    id="DrivingLicenseNumber"
                    placeholder="Enter Driver License Number"
                    type="text"
                    className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                    maxLength={20}
                    value={drivingLicenseDocument?.drivingLicenseNumber}
                    // onChange={(e) => {
                    //   setDrivingLicenseDocument((prevValues) => ({
                    //     ...prevValues,
                    //     drivingLicenseNumber: e.target.value,
                    //   }));
                    // }}
                    onChange={(e) => {
                      setDrivingLicenseDocument((prevValues) => ({
                        ...prevValues,
                        drivingLicenseNumber: e.target.value,
                      }));
                      const regex = /^[a-zA-Z0-9]{1,20}$/; // Only allow alphabets and numbers
                      //const regex = /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9]{1,20}$/;
                      if (!regex.test(e.target.value)) {
                        setDrivingLicenseErrors((prev) => ({
                          ...prev,
                          drivingLicenseNumber:
                            "Invalid driver License",
                        }));
                      } else {
                        setDrivingLicenseErrors((prev) => ({
                          ...prev,
                          drivingLicenseNumber: "",
                        })); // Clear error if valid
                      }
                    }}
                    onBlur={(e) => {
                      const value = e.target.value;
                      if (!value) {
                        setDrivingLicenseErrors((prev) => ({
                          ...prev,
                          drivingLicenseNumber:
                            "Driver license is required",
                        }));
                      }
                    }}
                  />
                  {drivingLicenseErrors?.drivingLicenseNumber && (
                    <div className="text-danger mt-2">
                      {drivingLicenseErrors?.drivingLicenseNumber}
                    </div>
                  )}
                </div>
                {/* <div className="mb-3 position-relative">
                  <input
                    min={new Date().toISOString().split("T")[0]}
                    id="expiryDate"
                    placeholder="Enter Expiry Date (dd/mm/yyyy)"
                    type="date"
                    className="form-control border3B3936 supportFormFocus p-2 bg-transparent outlineNone text-white"
                    value={drivingLicenseDocument?.expiryDate}
                    onChange={(e) => {
                      setDrivingLicenseDocument((prevValues) => ({
                        ...prevValues,
                        expiryDate: e.target.value,
                      }));
                    }}
                  />
                </div> */}

                <div className="mb-3 position-relative">
                  <CommonCalendar
                    onDateSelect={handleDateSelect}
                    handleBlur={() => {}}
                    defaultViewDate={new Date()}
                    minDate={new Date()}
                    maxDate=""
                    placeholder="Enter Expiry Date"
                    value={drivingLicenseDocument?.expiryDate}
                  />
                </div>

                <AddressAutocomplete
                  addressDetails={addressDetails}
                  setAddressDetails={setaddressDetails}
                  errors={errors}
                  setErrors={setErrors}
                />
                </div>
                <div className="d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    id="mailingAddress"
                    className="me-2"
                    checked={mailingAddressCheckbox}
                    onChange={() =>
                      setMailingAddressCheckbox(!mailingAddressCheckbox)
                    }
                  />
                  <label htmlFor="mailingAddress" className="text-white">
                    Use this address as my Mailing Address
                  </label>
                </div>

                <div className="d-flex align-items-center mb-3">
                  <input
                    type="checkbox"
                    id="licenseDetails"
                    className="me-2"
                    checked={licenseDetailsCheckbox}
                    onChange={() =>
                      setLicenseDetailsCheckbox(!licenseDetailsCheckbox)
                    }
                  />

                  <div
                    // onMouseEnter={() => setShowConditions(true)} // Show content on hover
                    // onMouseLeave={() => setShowConditions(false)} // Hide content when mouse leaves
                  >
                    <label htmlFor="licenseDetails" className="text-white dlPaddingRight">
                      We will use your license details to perform a driver and
                      criminal background check. For details{" "}
                      <span
                       onClick={(e) => {
                        e.preventDefault()
                        setShowConditions(!showConditions)
                      }}
                      className="cursorPointer fontColorCE1">
                        Read More
                      </span>
                    </label>
                    {showConditions && (
                      <div  onClick={() => setShowConditions(!showConditions)} className="col-md-12">
                        <div className="row">
                          <div
                            className="col-md-12 bg23 text-white p-2 px-4 borderRadius8"
                            style={{
                              height: "300px",
                              width: "400px",
                              overflow: "auto",
                              position: "absolute",
                              top: window.innerWidth > 992 ? "75%" : "100%",
                              left: "1%",
                              border: "1px solid grey",
                            }}
                          >
                            <p>
                              Joulez may need to request a consumer report,
                              drivers record report, or other background check
                              in order to approve your rental application. By
                              checking the box, you authorize Joulez and its
                              designated agents and representatives to conduct a
                              comprehensive review of your records. By checking
                              the box, you indicate that you understand the
                              scope of the consumer report may include, but is
                              not limited to: criminal history, including
                              records from any criminal justice agency in any or
                              all federal, state, or county jurisdictions; motor
                              vehicle records, including traffic citations and
                              registration; and any other public record that
                              might be relevant to determining your risk as a
                              vehicle renter.
                            </p>
                            <p>
                              If you are a California resident, you have the
                              right to receive the report Joulez reviews. By
                              sending us an e-mail at
                              reservations@drivejoulez.com, you can request to
                              receive a copy of the report Joulez reviews. Upon
                              receipt of the report, Joulez will provide a copy
                              of the report to you within three business days to
                              the e-mail address you have associated with this
                              rental.
                            </p>
                            <p>
                              By withholding your permission, no records will be
                              reviewed and your rental reservation will not be
                              processed further.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  className={`signInButton bgCE1 text-white w-100 ${
                    drivingLicenseErrors?.drivingLicenseNumber === "" &&
                    errors?.country === "" &&
                    errors?.address === "" &&
                    errors?.city === "" &&
                    errors?.state === "" &&
                    errors?.zipCode === "" &&
                    licenseDetailsCheckbox &&
                    addressDetails?.address !== "" &&
                    addressDetails?.city !== "" &&
                    addressDetails?.country !== "" &&
                    // addressDetails?.countryCode !== "" &&
                    addressDetails?.state !== "" &&
                    addressDetails?.zipCode !== "" &&
                    drivingLicenseDocument?.frontPage !== "" &&
                    drivingLicenseDocument?.backPage !== "" &&
                    drivingLicenseDocument?.drivingLicenseNumber !== "" &&
                    drivingLicenseDocument?.expiryDate !== ""
                      ? ""
                      : "disabled-class"
                  }`}
                  onClick={postDrivingLicenseData}
                >
                  {postDrivingLicenseLoading ? <SpinnerLoader /> : "Proceed"}
                </button>
              </form>
            </div>
          </DesktopCustomModal>
          {/*Coverages div */}
          {/* {coverageError && (
            <div className="fontColor4031">{coverageError}</div>
          )} */}
          <div className="pt-3">
            <div
              className={`${
                accordionProfile?.Coverages ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion("Coverages", !accordionProfile.Coverages)
                }
                className="d-flex align-items-center cursorPointer px-2"
              >
                <div>
                  <img src={profileCoverageIcon} alt="profileCoverageIcon" />
                </div>
                <div className="text-white mx-3 my-2">Coverages</div>
              </div>
              {accordionProfile?.Coverages && (
                <div>
                  <div className="row">
                    {coverages?.length !== 0 &&
                      coverages?.filter?.(coverageDetails => coverageDetails?.cardType === "credit").map((coverageDetails) => {
                        return (
                          <div
                            key={coverageDetails?.id}
                            className="col-12 col-lg-6 mb-1 px-3"
                          >
                            <div>
                              <div className=" bg262523 p-4 mb-1 fontWeight400 fontcolorFFF borderRadius12 m-1">
                                <div className="d-flex justify-content-between">
                                  <div className="p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Credit Card Number
                                    </div>
                                    <div className="mb-2">
                                      XXXX XXXX XXXX{" "}
                                      {coverageDetails?.cardNumber}
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Issuing State/Territory
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.address?.state}
                                    </div>
                                  </div>
                                  <div className="px-4 p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Insurance Provider
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.address?.country}
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Valid Till
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.expiryDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  {/* <div className="fontColorCE1 mx-1 cursorPointer">
                                  View Document
                                </div> */}
                                  {!coverageDetails?.readOnly &&
                                  <div
                                    onClick={() => {
                                      openModal("EditPaymentCard");
                                      setEditCardDetailsForStripe(
                                        coverageDetails
                                      );
                                    }}
                                    className={`fontColorB5757 mx-3 cursorPointer text-white ${coverageDetails?.readOnly ? "cardCooverageEditpaddingRight" : ""}` }
                               
                                  >
                                    Edit
                                    {/* <div className="fontColorB5757 mx-3 cursorPointer text-white">
                                  Edit  */}
                                  </div>
                                  }
                                  {
                                      !coverageDetails?.readOnly &&
                                  <div
                                    onClick={() => {
                                      openModal("DeletePaymentCard");
                                      setEditCardDetailsForStripe(
                                        coverageDetails
                                      );
                                    }}
                                    className="fontColorB5757 mx-1 cursorPointer"
                                  >
                                    Remove
                                  </div>
                      }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {/* {userInsuranceDocs?.length !== 0 &&
                      userInsuranceDocs?.map((coverageDetails) => {
                        return (
                          <div
                            key={coverageDetails?.id}
                            className="col-12 col-lg-6 mb-1 px-3"
                          >
                            <div>
                              <div className=" bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                                <div className="d-flex justify-content-between">
                                  <div className="p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Policy Number
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.documentId}
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Issuing State/Territory
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.issueState}
                                    </div>
                                  </div>
                                  <div className="px-4 p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Insurance Provider
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.provider}
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Valid Till
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.docExpDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div className="fontColorCE1 mx-1 cursorPointer">
                                    <a
                                      href={coverageDetails?.documentName} // Set this to the actual document link property
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="fontColorCE1 mx-1 cursorPointer"
                                    >
                                      View Document
                                    </a>
                                  </div>
                                  <div
                                    onClick={() => {
                                      openModal("DeletePaymentCard");
                                      setEditCardDetailsForStripe(
                                        coverageDetails
                                      );
                                    }}
                                    className="fontColorB5757 mx-1 cursorPointer"
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                    {userInsuranceDocs?.length !== 0 &&
                      userInsuranceDocs?.map((coverageDetails) => {
                        return (
                          <div
                            key={coverageDetails?.id}
                            className="col-12 col-lg-6 mb-1 px-3"
                          >
                            <div>
                              <div className=" bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                                <div className="d-flex justify-content-between">
                                  <div className="p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Policy Number
                                    </div>
                                    <div className="mb-2 d-flex flex-column flex-lg-row align-items-lg-center">
                                      <div>{coverageDetails?.documentId}</div>
                                      <div className="mt-2 mt-lg-0 mx-0 mx-lg-2">
                                      {coverageDetails?.isAxleVerified && <span>
                                        <img src={axleSuccessLogo} alt="axleSuccessLogo"  title="Axle Verified"/>
                                     </span>}
                                     </div>
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Issuing State/Territory
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.issueState}
                                    </div>
                                  </div>
                                  <div className="px-4 p-1">
                                    <div className="mb-2 fontColor5BD">
                                      Insurance Provider
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.provider}
                                    </div>
                                    <div className="mb-2 fontColor5BD">
                                      Valid Till
                                    </div>
                                    <div className="mb-2">
                                      {coverageDetails?.docExpDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <div className="fontColorCE1 mx-1 cursorPointer">
                                    <a
                                      href={coverageDetails?.documentName} // Set this to the actual document link property
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="fontColorCE1 mx-1 cursorPointer"
                                    >
                                      View Document
                                    </a>
                                    {/* View Document */}
                                  </div>
                                  <div
                                    onClick={() => {
                                      openModal("deleteCoverage");
                                      setSingleUserCoverage(coverageDetails);
                                    }}
                                    className="fontColorB5757 mx-1 cursorPointer"
                                  >
                                    Remove
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => openModal("AddNewCard")}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add Credit Card
                      </button>
                    </div>
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => openModal("AddInsurance")}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add Insurance
                      </button>
                    </div>
                    {isAxleNeed === "true" && <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => getAxleLink()}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add Insurance Through Axle
                      </button>
                    </div>}
                  </div>
                </div>
              )}
            </div>
            {/*Add Insurace modal */}
            <DesktopCustomModal
              isModalOpen={activeModal === "AddInsurance"}
              closeModal={() => closeModal("AddInsurance")}
              closeOnOutsideClick={false}
              modalCenter={true}
              isCloseIconRequired={true}
              modalId="AddInsurance"
              modalButton=""
              modalTitle={
                <div className="text-white fontSize17">
                  Insurance Details
                </div>
              }
            >
              <div className="text-white">
                <div className="mt-2">
                  <div>
                    <FileUploader
                      label={
                        <div className="fontAntarctica fontSize15">
                          Update New Coverage
                        </div>
                      }
                      fileTextMessage={
                        <div className="borderRadius8">
                          <img src={insuranceDocIcon} alt="insuranceDocIcon" />
                        </div>
                      }
                      removePreviewImage={true}
                      onFileChange={(file) => {
                        setInsuranceInputValues((prevValues) => ({
                          ...prevValues,
                          documentName: file, // Store the compressed file
                        }));
                      }}
                    />
                  </div>
                  <div className="pt-3 input-group w-100">
                    <form
                      className="w-100"
                      onSubmit={handleSubmitInsuranceDetails}
                    >
                      <div className="mb-3">
                        <input
                          id="policyNumber"
                          placeholder="Policy Number"
                          type="text"
                          className="form-control supportFormFocus p-2 mb-3"
                          onChange={handleChangeInsuranceDetails}
                          value={insuranceInputValues?.policyNumber}
                        />
                        <select
                          id="issueState"
                          onChange={handleChangeInsuranceDetails}
                          className="form-control supportFormFocus p-2 mb-3"
                          value={insuranceInputValues?.issueState}
                        >
                          <option value="">Issue State</option>
                          {stateList[0]?.states?.map((item) => (
                            <option key={item?.name} value={item?.stateCode}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <input
                          id="provider"
                          placeholder="Provider"
                          type="text"
                          className="form-control supportFormFocus p-2 mb-3"
                          onChange={handleChangeInsuranceDetails}
                          value={insuranceInputValues?.provider}
                        />
                        {/* <input
                          id="expiryDate"
                          placeholder="Expiry Date"
                          min={new Date().toISOString().split("T")[0]}
                          type="date"
                          className="form-control supportFormFocus p-2"
                          onChange={handleChangeInsuranceDetails}
                          value={insuranceInputValues?.expiryDate}
                        /> */}
                        <DateOfBirthPicker
                          id="expiryDate"
                          minDate={
                              new Date()
                            }
                            value={
                              insuranceInputValues?.expiryDate
                            }
                            placeholder="Policy Expiration Date"
                            onDateSelect={handleChangeInsuranceDateDetails}
                          />
                      </div>
                      <button
                        disabled={
                          !(
                            insuranceInputValues?.documentName &&
                            insuranceInputValues?.policyNumber &&
                            insuranceInputValues?.issueState &&
                            insuranceInputValues?.provider &&
                            insuranceInputValues?.expiryDate
                          )
                        }
                        type="submit"
                        className={`${
                          insuranceInputValues?.documentName !== "" &&
                          insuranceInputValues?.policyNumber !== "" &&
                          insuranceInputValues?.issueState !== "" &&
                          insuranceInputValues?.provider !== "" &&
                          insuranceInputValues?.expiryDate !== ""
                            ? ""
                            : "opacity disabled"
                        } w-100 mt-2 px-3 py-2 text-white bgCE1 borderRadius8 border-0 fontSize13`}
                      >
                        Upload
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </DesktopCustomModal>
            {/*delete coverage modal */}
            <DesktopCustomModal
              isModalOpen={activeModal === "deleteCoverage"}
              closeModal={() => closeModal("deleteCoverage")}
              closeOnOutsideClick={true}
              modalCenter={true}
              modalId="deleteCoverage"
              modalButton=""
              modalTitle={
                <div className="p-3 text-center text-white fontSize14">
                  Are you sure to delete Insurance document ?
                </div>
              }
            >
              <div className="d-flex justify-content-between w-100">
                <button
                  onClick={() => closeModal("deleteCoverage")}
                  className="accordionProfileButtons  m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgB5757 opacity-75 text-white"
                >
                  Cancel
                </button>

                <button
                  onClick={() => {
                    handleRemoveCoverage(singleUserCoverage);
                  }}
                  className="accordionProfileButtons m-1 border-0 outlineNone borderRadius8  py-3 cursorPointer fontAntarctica fontSize13 bgCE1 opacity-75 text-white"
                >
                  {loaderForDelete ? <SpinnerLoader /> : "Confirm"}
                </button>
              </div>
            </DesktopCustomModal>
          </div>
          {/*Social div */}
          <div className="pt-3">
            <div
              className={`${
                accordionProfile?.Social ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion("Social", !accordionProfile.Social)
                }
                className="d-flex justify-content-between  align-items-center cursorPointer px-2"
              >
                <div className="d-flex align-items-center">
                  <div>
                    <img src={profileSocialIcon} alt="addressIcon" />
                  </div>
                  <div className="text-white mx-3 my-2">Social</div>
                </div>
                {accordionProfile?.Social && (
                  <div
                    className="d-flex d-lg-none fontColorB5757 mx-3 cursorPointer text-white"
                    onClick={() => {
                      openModal("SocialModal");
                      setEditUserDetails({ ...userDetails });
                    }}
                  >
                    Edit
                  </div>
                )}
              </div>
              {accordionProfile?.Social && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column flex-lg-row fontSize13 col-12 col-lg-8">
                    <div className="border680 bg23 px-4 py-3 borderRadius8 m-1 d-flex align-items-center cursor">
                      <img src={facebookIcon} alt="facebookIcon" />
                      <Link
                      to="https://www.facebook.com/"
                      className="text-white mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                        {userDetails?.fbId}
                      </Link>
                    </div>
                    <div className="border680 bg23 px-4 py-3 borderRadius8 m-1 d-flex align-items-center cursor">
                      <img src={twitterIcon} alt="twitterIcon" />
                      <Link
                      to="https://www.twitter.com/"
                      className="text-white mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                        {userDetails?.twitterId}
                      </Link>
                    </div>
                    <div className="border680 bg23 px-4 py-3 borderRadius8 m-1 d-flex align-items-center cursor">
                      <img src={instaIcon} alt="instaIcon" />
                      <Link
                      to="https://www.instagram.com/"
                      className="text-white mx-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      >
                        {userDetails?.instaId}
                      </Link>
                    </div>
                  </div>
                  <div
                    className="d-none d-lg-flex fontColorB5757 mx-3 cursorPointer text-white"
                    onClick={() => {
                      openModal("SocialModal");
                      setEditUserDetails({ ...userDetails });
                    }}
                  >
                    Edit
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*Social Modal */}
          <DesktopCustomModal
            isModalOpen={activeModal === "SocialModal"}
            closeModal={() => closeModal("SocialModal")}
            closeOnOutsideClick={false}
            modalCenter={true}
            modalId="SocialModal  "
            modalButton=""
            isCloseIconRequired={true}
            modalTitle={
              <div className="p-3 fontSize17 text-center text-white">
                Social Media Details
              </div>
            }
          >
            <div className="px-2 input-group w-100 d-flex flex-column">
              <div className="mb-2">
                <input
                  id="fbId"
                  placeholder="Facebook ID"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangeSocial} //user/socialId/{userId}
                  value={socialID?.fbId}
                />
              </div>
              <div className="mb-2">
                <input
                  id="instaId"
                  placeholder="Instagram ID"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangeSocial}
                  value={socialID?.instaId}
                />
              </div>
              <div className="mb-2">
                <input
                  id="twitterId"
                  placeholder="Twitter ID"
                  type="text"
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangeSocial}
                  value={socialID?.twitterId}
                />
              </div>
              <div>
                <button
                  onClick={() => handleSubmitSocialIDs(userDetails?.id)}
                  className={`w-100 mt-3 borderRadius8 bgCE1 text-white p-2 border-0 outlineNone`}
                >
                  {loaderForDelete ? <SpinnerLoader /> : "Save"}
                </button>
              </div>
            </div>
          </DesktopCustomModal>
          {/*Family div */}
          <div className="pt-3">
            <div
              className={`${
                accordionProfile?.Family ? "bg191916" : "border3B3936"
              } p-2 borderRadius12`}
            >
              <div
                onClick={() =>
                  handleCloseAccordion("Family", !accordionProfile.Family)
                }
                className="d-flex align-items-center cursorPointer px-2"
              >
                <div>
                  <img src={profileFamilyIcon} alt="profileFamilyIcon" />
                </div>
                <div className="text-white mx-3 my-2">Family</div>
              </div>
              {accordionProfile?.Family && (
                <div>
                  <div className="row">
                    {familyDetails?.length !== 0 &&
                      familyDetails?.map((familyDetail) => {
                        return (
                          <div
                            key={familyDetail?.id}
                            className="col-12 col-lg-6 mb-1 px-3"
                          >
                            <div>
                              <div className=" bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                                <div className="d-flex justify-content-between">
                                  <div className="p-1">
                                    <div className="mb-4">Contact</div>
                                    <div className="mb-2">
                                      {familyDetail?.firstName}{" "}
                                      {familyDetail?.lastName}
                                    </div>
                                    <div className="mb-2">
                                      {familyDetail?.relationship}
                                    </div>
                                  </div>
                                  <div className="px-4 p-1 d-flex flex-column align-items-end">
                                    <div className="d-flex  mb-2">
                                      {familyDetail?.emergency === 1 && (
                                        <div className="borderRadius8 bgCE133">
                                          <div className=" d-flex justify-content-between align-items-center px-2">
                                            <div>
                                              <img
                                                src={emergencyContactIcon}
                                                alt="emergencyContactIcon"
                                              />
                                            </div>
                                            <div className="fontColorCE1 mx-2 fontSize13 fontAntarctica mt-1">
                                              Emergency Contact
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      <img
                                        className="mx-2 cursorPointer"
                                        src={profileMoreIcon}
                                        alt="moreIcon"
                                        onClick={() => {
                                          openModal("AddFamilyContact");
                                          setEmergencyDetails({...familyDetail, contact: familyDetail?.countryCode + familyDetail?.contact});
                                          setIsEdit(true);
                                        }}
                                      />
                                    </div>
                                    <div className=" mt-4 mb-2">
                                      +{familyDetail?.countryCode}{" "}
                                      {formatPhoneNumber(familyDetail?.contact)}
                                      {/* {familyDetail?.contact} */}
                                    </div>
                                    <div className="mb-2">
                                      {familyDetail?.email}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button
                        onClick={() => openModal("AddFamilyContact")}
                        className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100"
                      >
                        Add a contact
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/*Add Family Contact Modal */}
          <DesktopCustomModal
            isModalOpen={activeModal === "AddFamilyContact"}
            closeModal={() => {
              closeModal("AddFamilyContact")

              setEmergencyDetailsError({
                contact: "",
                email: "",
                firstName: "",
                lastName: "",
                relationship: "",
              })

              setEmergencyDetails({
                emergency: 0,
                id: 0,
                userId: userProfile?.id,
                contact: "",
                email: "",
                firstName: "",
                lastName: "",
                relationship: "Select Relation",
                countryCode: "",
                phoneNumberCountryCode: "",
              })
            }}
            closeOnOutsideClick={false}
            modalCenter={true}
            modalId="AddFamilyContact"
            modalButton=""
            isCloseIconRequired={true}
            modalTitle={
              <div className="p-3 fontSize17 text-center text-white">
                Family Contact Details
              </div>
            }
          >
            <div className="px-2 input-group w-100">
              <div className="w-100">
                <div className="mb-3">
                  <input
                    id="firstName"
                    placeholder="First Name"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3"
                    maxLength={50}
                    onChange={handleEmergencyDetails}
                    onBlur={() => validateEmergencyField("firstName")}
                    value={emergencyDetails?.firstName}
                  />
                </div>
                {emergencyDetailsError.firstName && (
                  <div className="text-danger">
                    {emergencyDetailsError.firstName}
                  </div>
                )}
                <div className="mb-3">
                  <input
                    id="lastName"
                    placeholder="Last Name"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3"
                    maxLength={50}
                    onChange={handleEmergencyDetails}
                    onBlur={() => validateEmergencyField("lastName")}
                    value={emergencyDetails?.lastName}
                  />
                </div>
                {emergencyDetailsError.lastName && (
                  <div className="text-danger">
                    {emergencyDetailsError.lastName}
                  </div>
                )}
                <div className="mb-3 borderRadius8 p-1">
                  <PhoneInput
                    value={emergencyDetails?.contact}
                    country={"us"}
                    placeholder="Phone Number"
                    onChange={handlePhoneChange}
                  />
                  <div className="fontColor4031">
                    {" "}
                    {emergencyDetailsError?.contact &&
                      emergencyDetailsError?.contact}
                  </div>
                </div>
                <div>
                  <select
                    id="relationship"
                    value={emergencyDetails.relationship}
                    onChange={handleEmergencyDetails}
                    onBlur={() => validateEmergencyField("relationship")}
                    placeholder="Relationship"
                    className="w-100 p-2 mb-3 cursorPointer outlineNone text-white bg23 supportFormFocus borderRadius8"
                  >
                    <option value="Select Relation">Select Relation</option>
                    <option value="Father">Father</option>
                    <option value="Husband">Husband</option>
                    <option value="Wife">Wife</option>
                    <option value="Mother">Mother</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Other">Other</option>
                    <option value="Partner">Partner</option>
                  </select>
                </div>
                {emergencyDetailsError?.relationship && (
                  <div className="text-danger">
                    {emergencyDetailsError?.relationship}
                  </div>
                )}
                <div className="mb-3">
                  <input
                    id="email"
                    placeholder="Email"
                    type="text"
                    className="form-control supportFormFocus p-2 mb-3"
                    onChange={handleEmergencyDetails}
                    onBlur={() => validateEmergencyField("email")}
                    value={emergencyDetails?.email}
                  />
                </div>
                {emergencyDetailsError.email && (
                  <div className="text-danger">
                    {emergencyDetailsError.email}
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  <button
                    onClick={() => {
                      closeModal("AddFamilyContact");
                    }}
                    className={`w-100 mt-2 px-3 py-2 text-white bg3B3936 borderRadius8 border-0 fontSize13`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      handleSubmitEmergencyDetails(
                        isEdit ? "put" : "post",
                        emergencyDetails
                      )
                    }
                    className={`
                    ${
                      (hasEmeregencyErrors ||
                        emergencyDetails?.firstName === "" ||
                        emergencyDetails?.lastName === "" ||
                        emergencyDetails?.email === "" ||
                        emergencyDetails?.contact === "" ||
                        emergencyDetails?.relationship === "Select Relation") &&
                      "disabled-class"
                    } 
                     w-100 mt-2 mx-1 px-3 py-2 text-white bgCE1 borderRadius8 border-0 fontSize13`}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </DesktopCustomModal>
          <div className="d-flex flex-column flex-lg-row mt-3 fontSize13 fontWeight400 fontAntarctica">
            {userProfile?.loginType === "CUSTOM" && !userProfile?.blocked && 
            <button onClick={
              () => {
               openModal("ChangePassword");
            }} className="col-12 col-lg-4 changeAndSignOutBtn mb-2 mb-lg-0 bg3B3936 fontcolorFFF br8 border234D">
              <img src={ChangePasswordLogo} alt="ChangePasswordLogo" className="me-2 mx-1" />
              Change Password
            </button>}
            <button onClick={handleCustomLogout} className="col-12 col-lg-4 changeAndSignOutBtn mx-0 mx-lg-2 bg3B3936 fontColorB5757 br8 border234D">
            <img src={signoutLogo} alt="signoutLogo" className="me-2 mx-0 mx-lg-1 profileSignoutLogo" />
             Sign Out
            </button>
          </div>
          <DesktopCustomModal
          isModalOpen={
            activeModal === "ChangePassword"
          }
          closeModal={ () => {
            closeModal("ChangePassword")
            setChangePassword({
              currentPassword:"",
              newPassword:"",
              confirmPassword:""
            })
            setChangePasswordError({
              currentPassword:"",
              newPassword:"",
              confirmPassword:""
            })
            setShowPassword({
              currentPassword:false,
              newPassword:false,
              confirmPassword:false
            })
          }}
          closeOnOutsideClick={false}
            modalCenter={true}
            modalId="ChangePassword"
            modalButton=""
            isCloseIconRequired={true}
            modalTitle={
              <div className="p-3 fontSize17 text-center text-white">
                Change Password
              </div>
            }
          >
          <div>
          <div className="px-2 input-group w-100 d-flex flex-column">
              <div className="mb-2">
                <input
                  name="currentPassword"
                  placeholder="Current Password"
                  type={showPassword?.currentPassword ? "text" : "password"}
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangePassword}
                  value={changePassword?.currentPassword}
                />
                 <div
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword((prev) => ({
                      ...prev,
                      currentPassword: !prev?.currentPassword
                    }));
                  }}
                >
                  {showPassword?.currentPassword ? (
                    <img
                      src={showPasswordIcon}
                      className="passwordIcon cursor mx-1"
                      alt="hidePasswordIcon"
                    />
                  ) : (
                    <img
                      src={hidePasswordIcon}
                      className="passwordIcon cursor mx-1"
                      alt="hidePasswordIcon"
                    />
                  )}
                </div>
                <p className="text-danger">{changePasswordError?.currentPassword}</p>
              </div>
              <div className="mb-2 position-relative">
                <input
                  name="newPassword"
                  placeholder="New Password"
                  type={showPassword?.newPassword ? "text" : "password"}
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangePassword}
                  value={changePassword?.newPassword}
                />
              <div onClick={() => {
                setShowPassword((prev) => ({
                  ...prev,
                  newPassword: !prev?.newPassword
                }))
              }} className="newProfilePasswordIcon">
                <img src={showPassword?.newPassword ? showPasswordIcon : hidePasswordIcon} alt="newPasswordIcon" />
              </div>
                 <p className="text-danger">{changePasswordError?.newPassword}</p>
              </div>
              <div className="mb-2 position-relative">
                <input
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type={showPassword?.confirmPassword ? "text" : "password"}
                  className="form-control supportFormFocus p-2 mb-3"
                  maxLength={30}
                  onChange={handleChangePassword}
                  value={changePassword?.confirmPassword}
                />
                <div onClick={() => {
                setShowPassword((prev) => ({
                  ...prev,
                  confirmPassword: !prev?.confirmPassword
                }))
              }} className="newProfilePasswordIcon">
                <img src={showPassword?.confirmPassword ? showPasswordIcon : hidePasswordIcon} alt="newPasswordIcon" />
              </div>
                 <p className="text-danger">{changePasswordError?.confirmPassword}</p>
              </div>
              <div>
                <button
                  onClick={handleSubmitNewPassword}
                  className={`w-100 mt-1 borderRadius8 bgCE1 text-white p-2 border-0 outlineNone ${
                    changePassword?.currentPassword?.length >= 8 &&
                    changePassword?.newPassword?.length >= 8 && 
                    changePassword?.confirmPassword?.length >= 8 &&
                    changePasswordError?.currentPassword === "" &&
                    changePasswordError?.newPassword === ""  &&
                    changePasswordError?.confirmPassword === "" 
                    ? "" : "disabled-class"
                  }`}
                >
                  {loaderForChangePassword ? <SpinnerLoader /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
          </DesktopCustomModal>
          {/*Tax Div */}
          {/* <div className="pt-3">
          <div
            className={`${
              accordionProfile?.Documents ? "bg191916" : "border3B3936"
            } p-2 borderRadius12`}
          >
            <div
              onClick={() =>
                handleCloseAccordion("Documents", !accordionProfile.Documents)
              }
              className="d-flex align-items-center cursorPointer px-2"
            >
              <div>
                <img src={profileDocumentIcon} alt="profileDocumentIcon" />
              </div>
              <div className="text-white mx-3 my-2">Documents</div>
            </div>
            <div>
              {accordionProfile?.Documents && (
                <div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-2">
                      <div className=" bg262523 p-4 mb-1 fontcolorFFF borderRadius12 m-1">
                        <div>Tax Exempt Certificate</div>
                        <div className="d-flex justify-content-end">
                          <div className="fontColorCE1 mx-1 cursorPointer">
                            View Document
                          </div>
                          <div className="fontColorB5757 mx-3 cursorPointer text-white">
                            Edit
                          </div>
                          <div className="fontColorB5757 mx-1 cursorPointer">
                            Remove
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-1 paddingProfileButtons">
                      <button className="accordionProfileButtons border-0 outlineNone borderRadius8 py-3 cursorPointer fontAntarctica fontSize13 bg3B3936 text-white w-100">
                        Upload a Document
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div> */}
          {/*Claims Div */}
          {/* <div className="pt-3">
          <div
            className={`${
              accordionProfile?.Claims ? "bg191916" : "border3B3936"
            } p-2 borderRadius12`}
          >
            <div
              onClick={() =>
                handleCloseAccordion("Claims", !accordionProfile.Claims)
              }
              className="d-flex align-items-center cursorPointer px-2"
            >
              <div>
                <img src={profileClaimsIcon} alt="profileClaimsIcon" />
              </div>
              <div className="text-white mx-3 my-2">Claims</div>
            </div>
            {accordionProfile?.Claims && (
              <div>
                <div className="row">
                  {familyDetails?.map((familyDetail) => {
                    return (
                      <div
                        key={familyDetail?.id}
                        className="col-12 col-lg-6 mb-1 px-3"
                      >
                        <div>
                          <div className=" bg262523 p-4 mb-1 fontcolorFFF fontSize13 fontAntarctica borderRadius12 m-1">
                            <div className="d-flex justify-content-between">
                              <div className="p-1">
                                <div className="mb-2">Claim ID</div>
                                <div className="mb-2">Booking ID</div>
                                <div className="mb-2">Cause</div>
                                <div className="mb-2">Status</div>
                              </div>
                              <div className="px-4 p-1 d-flex flex-column align-items-end">
                                <div className="mb-2">JC09876</div>
                                <div className="mb-2">6745627828</div>
                                <div className="mb-2">Collision</div>
                                <div
                                  className={`mb-2 ${
                                    familyDetail?.emergency === 1
                                      ? "fontC885"
                                      : "fontColor4031"
                                  } fontWeight400`}
                                >
                                  {familyDetail?.emergency === 1
                                    ? "Closed"
                                    : "Open"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
