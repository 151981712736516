import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../Store/pageSlice';

const TermsAndConditions = () => {
    const dispatch = useDispatch()
    const currentYear = new Date().getFullYear();
    const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        dispatch(setCurrentPage("terms-and-conditions"));
      }, [dispatch]);

    return (
        <div className={`${
            isDarkMode ? "darkGreyMode" : "lightGreyMode"
          } pt-5`}>
            <div className={`container footer_adjust terms `}>
                <div className="row">
                    <div className="col-md-12 mt-3 terms-section">
                        <h2 className="text-center mb-3 title">TERMS & CONDITIONS</h2>
                        <br />
                        <p><strong>THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
                            COURT OR JURY TRIALS OR CLASS ACTIONS AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
                            CAREFULLY REVIEW SECTION 13 BEFORE YOU AGREE TO THESE TERMS.</strong></p>
                        <p className='fontColorFCC'>These Terms and Conditions (these “terms”) contain the general terms and conditions that apply to customers,
                            users,
                            and visitors (collectively, “users,” or “you”) of Joulez Inc.’s (“Joulez” or “our”) online and mobile services,
                            including the website www.drivejoulez.com, all services made available through the website, and all other
                            applications, digital resources, or communications offered by Joulez (collectively, the “Services”). Joulez is a
                            corporation organized under the laws of the State of South Dakota having its principal place of business in the
                            State of New York.</p>
                        <p className='fontColorFCC'>These terms are an agreement between you and Joulez. <strong><u>Please read these terms carefully as they
                            govern your conduct when using the Services.</u></strong> These terms are incorporated into and are
                            supplemental to Joulez’s Privacy Policy, as well as any other specific, written agreements you may have with
                            Joulez with respect to a service. Unless you have a specific, written agreement with Joulez providing otherwise,
                            each of these terms apply to you. </p>
                        <p className='fontColorFCC'>By using the Services, such as by accessing the website or other applications, you accept and agree to be bound
                            by and abide by these terms. By using these Services you represent and warrant (a) you are 18 years of age or
                            older; (b) you have good right, title, and authority to enter into these terms on your own behalf and on behalf
                            of any entity or person whom you purport to represent (which entity shall be considered part of you for purposes
                            of these terms); (c) the use of these Services and the entering into of these terms has been duly authorized and
                            approved by any entity or person whom you represent; and (d) effective as of the date you first use the
                            Services, these terms constitute valid and binding obligations enforceable against you as described herein.&nbsp;
                            <strong><u>If you do not agree to these terms or if you are unable to make the foregoing representations and
                                warranties, you must not use the Services.</u></strong>
                        </p>
                        <p className='fontColorFCC'>Please note the information made available through the Services has been compiled from both internal and
                            external sources. Such information may include data prepared by third parties and provided by us or obtained
                            from sources we believe to be reliable, but we cannot and do not guarantee the accuracy, timeliness, or
                            completeness of such information for any particular purpose. Such information is inherently subject to change
                            without notice and may become dated. You agree that we will not be responsible for any loss you experience as a
                            result of your reliance on such information. We use reasonable efforts to make sure the information made
                            available through our Services is accurate and up to date, however, we do not represent or warrant that the
                            information contained therein is accurate, compete, or fit for your particular purpose, and you should always
                            verify any information obtained through our Services before you act upon it. Please see Section 9 below for more
                            information.</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">1. GRANT OF LICENSE</h3>
                        <p className='fontColorFCC'>The Services, including all displays, images, video, and audio, as well as the design, selection, and
                            arrangement thereof, are owned or licensed and provided by Joulez. </p>
                        <p className='fontColorFCC'>Joulez hereby grants you a non-exclusive, non-transferable, non-sublicensable, worldwide license to use the
                            Services subject to the restrictions in these terms. Joulez expressly reserves any rights not expressly granted
                            herein. You are solely responsible for any hardware, software, and telecommunications necessary to access the
                            Services and Joulez makes no warranty or guarantee that the Services will always be available or will not
                            change. </p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">2. LICENSE AND USE RESTRICTIONS </h3>
                        <p className='fontColorFCC'>Except as expressly authorized under these terms or in a separate written agreement between you and Joulez, you
                            may not:</p>
                        <ul className='fontColorFCC'>
                            <li>copy, modify, or create derivative works of the Services, in whole or in part;</li>
                            <li>rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make
                                available the Services;</li>
                            <li>reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to
                                any software component of the Services, in whole or in part;</li>
                            <li>remove any proprietary notices from the Services;</li>
                            <li>use the Services in any manner or for any purpose that infringes, misappropriates, or otherwise violates any
                                intellectual property right or other right of any person, or that violates any applicable law;</li>
                            <li>combine or integrate the Services with any software, technology, services, or materials;</li>
                            <li>design or permit any applications to disable, override, or otherwise interfere with any Joulez’s
                                communications to end users, consent screens, user settings, alerts, warning, or the like;</li>
                            <li>use the Services to replicate or attempt to replace the user experience of the Services in another
                                application; </li>
                            <li>attempt to cloak or conceal your identity when requesting authorization to use the Services; </li>
                            <li>use the Services in connection with or to promote any products, services, or materials that constitute,
                                promote, or are used primarily for the purpose of dealing in spyware, adware, or other malicious programs or
                                code, counterfeit goods, items subject to U.S. embargo, unsolicited mass distribution of email (“spam”),
                                multi-level marketing proposals, hate materials, hacking, surveillance, interception, or descrambling
                                equipment, libelous, defamatory, obscene, pornographic, abusive, or otherwise offensive content, stolen
                                products, and items used for theft, hazardous materials, or any illegal activities; </li>
                            <li>use the Services to collect, disclose, or otherwise process another’s personal information without their
                                consent to do so or in any other way in violation of applicable law; or</li>
                            <li>use the Services in any manner that we determine in our reasonable discretion is abusive or harmful. </li>
                        </ul>

                        <p className='fontColorFCC'>You also agree that you shall only use the Services in a manner that complies with all applicable laws in the
                            jurisdictions in which you use the Services, and that you shall not violate or infringe the rights of any third
                            party. Any such forbidden use shall immediately and automatically terminate your license to use the Services
                            without notice. </p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">3. TERM AND TERMINATION </h3>
                        <p className='fontColorFCC'>These terms shall apply so long as you are using the Services. We may terminate your access to the Services at
                            any time for any or no reason, unless otherwise provided in an express, written agreement between you and
                            Joulez. Upon termination of your access to the Services, you shall immediately discontinue use of the Services.
                            These terms shall survive termination of the Services to the extent applicable. </p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">4. USERNAME AND PASSWORD </h3>
                        <p className='fontColorFCC'>You may be required to provide certain registration details or other information and become a “Registered User”
                            before accessing certain features of the Services. It is a condition of your use of these Services as a
                            Registered User to ensure all the information you provide is correct, current, and complete. The information you
                            provide will be treated and used in accordance with our Privacy Policy. If you choose or are provided with a
                            username, password, or any other piece of information as part of our security procedures, you must treat such
                            information as confidential and you must not disclose it to any other person or entity. You acknowledge that
                            your account is personal to you and agree not to provide any other person with access to the Services or
                            portions of it using your username, password, or other security information. You must notify us immediately of
                            any unauthorized access to or use of your username or password or any other breach of security. You must exit
                            from your account at the end of each session and use caution when accessing your account from a public or shared
                            computer so that others are not able to view or record your password or other personal information.</p>
                        <p className='fontColorFCC'>We have the right to disable any username, password, or other identifier, whether chosen or provided, at any
                            time in our sole discretion for any or no reason, including, without limitation, if, in our opinion, you have
                            violated any provision of these terms.</p>
                    </div>


                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">5. MODIFICATION, DISCONTINUATION, OR SUSPENSION OF SERVICES</h3>
                        <p className='fontColorFCC'>Joulez may, in its sole discretion and at any time, modify or discontinue the Services in whole or in part. For
                            modified Services, you may be requested to accept a modification or new terms when you log in to your account.
                            If you do not accept the amended terms, you will not have access to the Services. </p>
                        <p className='fontColorFCC'>Joulez may discontinue its Services at any time without notice. Any dated information is published as of its
                            date only and we do not undertake any obligation or responsibility to update or amend any such information. </p>
                        <p className='fontColorFCC'>Joulez may temporarily suspend access to the Services under certain circumstances, including, without
                            limitation: (i) Joulez reasonably determines that: (A) there is a threat or attack on any of our intellectual
                            property; (B) your use of our Services disrupts, or poses a security risk, to our intellectual property; (C) you
                            use our Services for fraudulent or illegal activities, of for any other purpose prohibited by these terms; (D)
                            subject to applicable law, if you or the entity with which we have agreed to provide the Services to you has
                            ceased to continue its business in the ordinary course, made an assignment for the benefit of creditors or
                            similar disposition of its assets, or become the subject of any bankruptcy, reorganization, liquidation,
                            dissolution, or similar proceeding; or (E) our provision of the Services is prohibited by applicable law; or
                            (ii) any vendor of Joulez has suspended or terminated our access to or use of any third-party services or
                            products required to enable your access the Services; or (iii) if the service is one available only to paying
                            subscribers, you or the entity with which we have agreed to provide the Services to you fails to make payment
                            when due. </p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">6. PROPRIETARY RIGHTS </h3>
                        <p className='fontColorFCC'>The Services, including, without limitation, any of our Internet operations, design, content, hardware designs,
                            algorithms, software (in source and object forms), user interface designs, other templates and designs,
                            including, without limitation, templates and designs of goods offered or sold, algorithms, architecture, className
                            libraries, and documentation (both printed and electronic), know-how, good will, moral rights, trade secrets and
                            all other intellectual property rights throughout the world, and any derivative works, improvements,
                            modifications, enhancements or extensions thereof shall remain the sole and exclusive property of Joulez, and
                            you shall have no interest in them whatsoever.</p>
                        <p className='fontColorFCC'>All right, title, and interest in and to the Services are and will remain our exclusive property. Our
                            intellectual property rights in the Services are protected by copyright and trademark laws of the United States
                            and international treaties, as well as other domestic and international laws. Nothing in these terms gives you a
                            right to use our trademarks, logos, domain names, other intellectual property, and other distinctive brands.
                            There is also nothing in these terms that gives you the right to copy the Services, to copy any feature or
                            portion of the services, or create a derivative of the services, all of which is expressly prohibited. Unless
                            prohibited by law, any feedback, comments, or suggestions you may provide regarding the Services are entirely
                            voluntary and we will be free to use your feedback, comments, or suggestions as we see fit and without any
                            obligation or approval to you whatsoever.</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">7. USER-GENERATED CONTENT; USE BY OTHERS; SUBMISSIONS</h3>
                        <p className='fontColorFCC'>If you submit, upload or post any comments, ideas, suggestions, information, files, images, photos, videos or
                            other materials using the Services (“ User-Generated Content”), you agree not to provide any User-Generated
                            Content that (1) is defamatory, abusive, libelous, unlawful, obscene, threatening, harassing, fraudulent,
                            pornographic, or harmful, or that could encourage criminal or unethical behavior, (2) violates or infringes the
                            privacy, copyright, trademark, trade dress, trade secrets or intellectual property rights of any person or
                            entity, or (3) contains or transmits a virus or any other harmful component. You agree not to contact other site
                            users through unsolicited email, telephone calls, mailings or any other method of communication. Joulez shall
                            have a royalty-free, irrevocable, transferable right and license to use the User-Generated Content however
                            Joulez desires, including without limitation, to copy, modify, delete in its entirety, adapt, publish,
                            translate, create derivative works from and/or sell and/or distribute such User-Generated Content and/or
                            incorporate such User-Generated Content into any form, medium or technology throughout the world. We are and
                            shall be under no obligation (1) to maintain any User-Generated Content in confidence; (2) to pay to you any
                            compensation for any User-Generated Content; or (3) to respond to any User-Generated Content.</p>
                        <p className='fontColorFCC'>When using the Services and submitting User-Generated Content, you must:</p>
                        <ul className='fontColorFCC'>
                            <li>Not use any User-Generated Content that belongs to other people and pass it off as your own; this includes
                                any content that you might have found elsewhere on the Internet. For example, your User-Generated Content
                                should not contain any visible logos, phrases, or trademarks that belong to third parties. If anyone
                                contributes to your User-Generated Content or has any rights to your User-Generated Content, or if anyone
                                appears in the User-Generated Content, then you must also have their permission to submit such User-Generated
                                Content to us. If you choose to submit photos, link to embedded videos, or include other images of real
                                people, then make sure they are of you or of you and someone you know – and only if you have their permission
                                to submit them.</li>
                            <li>Not impersonate anyone else. You agree not to use a false email address, impersonate any person or entity,
                                or otherwise mislead as to the origin of any User-Generated Content.</li>
                            <li>Act Appropriately. Be respectful of other's opinions and comments so we can continue to build Communities
                                for everyone to enjoy. If you think your User-Generated Content might offend someone or be embarrassing to
                                someone, then chances are it probably will and it doesn’t belong on the Services. Cursing, harassing,
                                stalking, insulting comments, personal attacks, gossip, and similar actions are prohibited. Your
                                User-Generated Content must not threaten, abuse, or harm others, and it must not include any negative comments
                                that are connected to race, national origin, gender, sexual orientation, or physical handicap. Your
                                User-Generated Content must not be defamatory, slanderous, indecent, obscene, pornographic, or sexually
                                explicit.</li>
                            <li>Not Use for Commercial, Political, or Inappropriate Purposes. Your User-Generated Content must not advertise
                                or promote a product or service or other commercial activity, or a politician, public servant, or law. If you
                                submit User-Generated Content that Joulez reasonably believes violates this paragraph, then we may take any
                                legal action that we deem appropriate, in our sole discretion. Your User-Generated Content must not promote
                                any infringing, illegal, or other similarly inappropriate activity. If you submit User-Generated Content that
                                Joulez reasonably believes violates this paragraph, then we may take any legal action that we deem
                                appropriate, in our sole discretion.</li>
                        </ul>

                        <p className='fontColorFCC'>We are not obligated to regularly review posted User-Generated Content but we do reserve the right to monitor
                            and edit or remove any User-Generated Content you submit. You grant Joulez the right to use the name that you
                            submit in connection with any User-Generated Content. You are and shall remain solely responsible for the
                            content of any User-Generated Content you post to this website. We take no responsibility and assume no
                            liability for any User-Generated Content submitted by you or any third party.</p>
                        <p className='fontColorFCC'>You are solely responsible for the contents of your transmissions through the Services. Your use of the
                            Services is subject to all applicable local, state, national and international laws and regulations and the
                            license and use restrictions set forth above. You agree to monitor the use of the Services by any other person,
                            whether an employee, agent, or otherwise, who is acting on your behalf, at your direction, or using your online
                            account, for any activity that violates applicable laws, rules, and regulations or any of these terms, including
                            any fraudulent, inappropriate, or potentially harmful behavior, and promptly restrict any offending users of the
                            Services from further use of the Services. You agree to provide a resource for users of the Services to report
                            abuse of the Services at your operations. As between you and us, you are responsible for all acts and omissions
                            of your end users in connection with yours and their use of the Services. You agree that you are solely
                            responsible for posting any privacy notices and obtaining any consents from your end users required under
                            applicable laws, rules, and regulations for their use of the Services and you represent and warrant that you
                            have provided such notices and obtained such consents before submitting or allowing end users to submit
                            information to us.</p>
                        <p className='fontColorFCC'>All remarks, suggestions, ideas, graphics or other information that you communicate through the Services will
                            become and remain the property of Joulez. You understand that this means:</p>

                        <ul className='fontColorFCC'>
                            <li>Joulez does not have to treat any such submission as confidential.</li>
                            <li>You cannot claim any rights against Joulez for the use of the ideas you submit.</li>
                            <li>If Joulez uses your submissions (or anything like them), Joulez does not have to pay you or anyone else for
                                them. Joulez will have exclusive ownership of all present and future rights to submissions of every kind.</li>
                            <li>Joulez can use the submissions for any purpose, without compensating you or anyone else for them. </li>
                            <li>Our receipt of your unsolicited ideas and materials is not an admission by us of their novelty, priority, or
                                originality, and it does not impair our right to contest existing or future intellectual property rights
                                relating to your unsolicited ideas and materials.</li>
                        </ul>

                        <p className='fontColorFCC'>You acknowledge that you have full responsibility for the information you submit. You also acknowledge that
                            Joulez is NOT liable for any claims that the information you submit violates any laws or infringes the rights of
                            any third party including, without limitation, defamation, right of privacy or publicity, trademark, copyright
                            or consumer protection.</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">8. INDEMNIFICATION</h3>
                        <p className='fontColorFCC'>You agree to immediately notify Joulez of and indemnify and hold Joulez, its parents, subsidiaries, affiliates,
                            directors, officers, shareholders, investors, and employees, harmless from any claim or demand, including
                            reasonable attorneys’ fees, made by any third party due to or arising out of your use of the Services,
                            (including the unauthorized use of your account or any other breach of security attributable to your use of the
                            Services), the violation of these terms by you, or the infringement by you, or another user using your computer,
                            on any intellectual property or other right of any person or entity.</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">9. INFORMATIONAL PURPOSES ONLY</h3>
                        <p className='fontColorFCC'><strong>THE SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY, AND YOU SHOULD NOT CONSTRUE ANY SUCH INFORMATION OR
                            OTHER MATERIAL AVAILABLE THROUGH THEM AS LEGAL, TAX, INVESTMENT, FINANCIAL, OR OTHER ADVICE. THE INFORMATION
                            AND OTHER MATERIAL AVAILABLE ON THE SERVICES IS OF A GENERAL NATURE AND DOES NOT ADDRESS THE CIRCUMSTANCES OF
                            ANY PARTICULAR INDIVIDUAL OR ENTITY. YOU ASSUME THE SOLE RISK AND RESPONSIBILITY OF EVALUATING THE INFORMATION
                            AND OTHER MATERIAL AVAILABLE THROUGH THE SERVICES BEFORE MAKING ANY DECISIONS. YOU AGREE NOT TO HOLD US LIABLE
                            FOR ANY DECISION YOU MAKE BASED ON THE INFORMATION OR MATERIALS AVAILABLE ON THE SERVICES. </strong></p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">10. DISCLAIMER OF WARRANTIES</h3>
                        <p className='fontColorFCC'><strong>YOU AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND
                            “AS AVAILABLE” BASIS, AND JOULEZ (INCLUDING, WITHOUT LIMITATION, ITS PARENTS, AFFILIATES, INDEPENDENT
                            CONSULTANTS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, INVESTORS, SUBCONTRACTORS, DISTRIBUTORS, OR ANY CLIENT OF
                            JOULEZ (COLLECTIVELY, “JOULEZ THIRD PARTIES”)) ASSUMES NO RESPONSIBILITY FOR THE TIMELINESS,
                            DELETION, MIS-DELIVERY OR FAILURE TO STORE ANY OF YOUR COMMUNICATIONS, DATA, OR PERSONALIZATION
                            SETTINGS. </strong></p>
                        <p className='fontColorFCC'><strong>JOULEZ AND JOULEZ THIRD PARTIES DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                            INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                            NON-INFRINGEMENT, QUIET ENJOYMENT, TITLE, MERCHANTABILITY OF COMPUTER PROGRAMS AND INFORMATIONAL
                            CONTENT.</strong></p>
                        <p className='fontColorFCC'><strong>NEITHER JOULEZ NOR ANY JOULEZ THIRD PARTIES MAKE ANY WARRANTY THAT THE SERVICES WILL MEET YOUR
                            REQUIREMENTS, OR THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR OR VIRUS FREE; NOR DO JOULEZ
                            OR ANY JOULEZ THIRD PARTIES MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                            SERVICES OR AS TO THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE SERVICES OR THAT DEFECTS
                            IN THE SOFTWARE DRIVING THE SERVICES WILL BE CORRECTED. </strong></p>
                        <p className='fontColorFCC'><strong>YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
                            OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                            TO A COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. WE DO NOT
                            GUARANTEE THE SECURITY OF THE SERVICES OR THE PREVENTION FROM LOSS OF, ALTERATION OF, OR IMPROPER ACCESS TO,
                            YOUR ACCOUNT INFORMATION OR DATA, OR THAT THE SERVICES WILL BE FREE OF VIRUSES, WORMS OR OTHER HARMFUL
                            COMPONENTS.</strong></p>
                        <p className='fontColorFCC'><strong>WE MAY MODIFY, SUSPEND, OR DISCONTINUE OFFERING THE SERVICES EITHER IN GENERAL OR SPECIFICALLY TO YOU
                            AT ANY TIME AND FOR ANY REASON WITHOUT NOTICE OR COMPENSATION TO YOU. THE SERVICES MAY BECOME UNAVAILABLE DUE
                            TO MAINTENANCE, OUTAGE, OR ANY OTHER REASON WHATSOEVER. WE MAKE NO REPRESENTATION OR WARRANTY WHATSOEVER
                            RELATING TO OR RESULTING FROM THE USE OR INABILITY TO USE THE SERVICES, MISTAKES, OMISSIONS, SERVICE
                            INTERRUPTIONS, DELETION OF FILES, LOSS OR MODIFICATION OF CONTENT OR DATA, ERRORS, DEFECTS, DELAYS IN
                            OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, COMMUNICATION FAILURE, THEFT, DESTRUCTION, OR
                            UNAUTHORIZED ACCESS TO ANY SERVER, RECORDS, PROGRAMS, OR SERVICES, WHETHER OR NOT LIMITED TO CIRCUMSTANCES
                            BEYOND OUR CONTROL. </strong></p>
                        <p className='fontColorFCC'><strong>THE SERVICES MAY CONTAIN LINKS TO THIRD-PARTY SITES NOT AFFILIATED WITH US IN ANY WAY. THESE LINKS ARE
                            PROVIDED TO YOU ONLY AS A CONVENIENCE AND ARE NOT UNDER OUR CONTROL. WE ARE NOT RESPONSIBLE FOR THE CONTENTS
                            OF ANY LINKED SITE, OR ANY LINK CONTAINED ON A SITE LINKED THROUGH THE SERVICES, NOR DO WE ENDORSE ANY THIRD-
                            PARTY SITE TO WHICH WE MAY PROVIDE LINKS.</strong></p>
                        <p className='fontColorFCC'><strong>WE MAKE NO REPRESENTATION OR WARRANTY THAT THE INFORMATION PROVIDED VIA THE SERVICES IS APPROPRIATE OR
                            AVAILABLE FOR USE OUTSIDE THE UNITED STATES. IF YOU CHOOSE TO ACCESS THE SERVICES FROM OUTSIDE THE UNITED
                            STATES, YOU DO SO ON YOUR OWN INITIATIVE AND ARE RESPONSIBLE FOR COMPLIANCE WITH LOCAL LAWS, IF AND TO THE
                            EXTENT LOCAL LAWS ARE APPLICABLE.</strong></p>
                        <p className='fontColorFCC'><strong>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SERVICES SHALL CREATE
                            ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</strong></p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">11. LIMITATION OF LIABILITY </h3>
                        <p className='fontColorFCC'><strong>IN NO EVENT WILL JOULEZ, ITS PARENTS, SUBSIDIARIES, DIRECTORS, INVESTORS, SHAREHOLDERS, AFFILIATES,
                            OFFICERS AND EMPLOYEES, OR ANY JOULEZ THIRD PARTIES BE LIABLE TO YOU  FOR ANY SPECIAL, EXEMPLARY, INDIRECT,
                            INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO THOSE FOR LOSS OF PROFITS, REVENUE, OR
                            GOODWILL, USE, DATA OR OTHER INTANGIBLES, WHETHER THE CLAIM IS BASED UPON CONTRACT, BREACH OF WARRANTY, TORT,
                            OR OTHER THEORY, RESULTING FROM OR CONCERNING THE USE OR THE INABILITY TO USE THE SERVICES OR YOUR FAILURE TO
                            COMPLY WITH THESE TERMS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </strong></p>
                        <p className='fontColorFCC'><strong>IN NO EVENT SHALL THE TOTAL AND AGGREGATE LIABILITY OF JOULEZ OR ANY JOULEZ THIRD PARTIES FOR ANY CAUSE
                            OF ACTION OR REASON WHATSOEVER EXCEED ONE-HUNDRED DOLLARS ($100.00 USD). YOUR SOLE AND EXCLUSIVE REMEDY UNDER
                            THESE TERMS IS TO DISCONTINUE THE USE OF THE SERVICES. THE LIABILITY OF ANY PARTY UNDER THESE TERMS SHALL BE
                            CUMULATIVE AND NOT PER INCIDENT. JOULEZ DISCLAIMS ALL RESPONSIBILITY FOR ANY LOSS, INJURY, CLAIM, LIABILITY,
                            OR DAMAGE OF ANY KIND RESULTING FROM, ARISING OUT OF OR ANY WAY RELATED TO:</strong></p>
                        <ul className="list-unstyled ml-1 fontColorFCC">
                            <li className='mb-2'><strong>1. ANY ERRORS IN OR OMISSIONS IN THE SERVICES AND ITS CONTENT, INCLUDING BUT NOT LIMITED TO
                                TECHNICAL INACCURACIES AND TYPOGRAPHICAL ERRORS,</strong></li>
                            <li className='mb-2'><strong>2. ANY ERRORS OR OMISSIONS FROM RESULTING FROM YOUR USE OF THE SERVICES,</strong></li>
                            <li className='mb-2'><strong>3. ANY THIRD-PARTY WEBSITES OR CONTENT THEREIN DIRECTLY OR INDIRECTLY ACCESSED THROUGH LINKS
                                IN OUR SITE, INCLUDING BUT NOT LIMITED TO ANY ERRORS IN OR OMISSIONS THEREFROM,</strong></li>
                            <li className='mb-2'><strong>4. THE UNAVAILABILITY OF THE SERVICES, OUR SITE, OR ANY PORTION THEREOF,</strong></li>
                            <li className='mb-2'><strong>5. YOUR USE OF THE SERVICES AND/OR ANY ERRORS OR OMISSIONS IN DATA ENTRY BY YOU, OR</strong></li>
                            <li><strong>6. YOUR USE OF ANY EQUIPMENT OR SOFTWARE IN CONNECTION WITH THE SERVICES.</strong></li>
                        </ul>

                        <p className='fontColorFCC'><strong>YOU MUST COMMENCE ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS
                            OR THE USE OF THE SERVICES WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
                            ACTION OR CLAIM IS PERMANENTLY BARRED. </strong></p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">12. NOTICES </h3>
                        <p className='fontColorFCC'>Unless otherwise provided herein or in another express, written agreement between you and Joulez, notices given
                            by us to you will be given by email. Notices will be sent to the email address you provide to Joulez as part of
                            the registration process, or to updated addresses which you provide to Joulez via notice consistent with this
                            paragraph. Notices given by you to Joulez must be given by email to <a
                                href="mailto:legal@drivejoulez.com"  className='fontColorCE1 text-decoration-none'>legal@drivejoulez.com</a> or such updated address and number as Joulez may
                            provide you consistently with this notice provision. Notwithstanding anything herein to the contrary, it is your
                            sole responsibility to update your address for notices hereunder, and notice sent to the email address last
                            provided by you to Joulez shall be valid and binding on you regardless of whether such address has been changed,
                            canceled, has expired, has been terminated, or otherwise becomes inoperative.</p>
                        <p className='fontColorFCC'>All notices, requests, consents, claims, demands, waivers, and other communications hereunder shall be in
                            writing and shall be deemed to have been given: (i) when delivered by hand (with written confirmation of
                            receipt); (ii) when received by the addressee if sent by a nationally recognized overnight courier (receipt
                            requested); (iii) on the date sent by facsimile or email (with confirmation of transmission) if sent during
                            normal business hours of the recipient, and on the next business day if sent after normal business hours of the
                            recipient; or (iv) on the third day after the date mailed, by certified or registered mail, return receipt
                            requested, postage prepaid.</p>
                    </div>
                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">13. GOVERNING LAW; ABRITRATION AGREEMENT </h3>
                        <p className='fontColorFCC'><strong><u>PLEASE READ THIS CAREFULLY AS IT AFFECTS YOUR RIGHTS.</u></strong></p>
                        <p className='fontColorFCC'>These terms shall be governed by and construed in accordance with the laws of the State of Iowa notwithstanding
                            any conflict of law’s provisions. </p>
                        <p className='fontColorFCC'><strong>YOU AGREE THAT BY USING THE SERVICES YOU ARE WAIVING THE RIGHT TO A COURT OR JURY TRIAL OR TO
                            PARTICIPATE IN A CLASS ACTION. YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST JOULEZ, ITS PARENTS, SUBSIDIARIES,
                            DIRECTORS, INVESTORS, SHAREHOLDERS, AFFILIATES, OFFICERS AND EMPLOYEES, AND THE JOULEZ THIRD PARTIES ONLY IN
                            YOUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, REPRESENTATIVE, OR
                            COLLECTIVE PROCEEDING. ANY ARBITRATION WILL TAKE PLACE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS
                            ACTIONS ARE NOT PERMITTED.</strong></p>
                        <p className='fontColorFCC'><strong>YOU AGREE THAT ANY AND ALL CLAIMS AND DISPUTES ARISING FROM OR RELATING IN ANY WAY TO THE SUBJECT
                            MATTER OF THESE TERMS, YOUR USE OF THE SERVICES, OR YOUR DEALINGS WITH JOULEZ SHALL BE FINALLY SETTLED AND
                            RESOLVED THROUGH BINDING INDIVIDUAL ARBITRATION AS DESCRIBED IN THIS SECTION. THIS AGREEMENT TO ARBITRATE IS
                            INTENDED TO BE INTERPRETED BROADLY PURSUANT TO THE FEDERAL ARBITRATION ACT. THE ARBITRATION WILL BE GOVERNED
                            BY THE COMMERCIAL ARBITRATION RULES AND THE SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES OF THE
                            AMERICAN ARBITRATION ASSOCIATION (“AAA”), AS MODIFIED BY THIS SECTION. THE ARBITRATION WILL BE CONDUCTED USING
                            ONE ARBITRATOR WITH SUBSTANTIAL EXPERIENCE IN RESOLVING COMMERCIAL CONTRACT DISPUTES, WHO SHALL BE SELECTED
                            FROM THE APPROPRIATE LIST OF ARBITRATORS IN ACCORDANCE WITH THE ARBITRATION RULES AND PROCEDURES OF ANY
                            ARBITRATION ORGANIZATION OR ARBITRATOR THAT YOU AND JOULEZ AGREE UPON IN WRITING OR THAT IS APPOINTED PURSUANT
                            TO SECTION 5 OF THE FEDERAL ARBITRATION ACT. </strong></p>
                        <p className='fontColorFCC'><strong>To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your
                            claim to the AAA and to <a href="mailto:legal@drivejoulez.com"  className='fontColorCE1 text-decoration-none'>LEGAL@DRIVEJOULEZ.COM</a>. The AAA’s address
                            is: American Arbitration Association Case Filing Services, 1101 Laurel Oak Road, Suite 100, Voorhees, NJ 08043
                            (the “AAA Notice Address”). You may also send a copy to the AAA online at <a
                                href="https://www.adr.org"  className='fontColorCE1 text-decoration-none'>https://www.adr.org</a>.</strong></p>
                        <p className='fontColorFCC'><strong>FOR ANY CLAIM WHERE THE TOTAL AMOUNT OF THE AWARD SOUGHT IS $15,000 OR LESS YOU MUST ABIDE BY THE
                            FOLLOWING RULES: (A) THE ARBITRATION SHALL BE CONDUCTED SOLELY BASED ON TELEPHONE OR ONLINE APPEARANCES AND/OR
                            WRITTEN SUBMISSIONS; AND (B) THE ARBITRATION SHALL NOT INVOLVE ANY PERSONAL APPEARANCE BY THE PARTIES OR
                            WITNESSES UNLESS OTHERWISE MUTUALLY AGREED BY THE PARTIES. IF THE CLAIM EXCEEDS $15,000, THE RIGHT TO A
                            HEARING WILL BE DETERMINED BY THE AAA RULES, AND THE HEARING (IF ANY) MUST TAKE PLACE IN NEW YORK, NY, USA.
                            THE ARBITRATOR’S RULING IS BINDING AND MAY BE ENTERED AS A JUDGMENT IN ANY COURT OF COMPETENT JURISDICTION, OR
                            APPLICATION MAY BE MADE TO SUCH COURT FOR JUDICIAL ACCEPTANCE OF ANY AWARD AND AN ORDER OF ENFORCEMENT, AS THE
                            CASE MAY BE. </strong></p>
                        <p className='fontColorFCC'><strong>THERE IS NO JUDGE OR JURY IN ARBITRATION. ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN
                            RULES APPLICABLE IN COURT AND REVIEW BY A COURT IS LIMITED. YOU WILL NOT BE ABLE TO HAVE A COURT OR JURY TRIAL
                            OR PARTICIPATE IN A CLASS ACTION OR CLASS ARBITRATION. YOU UNDERSTAND AND AGREE THAT BY AGREEING TO RESOLVE
                            ANY DISPUTE THROUGH INDIVIDUAL ARBITRATION, YOU ARE WAIVING THE RIGHT TO A COURT OR JURY TRIAL. ANY DISPUTE
                            SHALL BE ARBITRATED ON AN INDIVIDUAL BASIS, AND NOT AS A CLASS ACTION, REPRESENTATIVE ACTION, CLASS
                            ARBITRATION OR ANY SIMILAR PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE THE CLAIMS OF MULTIPLE
                            PARTIES.</strong></p>
                        <p className='fontColorFCC'><strong>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE AGAINST JOULEZ, ITS PARENTS, SUBSIDIARIES, DIRECTORS,
                            INVESTORS, SHAREHOLDERS, AFFILIATES, OFFICERS AND EMPLOYEES, AND THE JOULEZ THIRD PARTIES ARISING OUT OF OR
                            RELATING IN ANY WAY TO THESE TERMS, YOUR USE OF THE SERVICES, OR YOUR DEALINGS WITH JOULEZ, ITS PARENTS,
                            SUBSIDIARIES, DIRECTORS, INVESTORS, SHAREHOLDERS, AFFILIATES, OFFICERS AND EMPLOYEES, AND THE JOULEZ THIRD
                            PARTIES MUST BE COMMENCED IN ARBITRATION WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. AFTER THAT ONE
                            (1) YEAR PERIOD, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED. SOME JURISDICTIONS DO NOT ALLOW TIME
                            LIMITATIONS OTHER THAN THOSE SET FORTH IN SUCH STATE’S STATUTE OF LIMITATIONS LAWS. IN SUCH CASES, THE
                            APPLICABLE STATUTE OF LIMITATIONS PROVIDED FOR UNDER THE LAWS OF SUCH STATE SHALL APPLY.</strong></p>
                        <p className='fontColorFCC'><strong>NOTWITHSTANDING ANY PROVISION IN THESE TERMS TO THE CONTRARY, IF THE CLASS-ACTION WAIVER ABOVE IS
                            DEEMED INVALID OR UNENFORCEABLE YOU AGREE THAT YOU SHALL NOT SEEK TO, AND WAIVE ANY RIGHT TO, ARBITRATE CLASS
                            OR COLLECTIVE CLAIMS. IF THE ARBITRATION PROVISION IN THIS SECTION IS FOUND UNENFORCEABLE OR TO NOT APPLY FOR
                            A GIVEN DISPUTE, THEN THE PROCEEDINGS MUST BE BROUGHT EXCLUSIVELY IN THE FEDERAL COURTS LOCATED WITHIN NEW
                            YORK COUNTY, NEW YORK, AND YOU AGREE TO SUBMIT TO THE PERSONAL JURISDICTION OF THESE COURTS FOR THE PURPOSE OF
                            LITIGATING SUCH CLAIMS OR DISPUTES, AND YOU STILL WAIVE YOUR RIGHT TO A JURY TRIAL, WAIVE YOUR RIGHT TO
                            INITIATE OR PROCEED IN A CLASS OR COLLECTIVE ACTION, AND REMAIN BOUND BY ANY AND ALL LIMITATIONS OF LIABILITY
                            AND DAMAGES INCLUDED IN THESE TERMS. THIS ARBITRATION AGREEMENT WILL SURVIVE TERMINATION OF YOUR USE OF THE
                            SERVICES AND YOUR RELATIONSHIP WITH JOULEZ. THIS ARBITRATION AGREEMENT INVOLVES INTERSTATE COMMERCE, AND,
                            THEREFORE, SHALL BE GOVERNED BY THE FEDERAL ARBITRATION ACT, 9 U.S.C. 1–16 (“FAA”), AND NOT BY STATE LAW.
                        </strong></p>
                        <p className='fontColorFCC'><strong>IF YOU WISH TO OPT OUT OF THE AGREEMENT TO ARBITRATE, WITHIN 30 DAYS OF WHEN YOU FIRST USE THE
                            SERVICES, OR SUBMIT THROUGH THE SERVICES A REQUEST FOR INFORMATION, YOU MUST SEND US AN EMAIL WITH THE SUBJECT
                            LINE “REQUEST TO OPT-OUT OF AGREEMENT TO ARBITRATE” TO THE FOLLOWING ADDRESS: <a
                                href="mailto:legal@drivejoulez.com"  className='fontColorCE1 text-decoration-none'>LEGAL@DRIVEJOULEZ.COM</a>. In the event you opt out of the arbitration
                            provision, you agree to litigate exclusively in the federal courts located within NEW YORK, NY, USA, and you
                            hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such
                            action. </strong></p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">14. COPYRIGHT POLICY</h3>
                        <p className='fontColorFCC'>We take claims of copyright infringement seriously. We will respond to notices of alleged copyright
                            infringement that comply with applicable law. If you believe any materials accessible on or from the Services
                            infringe your copyrights, you may request removal of those materials (or access to them) by submitting written
                            notification to our copyright agent designated below. In accordance with the Online Copyright Infringement
                            Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice
                            (the “DMCA Notice”) must include substantially the following:</p>
                        <ul className="list-unstyled ml-1 fontColorFCC">
                            <li className='mb-2'>1. Your physical or electronic signature.</li>
                            <li className='mb-2'>2. Identification of the copyrighted work you believe to have been infringed or, if the claim involves
                                multiple works, a list of such works.</li>
                            <li className='mb-2'>3. Identification of the material you believe to be infringing in a sufficiently precise manner to allow us
                                to locate that material.</li>
                            <li className='mb-2'>4. Adequate information by which we can contact you (including your name, postal address, telephone number,
                                and, if available, email address).</li>
                            <li className='mb-2'>5. A statement that you have a good faith belief that use of the copyrighted material is not authorized by
                                the copyright owner, its agent, or the law.</li>
                            <li className='mb-2'>6. A statement that the information in the written notice is accurate.</li>
                            <li>7. A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
                            </li>
                        </ul>

                        <p className='fontColorFCC'>Our designated copyright agent to receive DMCA Notices is:</p>
                        <address className='fontColorFCC'>
                            Brian McCormac<br />
                            Legal Counsel to Joulez  Inc.,<br />
                            BrownWinick Law Firm<br />
                            666 Grand Avenue, Suite 2000 Ruan Center<br />
                            Des Moines, IA 50309<br />
                            (515) 242-2400<br />
                            Brian.mccormac@brownwinick.com <br />
                        </address>

                        <p className='fontColorFCC'>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not
                            be effective. Please be aware that if you knowingly materially misrepresent that material or activity on the
                            Services is infringing your copyright, you may be held liable for damages (including costs and attorneys’ fees)
                            under Section 512(f) of the DMCA.</p>
                        <p className='fontColorFCC'>If you believe that material you posted to the Services was removed or access to it was disabled by mistake or
                            misidentification, or that you have the appropriate authorization from the copyright owner, the copyright
                            owner’s agent, or pursuant to the law, to upload and use any material that you uploaded or used, you may file a
                            counter-notification with ss (a “Counter-Notice”) by submitting written notification to our copyright agent
                            designated above. Pursuant to the DMCA, the Counter-Notice must include substantially the following:</p>

                        <ul className="list-unstyled ml-1 fontColorFCC">
                            <li className='mb-2'>1. Your physical or electronic signature.</li>
                            <li className='mb-2'>2. An identification of the material that has been removed or to which access has been disabled and the
                                location at which the material appeared before it was removed or access disabled. </li>
                            <li className='mb-2'>3. Adequate information by which we can contact you (including your name, postal address, telephone number,
                                and, if available, email address).</li>
                            <li className='mb-2'>4. A statement under penalty of perjury by you that you have a good faith belief that the material
                                identified above was removed or disabled as a result of a mistake or misidentification of the material to be
                                removed or disabled.</li>
                            <li>5. A statement that you will consent to the jurisdiction of the Federal District Court for the judicial
                                district in which your address is located (or if you reside outside the United States for any judicial
                                district in which the Services may be found) and that you will accept service from the person (or an agent of
                                that person) who provided the agent designated above with the complaint at issue.</li>
                        </ul>

                        <p className='fontColorFCC'>The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a
                            court action against you within ten (10) business days of receiving the copy of your Counter-Notice.</p>
                        <p className='fontColorFCC'>Please be aware that if you knowingly materially misrepresent that material or activity on the Services was
                            removed or disabled by mistake or misidentification, you may be held liable for damages (including costs and
                            attorneys’ fees) under Section 512(f) of the DMCA.</p>
                        <p className='fontColorFCC'>We reserve the right to disable and/or terminate the accounts of users who violate our Copyright Policy and
                            take all other legal actions in our discretion.</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">15. SECURITY DEPOSIT</h3>
                        <p className='fontColorFCC'>
                            At the time of rental an authorization hold of a minimum of USD $250.00 up to USD $1,000.00 will be secured on
                            your credit card to cover any estimated additional charges that may be incurred. This amount is determined by
                            length of trip, value of vehicle, rental history with Joulez and age of the driver. Your security deposit will
                            be released after your trip is complete and all incidental charges have been paid. Incidental charges applied to
                            the deposit can include but are not limited to: EV Charging fees, tickets, fines, tolls, physical damage to the
                            vehicle up to your insurance or protection plan deductible, late return fees or additional unpaid usage fees.
                        </p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">16. Incidentals and Vehicle Charge Policy</h3>

                        <ul >
                            <li className='fontColorFCC'><strong  className='fontcolorFFF'>Expenses:</strong> The customer is responsible for all incidental expenses incurred during their
                                trip,
                                including but not limited to tolls, supercharges, and violations. Joulez reserves the right to charge these
                                expenses to the customer's account. Additionally, an administrative fee will be applied, with the rate of
                                20%, depending on the type of the incidental charge.</li>
                            <li className='fontColorFCC'><strong className='fontcolorFFF'>Vehicle Charge Requirement:</strong> Unless the customer selects or purchases the Prepaid EV
                                Recharge
                                option, it is expected that the vehicle will be returned with the same battery charge level as it had when
                                initially rented.</li>
                            <li className='fontColorFCC'><strong className='fontcolorFFF'>Low Battery Return:</strong> In the event that the vehicle is returned with a lower battery charge
                                than the initial pickup charge percentage, the following charges will apply:</li>
                        </ul>
                        <p >
                            <strong className='fontcolorFFF'>Post Rental Supercharge Amount calculation:</strong><br />
                            <span className='fontColorFCC'> Rental Supercharge Amount = (Pickup charge percentage - Return charge percentage) / 2
                                For example, if the pickup charge is 90% and the return charge percentage is 10%, the post-rental charge would
                                be
                                $40 ( 90-10/2)</span>
                        </p>
                    </div>
                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">17. Third Party Coverage Restrictions </h3>
                        <p className='fontColorFCC'>Joulez  Inc., reserves the right to reject any third party coverages based on its own discretion.</p>
                    </div>
                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">18. COMMUNICATION </h3>
                        <p className='fontColorFCC'>By providing your contact information, you consent to receive communications from Joulez through electronic channels, including email, in-app notifications, and messaging via the Joulez platform, as an alternative to receiving physical mail. To streamline our communication with you, we may also contact you via phone calls or text messages for purposes such as confirming or providing information about your Joulez Account, addressing account-related activities, preventing or investigating fraud, collecting outstanding debts, recovering unpaid invoices, or delivering urgent notices. In order to facilitate these communications, we may share your phone number(s) with third-party service providers contracted by Joulez. However, we will never use your contact information for marketing purposes, nor will we share it with any third parties for their own marketing uses without your explicit consent. Please note that standard charges for calls, texts, and data usage may apply. If consent is required for any communication, you have the right to revoke your consent at any time. By agreeing, you acknowledge that Joulez may contact you at any time and on any day in relation to the purposes outlined above.</p>
                    </div>
                    <div className="col-md-12 terms-section">
                        <h3 className="gdpr-heading">19. GENERAL </h3>
                        <p className='fontColorFCC'>If any provision of these terms is held by a court of competent jurisdiction to be contrary to law, then such
                            provision shall be construed, as nearly as possible, to reflect the intentions of the parties with the other
                            provisions remaining in full force and effect. Any failure to exercise or enforce any right or provision of
                            these terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to in
                            writing. The section titles in these terms are solely used for the convenience of the parties and have no legal
                            or contractual significance. These terms may be assigned in whole or in part by Joulez. These terms may not be
                            assigned in any manner by you without the express, prior written permission of Joulez.</p>
                        <p className='fontColorFCC'>Our failure to enforce any right or provision of these terms will not be deemed a waiver of such right or
                            provision. Any waiver will be in writing and signed by the waiving party. </p>
                        <p className='fontColorFCC'>We may revise these terms at any time in our sole discretion by posting such revised terms on the terms page of
                            drivejoulez.com. Please review the terms page on a regular basis for changes. Continued use of our Services
                            following any change constitutes your acceptance of the change.</p>
                        <p className='fontColorFCC'>If you have questions regarding these terms, please contact us at <a
                            href="mailto:legal@drivejoulez.com" className='fontColorCE1 text-decoration-none'>legal@drivejoulez.com</a> or 66 W Flagler Street Suite 900 #11303 - Miami, FL 33130</p>
                    </div>

                    <div className="col-md-12 terms-section">
                        <p>&copy; {currentYear} Joulez  Inc., All rights reserved.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TermsAndConditions