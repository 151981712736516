import { Fragment, useEffect, useRef, useState } from "react";
import { content1 } from "../data/content";
import CarouselContainer from "../Components/CarouselContainer";
import DiscoverContainer from "../Components/DiscoverContainer";
import FounderContainer from "../Components/FounderContainer";
import JoulezWorkContainer from "../Components/JoulezWorkContainer";
import LatestUpdatesContainer from "../Components/LatestUpdatesContainer";
import SearchContainer from "../Components/SearchComponent/SearchContainer";
import TeslaContainer from "../Components/TeslaContainer";
import images from "../assets/Images";
import { setCurrentPage } from "../Store/pageSlice";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "../Components/MetaTag";
import useScrollY from "../Hooks/useScrollY";
import { addPickupAndDropDates } from "../Store/dateAndTimeSlice";
import FaqList from "../Components/FaqList";
import { useLocation } from "react-router-dom";
import AirportContainer from "../Components/AirportContainer";
import CurbsideDeliveryContainer from "../Components/CurbsideDeliveryContainer";
import CustomerReviewsContainer from "../Components/CustomerReviewsContainer";
//const randomImages  = [images?.firstNewCar, images?.secondNewCar, images?.thirdNewCar]

const randomImages = (images) => {
  return [images?.firstNewCar, images?.secondNewCar, images?.fourthCar].filter(Boolean);
};

const Home = () => {
  const { title1, title2, locations } = content1;
  const [image, setImage] = useState(randomImages[0]); // Default to first image
  const dispatch = useDispatch();
  const scrollY = useScrollY();
  const modalLocationStatus = useSelector((store) => store.modalStatus);

  useEffect(() => {
    dispatch(setCurrentPage("home"));
  }, [dispatch]);

  useEffect(() => {
   if(modalLocationStatus?.showModalPickupDropDown){
    window.scrollTo(0, 0);
   }
  }, [modalLocationStatus?.showModalPickupDropDown]);

//current dates sending to redux store initially from home page
  const correctDate = new Date();

// Add 2 hours to the current time
correctDate.setHours(correctDate.getHours() + 1);

// Format the current date with 2 hours added
const formattedDateNew = correctDate.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

const tomorrowDate = new Date(correctDate);
tomorrowDate.setDate(tomorrowDate.getDate() + 1);

// Format tomorrow's date
const formattedTomorrowDateNew = tomorrowDate.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});

useEffect(() => {
  //console.log(formattedDateNew, formattedTomorrowDateNew);
  dispatch(addPickupAndDropDates({ pickupDate: formattedDateNew, dropDate: formattedTomorrowDateNew }));
}, [dispatch, formattedDateNew, formattedTomorrowDateNew]);

const location = useLocation()
const state = location?.state
const bottomRef = useRef(null);

useEffect(() => {
  if (state?.FAQs && bottomRef.current) {
    // Scroll to the element referenced by bottomRef
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  }
}, [state?.FAQs]); // Dependency on state.FAQs to trigger the scroll

// useEffect(() => {
//   // Select a random image when component mounts
//   setImage(randomImages[Math.floor(Math.random() * randomImages.length)]);
// }, []);

const imageSelected = useRef(false); // Prevent re-selection on re-render

useEffect(() => {
  if (!imageSelected.current && images) {
    const validImages = randomImages(images);
    if (validImages.length > 0) {
      setImage(validImages[Math.floor(Math.random() * validImages.length)]);
      imageSelected.current = true; // Mark as selected
    }
  }
}, []);

  return (
    <>
      <MetaTags
        title="Joulez - Electric Vehicle Car Rental Company Based in New York City and Los Angeles."
        description="Joulez is an Electric Vehicle company based out of New York City and Los Angeles. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen and Hyundai cars."
      />
      <div className="bg191916">
        <img
          className="w-100 bg-Image object-fit-cover position-absolute"
          src={image ? image : images?.secondNewCar}
          alt="homeBackgroundImage"
        />
        <div className="position-relative  m-auto w-100 ">
          <div
            className="text-center "
            // style={{
            //   top: scrollY < 400 ? `5%` : "-26%", // Initially at top 10%, then sticks at the top
            //   position: scrollY < 200 ? "absolute" : "fixed", // Fixed to the top after scrolling
            //   transition: "top 0.1s ease", // Smooth transition when adjusting 'top'
            //   width: scrollY < 400 ? '' : "100%", // Optional, adjust based on design
            //   // left: '50%',
            //   transform: "translateX(-50%) ", // Center the container horizontally
            //   zIndex: "2",
            //   marginTop:"10px"
            // }}
          >
            {/* <div>
              <div className="fontAtacama">
                <h1>{title1}</h1>
                <h1>{title2}</h1>
              </div>
              <div className="text-center d-flex justify-content-center fontAntarctica">
                {locations.map((location, index) => {
                  return (
                    <Fragment key={index}>
                      <p className="px-2">{location}</p>
                      {index < locations.length - 1 && <span>|</span>}
                    </Fragment>
                  );
                })}
              </div>
            </div> */}
            <div className={`d-flex justify-content-center w-100  ${scrollY > 500 ? "mt-0" : "py-lg-5 mt-3"} ${window?.innerWidth > 992 ? "position-fixed pt-0  " : "position-absolute padddingTop35px"} top-0 z-2`}>
              <SearchContainer JoulezIconRequired={scrollY > 500 ? true : false} />
            </div>
          </div>
          <div className=" d-flex justify-content-center w-100">
            <DiscoverContainer marginTop="mt-525" />
          </div>
          <div className="text-white">
            <CarouselContainer />
          </div>
          {/* <div>
            <AirportContainer />
          </div> */}
          <div>
            <CurbsideDeliveryContainer />
          </div>
          <div>
            <JoulezWorkContainer />
          </div>
          <div>
            <FounderContainer />
          </div>
          {/* <div>
            <LatestUpdatesContainer />
          </div> */}
          <div>
            <TeslaContainer />
          </div>
          {/* <div>
            <CustomerReviewsContainer />
          </div> */}
          <div ref={bottomRef}>
             <FaqList />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;


// import { Fragment, useEffect, useState } from "react";
// import { content1 } from "../data/content";
// import CarouselContainer from "../Components/CarouselContainer";
// import DiscoverContainer from "../Components/DiscoverContainer";
// import FounderContainer from "../Components/FounderContainer";
// import JoulezWorkContainer from "../Components/JoulezWorkContainer";
// import LatestUpdatesContainer from "../Components/LatestUpdatesContainer";
// import SearchContainer from "../Components/SearchComponent/SearchContainer";
// import TeslaContainer from "../Components/TeslaContainer";
// import images from "../assets/Images";
// import { setCurrentPage } from "../Store/pageSlice";
// import { useDispatch } from "react-redux";
// import MetaTags from "../Components/MetaTag";

// const Home = () => {
//   const { title1, title2, locations } = content1;
//   const dispatch = useDispatch();

//   // State to track the scroll position
//   const [scrollY, setScrollY] = useState(0);

//   useEffect(() => {
//     dispatch(setCurrentPage("home"));

//     // Function to handle scroll events
//     const handleScroll = () => {
//       setScrollY(window.scrollY); // Update scroll position
//     };

//     // Add event listener for scrolling
//     window.addEventListener("scroll", handleScroll);

//     // Cleanup the event listener when the component is unmounted
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, [dispatch]);

//   return (
//     <>
//       <MetaTags
//         title="Joulez - Electric Vehicle Car Rental Company Based in New York City and Los Angeles."
//         description="Joulez is an Electric Vehicle company based out of New York City and Los Angeles. At Joulez, we rent Tesla, BMW, Mercedes, Kia, Polestar, Lucid, Volkswagen and Hyundai cars."
//       />
//       <div className="bg191916">
//         <img
//           className="w-100 bg-Image object-fit-cover position-absolute"
//           src={images?.homepage}
//           alt="homeBackgroundImage"
//         />
//         <div className="position-relative  m-auto w-100 ">
//           <div
//             className="fontcolorFFF text-center detailsContainer"
//             style={{
//               top: scrollY < 400 ? `5%` : "-26%", // Initially at top 10%, then sticks at the top
//               position: scrollY < 200 ? "absolute" : "fixed", // Fixed to the top after scrolling
//               transition: "top 0.1s ease", // Smooth transition when adjusting 'top'
//               width: scrollY < 400 ? '' : "100%", // Optional, adjust based on design
//               // left: '50%',
//               transform: "translateX(-50%) ", // Center the container horizontally
//               zIndex: "2",
//               marginTop:"10px"
//             }}
//           >
//             <div>
//               <div className="fontAtacama">
//                 <h1>{title1}</h1>
//                 <h1>{title2}</h1>
//               </div>
//               <div className="text-center d-flex justify-content-center fontAntarctica">
//                 {locations.map((location, index) => {
//                   return (
//                     <Fragment key={index}>
//                       <p className="px-2">{location}</p>
//                       {index < locations.length - 1 && <span>|</span>}
//                     </Fragment>
//                   );
//                 })}
//               </div>
//             </div>
//             <div className="container-fluid d-flex justify-content-center w-100 padding-12px">
//               <SearchContainer />
//             </div>
//           </div>
//           <div className=" d-flex justify-content-center w-100">
//             <DiscoverContainer marginTop="mt-525" />
//           </div>
//           <div className="text-white">
//             <CarouselContainer />
//           </div>
//           <div>
//             <JoulezWorkContainer />
//           </div>
//           <div>
//             <FounderContainer />
//           </div>
//           <div>
//             <LatestUpdatesContainer />
//           </div>
//           <div>
//             <TeslaContainer />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;
