import React, { useEffect, useState } from "react";
import { BASE_URL } from "../api";
import axios from "axios";
import { toast } from "react-toastify";

const FaqList = () => {
  const [loadingFaqs, setLoadingFaqs] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [faqID, setFaqID] = useState("");
  const [faqError, setFaqError] = useState(false)

  useEffect(() => {
    const fetchFaqs = async () => {
      setLoadingFaqs(true);
      try {
        const response = await axios.get(`${BASE_URL}faq/open`);
        setFaqData(response?.data?.data);
        setFaqError(false)
        setLoadingFaqs(false);
      } catch (error) {
        setFaqError(true)
        setLoadingFaqs(false);
        // Optionally, you could set an error state to show an error message to the user
      }
    };

    fetchFaqs();
  }, []);

  const decodeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <div className="bg23 text-white py-5 fontAntarctica">
      <div className="text-center">
        <div className="faqFontSizeHeading fontWeight500">
          Frequently Asked Questions
        </div>
        <div className="faqFontsizeSubText fontWeight300 pt-2">
          Here are some questions we get asked a lot.
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row flex-nowrap flex-lg-wrap justify-content-between px-3 px-lg-5 pt-4">
        {faqData?.map((faq, index) => (
          <div
            className="p-1 faqListMargin col-12 col-lg-5 mx-0 mx-lg-4 mx-xl-5"
            key={faq?.id}
          >
            <div
              onClick={() => {
                if (index === faqID) {
                  setFaqID("");
                } else {
                  setFaqID(index);
                }
              }}
              className="fontSize17 my-1 cursorPointer fontWeight400"
            >
              {faq?.question}
            </div>
            {index === faqID && (
              <p
                className="fontSize13 fontWeight300"
                dangerouslySetInnerHTML={{
                  __html: decodeHTML(faq?.answer),
                }}
              ></p>
            )}
            {faqData?.length - 1 === index ? <div></div> : <hr className="m-0" />}
          </div>
        ))}
        {faqError && <div className="text-white text-center">Error In Faqs</div>}
      </div>
    </div>
  );
};

export default FaqList;
