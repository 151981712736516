import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import locateIcon from "../assets/svg/colorLocateIcon.svg";
import ShowPricingDetails from "../Components/ShowPricingDetails";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../api";
import { format } from "date-fns";
import { setCurrentPage } from "../Store/pageSlice";
import DuplicateImage from "../assets/Images/Duplicate.png";
import carDuplicateImage from "../assets/Images/carDuplicateImage.png"

import SpinnerLoader from "../Components/SpinnerLoader";

const BookingStatus = () => {
  const location = useLocation();
  const { bookingId } = location.state || {}; // Access the passed state
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [bookingDetailsLoading, setBookingDetailsLoading] = useState(false);

  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);
  const estimatedPriceDetails = JSON.parse(
    localStorage.getItem("bookingInputs")
  );

  const [bookingResponse, setBookingRespose] = useState("");
  const [bookingError, setBookingError] = useState(""); // Add state for error handling

  const token = sessionStorage.getItem("AccessToken");

  const callGoogletag = () => {
    window.gtag("event", "conversion", {
      send_to: "G-DE7JLFVL37",
      value: bookingResponse.paymentTransactions[0].total,
      currency: "USD",
      transaction_id: bookingResponse.bookingRefId,
    });
    window.gtag("event", "conversion", {
      send_to: "AW-10903830625/bNOSCM_dxYgYEOGArc8o",
      value: bookingResponse.paymentTransactions[0].total,
      currency: "USD",
      transaction_id: bookingResponse.bookingRefId,
    });
    window.gtag("event", "booking_success", {
      send_to: "G-DE7JLFVL37",
      value: bookingResponse.paymentTransactions[0].total,
      currency: "USD",
      transaction_id: bookingResponse.bookingRefId,
      car: carDetails?.carName,
      duration: estimatedPriceDetails?.noOfDays,
      city: carDetails?.location?.city,
    });
  };

  useEffect(() => {
    const getBookingById = async () => {
      try {
        setBookingDetailsLoading(true);
        const bookingIdResponse = await axios.get(
          `${BASE_URL}booking/getById/${bookingId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the Bearer token here
            },
          }
        );
        setBookingRespose(bookingIdResponse?.data?.data);
        setBookingDetailsLoading(false);
      } catch (error) {
        // Handle the error when the request fails
        if (error.response && error.response.data) {
          // If the server sent an error message
          setBookingError(
            error.response.data.message ||
              "An error occurred while fetching booking details."
          );
        } else {
          // General error handling
          setBookingError(
            "An unexpected error occurred. Please try again later."
          );
        }
        setBookingDetailsLoading(false);
      }
    };

    getBookingById();
    // eslint-disable-next-line
  }, [bookingId, token]);

  useEffect(() => {
    if (bookingResponse !== "") {
      callGoogletag();
    }
  }, [bookingResponse]);

  useEffect(() => {
    dispatch(setCurrentPage("booking-status"));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return format(date, "MMM dd, yyyy hh:mm a");
  };

  const carDetails = JSON.parse(localStorage.getItem("selectedCar"));
  const { carImage, vehicleModel, vehicleSubModel, vehicleType } = carDetails;
  //  console.log(carDetails);

  const {
    pickupLocation,
    dropOffLocation,
    bookingRefId,
    startDateTime,
    endDateTime,
    paymentTransactions,
  } = bookingResponse;

  const formattedStartDateTime = startDateTime
    ? formatDate(startDateTime)
    : "Invalid start date";
  const formattedEndDateTime = endDateTime
    ? formatDate(endDateTime)
    : "Invalid end date";

  const [carName, setCarName] = useState(null);
  const [carModel, setCarModel] = useState(null);
  // const [vehicleType, setCarModel] = useState(null);
  // vehicleType

  useEffect(() => {
    const selectCar = localStorage.getItem("selectedCar");
    if (selectCar) {
      const parsedCar = JSON.parse(selectCar);
      setCarName(parsedCar.name);
      setCarModel(parsedCar.vehicleModel); // Assuming 'vehicleModel' is the correct key
    }
  }, []);

  return (
    <div
      className={`min-vh-100 bookingStatuspaddingLeftAndRight  ${
        isDarkMode ? "darkMode" : "bgFEFDFD"
      }`}
    >
      {bookingDetailsLoading && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      <div className="pt-5 my-2 text-center bookingStatusThankYouTitle fontAtacama">
        Thank You for Choosing Joulez !
      </div>
      <div className="bookingStatusScreenPadding">
        <hr className="border3B3936 m-0" />
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-between ">
        <div className="bookingStatusScreenPadding col-lg-6 col-md-12">
          <div className="pt-2 fontAntarctica d-none d-lg-flex  justify-content-center align-items-center">
            <img
              className="bookingStatusCarImage borderRadius8"
              src={carImage || carDuplicateImage}
              alt="carImage"
            />
          </div>
          <div className="pt-2 fontAntarctica d-flex d-lg-none">
            <div className="w-100">
              <div className="d-flex justify-content-between mb-2 w-100">
                <div className="opacity70">Booking ID</div>
                <div>{bookingRefId}</div>
              </div>
              <div className="d-flex justify-content-between ">
                <div>
                  <div>
                    {carName} {carModel}
                  </div>
                  <div>{vehicleType}</div>
                  <div className="bg4ACC px-4 borderRadius4 p-1 mt-4">
                    Review Pending
                  </div>
                </div>
                <div>
                  <img
                    className="bookingStatusSmallScreenCarImage carSize borderRadius8"
                    src={carImage || carDuplicateImage}
                    alt="carImage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between my-2">
            <div>
              $
              {estimatedPriceDetails?.estimatedPrice?.total?.toLocaleString(
                "en-US",
                { minimumFractionDigits: 2, maximumFractionDigits: 2 }
              )}
            </div>
            <div>Days {estimatedPriceDetails?.noOfDays}</div>
          </div>
          <div className="d-none d-lg-flex justify-content-between align-items-center mt-3 fontAntarctica">
            <div>
              {vehicleModel} <span>{vehicleSubModel}</span>
            </div>
            <div className="opacity70">{vehicleType}</div>
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-between fontSize13">
            <div className="border3B3936 bg3B3936 p-3 mt-3 borderRadius8 fontAntarctica m-1">
              <div className="d-flex justify-content-between align-items-center">
                <div>Pick Up</div>
                <div>{formattedStartDateTime}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="px-1">
                  <img src={locateIcon} alt="locateIcon" />
                </div>
                <div className="px-1 opacity70">{pickupLocation}</div>
              </div>
            </div>
            <div className="border3B3936 bg3B3936 p-3 mt-3 borderRadius8 fontAntarctica m-1">
              <div className="d-flex justify-content-between align-items-center">
                <div>Drop Off</div>
                <div>{formattedEndDateTime}</div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="px-1">
                  <img src={locateIcon} alt="locateIcon" />
                </div>
                <div className="px-1 opacity70">{dropOffLocation}</div>
              </div>
            </div>
          </div>
          <div className="fontAntartica mt-3 border3B3936 p-3 borderRadius12 d-none d-lg-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="opacity70">Paid from</div>
              <div>XX {paymentTransactions?.[0]?.last4}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="opacity70">Amount Paid</div>
              <div>
                $
                {estimatedPriceDetails?.estimatedPrice?.total?.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bookingStatusScreenPadding col-lg-6 col-md-12">
          <div className="fontAntartica mt-3 border3B3936 p-3 borderRadius12 d-none d-lg-flex flex-column">
            <div className="d-flex justify-content-between mb-2">
              <div className="opacity70">Trip status</div>
              <div className="bg4ACC px-4 borderRadius4 p-1">
                Review Pending
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div className="opacity70">Booking ID</div>
              <div>{bookingRefId}</div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div className="opacity70">Duration</div>
              <div>
                {estimatedPriceDetails?.noOfDays}{" "}
                {estimatedPriceDetails?.noOfDays === 1 ? "Day" : "Days"}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div className="opacity70">Payment Details</div>
              <div>XXXX XXXX XXXX {paymentTransactions?.[0]?.last4}</div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div className="opacity70">Total Amount</div>
              <div>
                $
                {estimatedPriceDetails?.estimatedPrice?.total?.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </div>
            </div>
          </div>
          <div className="mt-1 mt-lg-3">
            <ShowPricingDetails
              estimatedPrice={estimatedPriceDetails?.estimatedPrice}
              pricingDetails={estimatedPriceDetails?.pricingDetails}
            />
          </div>
          <div className="mt-3 d-block d-lg-none fontAntarctica fontSize13">
            <div className="text-center my-2">
              <div>For any queries contact us</div>
            </div>
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="opacity70">Email</div>
                <div>support@drivejoulez.com</div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="opacity70">Contact</div>
                <div>+1 (445) 256-8539</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bookingStatusScreenPadding d-flex justify-content-end">
        <button
          onClick={() => navigate("/booking-history")}
          className="goToMyBookings  bg3B3936 p-5 py-2 text-white border-0 borderRadius8 mt-3 fontAntartica"
        >
          Go to My Trips
        </button>
      </div>
    </div>
  );
};

export default BookingStatus;
