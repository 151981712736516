import axios from "axios";
import React, { useEffect, useState } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../api";
import { useDispatch } from "react-redux";
import {
  setOpenLoginDesktopModal,
} from "../Store/buttonSlice";
import { setCurrentPage } from "../Store/pageSlice";
import verifyEmailBgImage from "../assets/Images/verifyEMailBgImage.png"
import mobileScreenVerifyBgImage from "../assets/Images/mobileScreenVerifyBgImage.png"

const VerifyEmail = () => {
  const token = sessionStorage.getItem("AccessToken");
  const [firstDiv, setFirstDiv] = useState(false);
  const [secondDiv, setSecondDiv] = useState(false);
  const [infoMessage, setinfoMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  
  useEffect(() => {
    getUserVerified();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("verify-email"));
  }, [dispatch]);

  //to get User details
  const getUserVerified = async () => {
    try {
      const userResponse = await axios.get(
        `${BASE_URL}user/verifyEmail/open${location?.search}`
      );
      setinfoMessage(userResponse?.data?.message);
      setFirstDiv(true);
    } catch (error) {
      //console.log(error);
      setSecondDiv(true);
      setinfoMessage(error.response?.data?.message);
    }
  };

  return (
    <div className="bg23 pt-5 min-vh-100">
            <div className="pt-5">
              {firstDiv && (
                <div className="my-5 fontAntarctica overflow-hidden">
                  <div className="text-center text-white successVerifyEmailContent">{infoMessage}</div>
                  <div className="text-center text-white verifySecondContent fontWeight400 py-3">Just log in and let’s get this party started!</div>
                  <div className="d-flex flex-column flex-lg-row justify-content-center">
                  <div className="mt-2 mt-lg-4 text-center">
                   {(token === null || token === ""  || token=== undefined || !token) &&  <button 
                      onClick={() => {
                        dispatch(setOpenLoginDesktopModal(true))
                      }}
                      className="bgCE1 text-white p-2 outlineNone border-0 borderRadius8 mx-2"
                    >
                      Continue to Login
                    </button>
                    }
                    </div>
                    <div className="mt-2 mt-lg-4 text-center">
                    <button 
                      onClick={() => {
                        navigate("/");
                      }}
                      className="bgCE1 text-white p-2 outlineNone border-0 bg3B3936 borderRadius8 mx-2"
                    >
                      Go to Home
                    </button>
                    </div>
                  </div>
                  <div className="pt-4">
                      <img className="w-100 d-none d-lg-block" src={verifyEmailBgImage} alt="verifyEmailBgImage" />
                      <img className="d-block d-lg-none" src={mobileScreenVerifyBgImage} alt="mobileScreenVerifyBgImage" />
                  </div>
                </div>
              )}

              {secondDiv && (
                <div className="my-5 text-white">
                  <h5 className="text-msg1 card-title text-center text-white">
                    {infoMessage}
                  </h5>
                  <div className="d-flex justify-content-center align-align-items-center cursorPointer">
                  <button
                    onClick={() => {
                      navigate("/");
                    }}
                    className=" mt-3 fontAntarctica bgCE1 p-2 br8 border-0 text-white"
                  >
                    Home
                  </button>
                  </div>
                </div>
              )}
            </div>
    </div>
  );
};

export default VerifyEmail;
