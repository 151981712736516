import { useEffect, useState } from "react";
import { setCurrentPage } from "../Store/pageSlice";
import { useDispatch, useSelector } from "react-redux";
import GoogleMpas from "../Components/GoogleMaps";
import axios from "axios";
import locationIcon from "../assets/svg/locateIcon.svg";
import locationRedIcon from "../assets/svg/locate_red_icon.svg";
import CarouselContainer from "../Components/CarouselContainer";
import SpinnerLoader from "../Components/SpinnerLoader";
import { BASE_URL } from "../api";

const Location = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((store) => store?.currentModeStatus?.darkMode);
  const [locationName, setLocationName] = useState("New York");
  const [cityName, setCityName] = useState("Bronx");
  const [locationData, setLocationData] = useState([]);
  const [resultLocation, setResultLocation] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mapWidth, setMapWidth] = useState("450px");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll behavior
    });
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage("location"));
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 570) {
        setMapWidth("350px");
      } else {
        setMapWidth("450px");
      }
    };

    handleResize()//intially it will call to render the specific size

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchLocationList = async () => {
      setLoading(true);
      try {
        const result = await axios.get(
          `${BASE_URL}location/getLocationAdditionalInfo/open`
        );
        setLocationData(result?.data?.data);
        setLoading(false);
      } catch (error) {
        setError("failed to fetch data from location screen");
        setLoading(false);
      }
    };

    fetchLocationList();
  }, []);

  useEffect(() => {
    const filteredLocation = locationData?.filter(
      (ele) => ele?.title === locationName
    );
    setResultLocation(filteredLocation);
    setCityName(filteredLocation[0]?.locations[0]);
  }, [locationName, locationData]);

  if (error) return <p>failed to fetch the location name!</p>;


  return (
    <div className="position-relative">
      {loading && (
        <div className="overlay">
          <div className="spinner-container">
            <SpinnerLoader />
          </div>
        </div>
      )}
      {resultLocation?.map((ele, index) => (
        <div key={index}>
          <img
            className="object-fit-cover w-100 bg_location_image"
            src={ele?.bannerImage}
            alt={ele?.title}
          />
        </div>
      ))}
      <div className="bg191916">
        <div className="findUSContainer text-center text-white">
          <h2 className="py-2 fontAtacama findUsHeading">Where to find us.</h2>
          <div className="fontAntarctica findUsDescriptive">
            Experience cruising the streets of New York or Los
            Angeles in style with an EV car rental. Enjoy the smooth, quiet ride
            and instant acceleration of a Tesla Model 3, or choose from a
            variety of other electric cars to fit your needs. Book your rental
            today!
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="w-100 d-flex justify-content-center align-items-center">
            {locationData?.map((location, index) => (
              <div
                onClick={() => setLocationName(location?.title)}
                key={index}
                className="px-3 py-3 fontSize17 text-white"
              >
                <div className="mb-2 cursorPointer">{location?.title}</div>
                <div
                  className={`${
                    locationName === location?.title ? "border-bottom-line" : ""
                  }`}
                ></div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="mx-3 location_page_box">
              {resultLocation[0]?.locations.map((ele, index) => (
                <div
                  className={`text-white px-3 py-4 my-2 bg23 fontSize17 fontAntarctica borderRadius12 ${
                    ele?.locationName === cityName?.locationName
                      ? "borderTrue"
                      : "border234D"
                  }`}
                  key={index}
                  onClick={() => setCityName(ele)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>{ele?.locationName}</div>
                    <div>
                      <img
                        src={
                          ele?.locationName === cityName?.locationName
                            ? locationRedIcon
                            : locationIcon
                        }
                        alt="locateIcon"
                      />
                    </div>
                  </div>
                  <div className= "fontSize13" >{ele?.address}</div>
                </div>
              ))}
            </div>
            <div className="mx-3">
              <div className="text-white my-2">
                <GoogleMpas
                  height="530px"
                  width={mapWidth}
                  lat={cityName?.latitude}
                  long={cityName?.longitude}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-2">
          {resultLocation[0]?.locations
            .filter((ele) => ele?.locationName === cityName?.locationName)
            .map((ele, i) => (
              <div className="text-white m-2 fontSize17 fontAntarctica" key={i}>
                <div className="col-12 py-2 d-flex flex-column justify-content-center align-items-center">
                  <img
                    className="col-8 my-2"
                    src={ele?.locationImage}
                    alt={ele?.title}
                  />
                  {/* <div className="my-2 fontAtacama fontSize30">
                    {cityName?.locationName}
                  </div>
                  <div className="col-12 col-md-8 p-2 my-2 text-center fontAntarctica fontSize17">
                    {ele?.description}
                  </div> */}
                  {/* <button className="location_page_bookNow mt-2">
                    Book Now
                  </button> */}
                </div>
              </div>
            ))}
        </div>
        <div className={`${isDarkMode ? "darkMode" : "lightMode"} text-center`}>
          <div className="locationPageHeading fontAtacama pt-4">
            Our cars in {cityName?.locationName}
          </div>
          <div className="col-12 col-md-6 p-sm-2 m-auto">
            Embrace the future where every drive is a journey of modern luxury,
            sustainability and a seamless experience. With Joulez, you're not
            just renting an EV, you're part of a movement.
          </div>
          <CarouselContainer />
        </div>
        {/* <div className="location-image-container">
          <img
            className="object-fit-cover h-100 w-100"
            src={resultLocation[0]?.parkingImage}
            alt={resultLocation[0]?.title}
          />
          <div className="location-content-container">
            <div className="d-flex flex-column justify-content-between align-items-center">
              <div className="my-2 locationPageHeading fontAtacama fontWeight400">
                Parking in New York.
              </div>
              <div className="my-2 fontAntarctica parking_description">
                Hey, if you’re planning to park in New York, prices can be a bit
                all over the place. Just a heads up, if you’re parking on the
                street, make sure your car is no more than 18 inches from the
                curb, or you might get a ticket.
              </div>
              <div className="my-2 fontAtacama fontWeight400 parking_second_heading">
                Here are some popular parking spots in New York
              </div>
              <div className="my-2 fontAntarctica parking_description">
                Times Square is just a hop, skip and a jump away from Broadway
                and there’s plenty of parking.
                <div className="border-bottom-line my-2"></div>
              </div>
              <div className="my-2 fontAntarctica parking_description">
                Grand Central Terminal, There’s a 24-hour parking garage right
                near the heart of Midtown Manhattan.
                <div className="border-bottom-line my-2"></div>
              </div>
              <div className="my-2 fontAntarctica parking_description">
                Battery Park City is the perfect spot if you’re heading to
                Battery Park. The entrance is near the Statue of Liberty ferry
                and there’s lots of space.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Location;
