import teslaApp1 from "../assets/svg/teslaApp1.svg";
import teslaApp2 from "../assets/svg/teslaApp2.svg";
import teslaApp4 from "../assets/svg/teslaApp4.svg";
import teslaApp5 from "../assets/svg/teslaApp5.svg";
// import tesla_sub1 from "../assets/svg/tesla_sub1.svg";
// import tesla_sub3 from "../assets/svg/tesla_sub3.svg";
import { content5 } from "../data/content";
// import bxl_apple from "../assets/svg/bxl_apple.svg";
import bxl_apple from "../assets/svg/bxl_apple.svg";
import bxl_light_apple from "../assets/svg/bxl_light_apple.svg";
import bxl_play from "../assets/svg/bxl_play.svg";
import bxl_light_play from "../assets/svg/bxl_light_play.svg";
import { useSelector } from "react-redux";
import Button from "./Button";
// import tesla_new_cat1 from "../assets/svg/tesla_new_cat1.svg";

const TeslaContainer = () => {
  const isDarkMode = useSelector((store) => store.currentModeStatus.darkMode);
  const { title, title1,description1,title2,description2, title3,description3,title4,description4,title5, description5, title6, description6} = content5;
  return (
    <div>
      <div
        // className={`teslaContainer ${isDarkMode ? "darkMode" : "lightMode"}`}
        className="teslaContainer bgF2F0FD"
      >
        <div className="container">
          <div className="row py-2">
            <div className="col-lg-6 py-4 order-lg-1 ">
              <div
                id="carouselExampleSlidesOnly"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src={teslaApp1} alt="teslaPic1" className="teslaPic" />
                  </div>
                  <div className="carousel-item">
                    <img src={teslaApp2} alt="teslaPic2" className="teslaPic" />
                  </div>
                  <div className="carousel-item">
                    <img src={teslaApp4} alt="teslaPic4" className="teslaPic" />
                  </div>
                  <div className="carousel-item">
                    <img src={teslaApp5} alt="teslaPic5" className="teslaPic" />
                  </div>
                </div>

                 {/* Carousel Indicators */}
                 <div className="carousel-indicators teslaIndicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleSlidesOnly"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                </div>

              </div>
            </div>
            <div className="col-lg-6 order-lg-2 align-self-center  teslaSecondContaoiner fontAntarctica">
              <h3 className="pb-3">{title}</h3>
              {/* <p>{description1}</p>
              <p>{description2}</p> */}
              <ul >
              <li >
                  <p> <span className="fontWeight500">{title1}:</span> <span className="fontColor16CC fontWeight300">{description1}</span>
                  </p>
              </li>
              <li >
                <p >
                <span className="fontWeight500">{title2}:</span> <span className="fontColor16CC fontWeight300">{description2}</span>
                 
                </p>
              </li>
              <li >
                <p >
                <span className="fontWeight500">{title3}:</span> <span className="fontColor16CC fontWeight300">{description3}</span>
                </p>
              </li>
              <li >
                <p >
                <span className="fontWeight500">{title4}:</span> <span className="fontColor16CC fontWeight300">{description4}</span>
                </p>
              </li>
              <li >
                <p >
                <span className="fontWeight500">{title5}:</span> <span className="fontColor16CC fontWeight300">{description5}</span>
                
                </p>
              </li>
              <li >
                <p >
                <span className="fontWeight500">{title6}:</span> <span className="fontColor16CC fontWeight300">{description6}</span>
                
                </p>
              </li>
            </ul>
              {/* <div className="mt-3 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row justify-content-start w-100">
                <div>
                  <Button
                    btnClassName={`button ${
                      isDarkMode ? "text-white bg3B3936" : "lightGreyMode"
                    }`}
                  >
                    <a
                      href="https://apps.apple.com/us/app/tesla/id582007913"
                      rel="noreferrer"
                      className="text-white text-decoration-none"
                      target="_blank"
                    >
                      <img
                        src={`${isDarkMode ? bxl_apple : bxl_light_apple}`}
                        alt="bxl_apple"
                      />
                      Download for iPhone
                    </a>
                  </Button>
                </div>
                <div className="mx-0 mx-sm-3 mx-md-3 mx-lg-3 mx-xl-3">
                  <Button
                    btnClassName={`button ${
                      isDarkMode ? "text-white bg3B3936" : "lightGreyMode"
                    }`}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.teslamotors.tesla&hl=en&gl=US"
                      rel="noreferrer"
                      className="text-white text-decoration-none"
                      target="_blank"
                    >
                      <img
                        src={`${isDarkMode ? bxl_play : bxl_light_play}`}
                        alt="bxl_play"
                      />
                      Download for Android
                    </a>
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeslaContainer;
